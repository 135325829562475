import Vue from 'vue';
import app from "@/nuxt.app";
import common from '~/utils/common';
import toast from '~/utils/toast';

//- vue 原型拓展
Vue.prototype.$C = common;
Vue.prototype.$toast = toast;
Vue.prototype.$app = app;
Vue.config.productionTip = false;


