
export default {
  data() {
    return {
      mode: "sms"
    }
  },
  methods: {
    handleSwitch(mode) {
      const targetMode = mode || 'sms';
      this.mode = targetMode;
      this.$emit('change', targetMode)
    }
  }
}
