
export default {
  name: "page-upcoming",
  head() {
    return {
      title: this.$app.getTitle("暂未开放"),
    };
  },
  data() {
    return {};
  },
  methods: {},
};
