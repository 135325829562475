
import usersApi from "~/utils/api/users";

export default {
  name: "page-user-detail",
  head() {
    const { user } = this;

    return {
      title: this.$app.getTitle(`${user.username}的用户主页`),
    };
  },
  data() {
    return {};
  },
  async asyncData({ params }) {
    const id = params.id;
    const rs = await usersApi.detail(id);
    if (rs) {
      return {
        id,
        user: rs.data
      }
    }
  },
  methods: {

  },
};
