
export default {
  props: {
    metas: {
      type: Object,
      default() {
        return null
      }
    },
    // 用于跳转的基础路径
    basePath: {
      type: String,
      default: "/"
    },
    // 如果使用query模式，会在链接后增加 /path?page=1 否则 /path/1
    query: {
      type: Boolean,
      default: false,
    },
    hasMore: {
      type: Boolean,
      default: false,
    },
    // 左右最长显示分页
    maxPageLength: {
      type: Number,
      default: 3
    },
    // 注意在组件调用处传入
    totalPage: {
      type: Number,
      default: 1
    }
  },
  computed: {
    /**
     * 分页html
     */
    pageLinks() {
      const { metas, totalPage, maxPageLength, basePath, query } = this;
      if (totalPage == 0) {
        return ``
      }

      // 当前页
      const currentPage = metas.currentPage;
      // 将渲染的分页符号长度
      const willRenderPageLength = totalPage >= maxPageLength ? maxPageLength + 1 : totalPage;
      let links = [];

      /// 添加反向
      for (let i = 0; i < willRenderPageLength; i++) {
        const page = currentPage - i;
        let pathSlug = `/${page}`;
        if (query) {
          pathSlug = `?page= ${page}`
        }

        if (page > 0) {
          links.unshift({ page, path: `${basePath}${pathSlug}` })
        }
      }


      //- 添加正向页面
      for (let i = 0; i < willRenderPageLength; i++) {
        const page = currentPage + i;
        let pathSlug = `/${page}`;
        if (query) {
          pathSlug = `?page= ${page}`
        }

        // 防止超出
        if (page <= totalPage) {
          links.push({ page, path: `${basePath}${pathSlug}` })
        }
      }

      // 去重
      let removeSameLinks = [];
      links.forEach(it => {
        const has = removeSameLinks.find(el => el.page == it.page);
        if(has){
          return;
        }

        removeSameLinks.push(it);
      });



      return removeSameLinks
    },
    /**
     * 上一页路径
     */
    previewPagePath() {
      const { metas, basePath, query } = this;
      if (metas.currentPage <= 1) {
        return basePath;
      }

      let pathSlug = `/${metas.currentPage - 1}`;
      if (query) {
        pathSlug = `?page= ${metas.currentPage - 1}`
      }

      return `${basePath}${pathSlug}`
    },
    /**
     * 下一页路径
     */
    nextPagePath() {
      const { metas, basePath, query, hasMore } = this;
      if (!hasMore) {
        return basePath;
      }

      let pathSlug = `/${metas.currentPage + 1}`;
      if (query) {
        pathSlug = `?page= ${metas.currentPage + 1}`
      }

      return `${basePath}${pathSlug}`
    },

  }
}
