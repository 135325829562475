import { render, staticRenderFns } from "./index.vue?vue&type=template&id=3617f247&scoped=true&lang=pug"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=3617f247&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3617f247",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EditorFaqTextArea: require('/Users/weizhang/Projects/mingcui_web/components/editor/faq/TextArea.vue').default,QuestionHasPickedAnswerList: require('/Users/weizhang/Projects/mingcui_web/components/question/HasPickedAnswerList.vue').default,QuestionHasNoPickedAnswerList: require('/Users/weizhang/Projects/mingcui_web/components/question/HasNoPickedAnswerList.vue').default})
