
import { Base64 } from 'js-base64';

export default {
  head() {
    return {
      title: this.$app.getTitle('在线Base64编码 Base64解码 Base64编码解码'),
      meta: [
        {
          hid: "keywords",
          name: "keywords",
          content: "在线Base64编码 Base64解码 Base64编码解码 Base64在线解码",
        },
      ]
    };
  },
  data() {
    return {
      method: 'btoa', // 或者atob
      beforeData: "", // 处理前的数据
      afterData: "", // 处理后的数据
    };
  },
  methods: {
    handleEncode() {
      const { beforeData } = this;
      this.afterData = Base64.encode(beforeData);
    },
    handleDecode() {
      const { afterData } = this;
      this.beforeData = Base64.decode(afterData);
    }
  },
};
