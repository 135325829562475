import domainTradeApi from "~/utils/api/domain-trade";
import isEmpty from 'lodash/isEmpty';


export const state = () => ({
  tlds: [],
})


export const mutations = {
  /**
     * 更新TLD列表
     * @param {*} state 
     * @param {*} tldLites 
     */
  updateTlds(state, tlds) {
    state.tlds = tlds;
  }
};

export const actions = {
  async getTlds(context) {
    if (!isEmpty(context.state.tlds)) {
      return;
    }

    const rs = await domainTradeApi.tlds();
    if (rs) {
      context.commit("updateTlds", rs.data);
      return true;
    }
    return;
  },
};


export const namespaced = true;