
import emojiApi from "~/utils/api/emoji";

export default {
  data() {
    return {
      emoji: [],
      loading: true
    }
  },
  mounted() {
    this.$nextTick(async () => {
      await this.getEmojiList();
    });
  },
  methods: {
    /**
     * 取Emoji
     */
    async getEmojiList() {
      const { emoji } = this;
      if (!this.$_.isEmpty(emoji)) {
        return;
      }

      this.loading = true;

      try {
        const rs = await emojiApi.qq();
        if (rs) {
          this.emoji = rs.data || [];
        }
      } catch (error) {
        console.log(error)
      }

      this.loading = false;
    },
    handleClick(e) {
      this.$emit('add', e);
    }
  }
}
