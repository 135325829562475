
import threadsApi from "~/utils/api/threads";

export default {
  async fetch() {
    await this.getThreads();
  },
  data() {
    return {
      threads: [],
      loading: true,
      id: this.$route.params.id,
    };
  },
  methods: {
    /**
     * 取主题
     */
    async getThreads() {
      const { id } = this;
      try {
        this.loading = true;
        const rs = await threadsApi.list({
          userID: Number(id)
        });
        if (rs) {
          this.threads = rs.data || [];
        }
      } catch (err) {
        console.error(err);
      }

      this.loading = false;
    }
  }
}
