
import threadsApi from "~/utils/api/threads";

export default {
  data() {
    return {
      loading: true,
      tags: []
    }
  },
  mounted() {
    this.$nextTick(async () => {
      await this.getList();
    });
  },
  methods: {
    /**
     * 取标签列表
     */
    async getList() {
      try {
        const rs = await threadsApi.tagList({
          limit: 25
        });
        if(rs){
          this.tags = rs.data || [];
        }
      } catch (err) {
        console.error(err);
      }
      this.loading = false;
    }
  }
}
