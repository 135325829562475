
export default {
  props: {
    // 主题
    thread: {
      type: Object,
      default() {
        return null
      }
    }
  },
  computed: {
    /**
     * 作者
     */
    author() {
      const { thread } = this;
      if (this.$_.isEmpty(thread)) {
        return null;
      }

      return thread.author || null;
    }
  }
}
