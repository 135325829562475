
export default {
  head() {
    return {
      title: this.$app.getTitle('空的占位页'),
    };
  },
  data() {
    return {};
  },
  methods: {},
};
