
export default {
  head() {
    return {
      title: this.$app.getTitle('创建收货地址'),
    };
  },
  data() {
    return {};
  },
  mounted() {
    this.$nextTick(async () => {
      await this.$store.dispatch('users/redirect');
    });
  },
  methods: {},
};
