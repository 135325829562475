
export default {
  data() {
    return {
      menus: [
        { name: "首页", path: "/", icon: "shouye" },
        { name: "专栏", path: "/threads", icon: "wenjuan" },
        { name: "搜索", path: "/search/threads/德宏", icon: "sousuo" }
      ]
    }
  }
}
