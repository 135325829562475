import { render, staticRenderFns } from "./StudioEditor.vue?vue&type=template&id=29a4c773&lang=pug"
import script from "./StudioEditor.vue?vue&type=script&lang=js"
export * from "./StudioEditor.vue?vue&type=script&lang=js"
import style0 from "./StudioEditor.vue?vue&type=style&index=0&id=29a4c773&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EditorRecentThreads: require('/Users/weizhang/Projects/mingcui_web/components/editor/RecentThreads.vue').default,CommonCard: require('/Users/weizhang/Projects/mingcui_web/components/common/Card.vue').default,EditorKeywordIndexGen: require('/Users/weizhang/Projects/mingcui_web/components/editor/KeywordIndexGen.vue').default,EditorCustomKeywords: require('/Users/weizhang/Projects/mingcui_web/components/editor/CustomKeywords.vue').default,EditorOrigin: require('/Users/weizhang/Projects/mingcui_web/components/editor/Origin.vue').default,PexelsFlatSearch: require('/Users/weizhang/Projects/mingcui_web/components/pexels/FlatSearch.vue').default,ThreadsCoverImageEditor: require('/Users/weizhang/Projects/mingcui_web/components/threads/CoverImageEditor.vue').default,EditorQuillEditor: require('/Users/weizhang/Projects/mingcui_web/components/editor/QuillEditor.vue').default,ThreadsDraftList: require('/Users/weizhang/Projects/mingcui_web/components/threads/DraftList.vue').default,ThreadsCategorySelector: require('/Users/weizhang/Projects/mingcui_web/components/threads/CategorySelector.vue').default,EditorAdminOptions: require('/Users/weizhang/Projects/mingcui_web/components/editor/AdminOptions.vue').default,EditorSaveButtons: require('/Users/weizhang/Projects/mingcui_web/components/editor/SaveButtons.vue').default})
