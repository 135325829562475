
export default {
  head() {
    return {
      title: this.$app.getTitle('模板页'),
    };
  },
  data() {
    return {};
  },
  methods: {},
};
