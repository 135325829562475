import { render, staticRenderFns } from "./create.vue?vue&type=template&id=45174210&scoped=true&lang=pug"
import script from "./create.vue?vue&type=script&lang=js"
export * from "./create.vue?vue&type=script&lang=js"
import style0 from "./create.vue?vue&type=style&index=0&id=45174210&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45174210",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonLogo: require('/Users/weizhang/Projects/mingcui_web/components/common/Logo.vue').default,SmsSendButton: require('/Users/weizhang/Projects/mingcui_web/components/sms/SendButton.vue').default,CommonPrivacyCheck: require('/Users/weizhang/Projects/mingcui_web/components/common/PrivacyCheck.vue').default})
