
import format from "~/utils/format";
export default {
  props: {
    /**
     * 主题信息
     */
    thread: {
      type: Object,
      default() {
        return null;
      }
    }
  },
  data() {
    return {
      dataUrl: ""
    }
  },
  computed: {
    /**
     * 计算真实帖子地址
     */
    computedThreadUrl() {
      const { thread } = this;
      if (this.$_.isEmpty(thread)) {
        return "";
      }

      const loc = window.location.host;
      return `https://${loc}/threads/content/${thread.hashID}.html`;
    }
  },
  methods: {
    /**
     * 格式化时间
     */
    formatDate(t) {
      return format.formatDate(t, "YYYY-MM-DD");
    },
    /**
     * 生成二维码
     */
    generateQrcodeUrl() {
      const { computedThreadUrl } = this;
      if (this.$_.isEmpty(computedThreadUrl)) {
        return "";
      }

      const QRCode = require('qrcode')
      QRCode.toDataURL(computedThreadUrl, (_, url) => {
        this.dataUrl = url;
      })
    }
  },
  watch: {
    "thread": {
      handler() {
        this.generateQrcodeUrl();
      },
      immediate: true
    },
  }
}
