
import { mapState } from "vuex";
import debounce from "lodash/debounce";

export default {
  name: 'common-nav',
  async fetch() {
    await this.updateThreadCatgories();
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  data() {
    return {
      // 路径
      path: this.$route.path,
      slug: this.$route.params.categorySlug || '',
      collapseNav: false,
    };
  },
  computed: {
    ...mapState("threads", {
      threadCatgories: (state) => state.threadCatgories,
    }),
    ...mapState("navbar", {
      navbar: (state) => state.navbar,
    }),
    /**
     * 当前分类
     */
    currentCategory() {
      const { threadCatgories, slug } = this;
      return threadCatgories.find((it) => it.slug == slug);
    },
    /**
    * 当前分类ID
    */
    currentID() {
      const { currentCategory } = this;
      if (!currentCategory) {
        return 0;
      }

      return Number(currentCategory.id || '0');
    },
    /**
     * 仅当目录处于threads下才会显示
     */
    showSubMenu() {
      const { path } = this;

      const pathSplit = path.split("/");
      const hasSearch = pathSplit.find(it => it == 'search');
      const hasThreads = pathSplit.find(it => it == 'threads');

      // 不要在搜索模式下错误渲染
      if (hasThreads && !hasSearch) {
        return true;
      }

      return false;
    },

    /**
     * 重新计算分类，增加一个全部选项
     */
    categories() {
      const { threadCatgories } = this;
      if (this.$_.isEmpty(threadCatgories)) {
        return [];
      }

      let categories = [
        ...threadCatgories,
      ];

      const defaultCategory = { id: 0, name: '全部', slug: 'all' };
      if (!categories.find(it => it.name == '全部')) {
        categories.unshift(defaultCategory);
      }

      return categories;
    }
  },
  methods: {
    handleScroll: debounce(function (_) {
      const collapseNav = window.pageYOffset > 100 ? true : false;
      this.collapseNav = collapseNav;
    }, 100),
    // 取分类列表
    async updateThreadCatgories() {
      const { threadCatgories } = this;
      if (!this.$_.isEmpty(threadCatgories)) {
        return;
      }

      await this.$store.dispatch("threads/updateThreadCatgories");
    },
    /**
     * 菜单是否激活
     * @param {*} currentPath
     */
    mapMenuItemIsActived(currentPath) {
      const { path } = this;

      // 如果是根目录
      if (currentPath == "/") {
        return currentPath == path ? true : false;
      }

      // 使用path匹配当前目录是否是子目录
      return path.indexOf(currentPath) >= 0 ? true : false;
    },
  },
  watch: {
    /**
     * 如果path发生变化
     * @param {*} val
     * @param {*} _
     */
    "$route.path": function (val, _) {
      this.path = val;
    },
    /**
     * id
     * @param {*} val 
     * @param {*} _ 
     */
    "$route.params.categorySlug": function (val, _) {
      this.slug = val || '';
    },
  },
};
