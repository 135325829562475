
import wbsSSLApi from "~/utils/api/wbs-ssl";

export default {
  data() {
    return {
      wbsPlanList: [],
      loading: false
    }
  },
  async fetch() {
    await this.getWbsPlanList();
  },
  methods: {
    async getWbsPlanList() {
      this.loading = true;

      try {
        const rs = await wbsSSLApi.planList();
        if (rs) {
          const wbsPlanList = rs.data || []
          this.wbsPlanList = wbsPlanList.reverse();
        }
      } catch (err) {
        console.error(err);
      }

      this.loading = false;
    }
  }
}
