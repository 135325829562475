
import quetionApi from "~/utils/api/question";
import metasMixins from "~/mixins/metas";

export default {
  mixins: [metasMixins],
  data() {
    return {
      questionList: [],
      loading: true
    }
  },
  computed: {
  },
  async fetch() {
    await this.getQuestionList();
  },
  head() {
    const { metas } = this;


    /// seo信息
    return {
      title: this.$app.getTitle(`问答列表 第${metas.currentPage}页`),
    };
  },
  methods: {
    /**
     * 取问答列表
     */
    async getQuestionList(targetMetas) {
      const { metas } = this;

      const willLoadMetas = targetMetas || metas;
      let data = {
        ...willLoadMetas,
        hasPickedAnswer: true,
      };

      this.loading = true;
      try {
        const rs = await quetionApi.listLite({
          ...data
        });
        if (rs) {
          this.metas = rs.metas;
          this.questionList = rs.data || [];
        }
      } catch (err) {
        console.error(err);
      }

      this.loading = false;
    }
  }
}
