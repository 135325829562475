import req from "~/utils/request";
import { URLS } from "~/utils/request/requestUrls";

export default {
  /**
   * 业务启动接口
   * @returns 
   */
  async bootstrap() {
    const rs = await req.get({
      url: URLS.WBS_SSL_BOOTSTRAP
    });
    return rs.data;
  },
  //- 创建
  async save(data) {
    const rs = await req.postJson({
      url: URLS.WBS_SSL_SAVE,
      data
    });
    return rs.data;
  },
  // 列表
  async list(data) {
    const rs = await req.postJson({
      url: URLS.WBS_SSL_LIST,
      data
    });
    return rs.data;
  },
  // 删除任务
  async delete(id) {
    const rs = await req.get({
      url: `${URLS.WBS_SSL_DELETE}/${id}`
    });
    return rs.data;
  },
  // 详情
  async detail(id) {
    const rs = await req.get({
      url: `${URLS.WBS_SSL_DETAIL}/${id}`
    });
    return rs.data;
  },
  // 执行预加载
  async preload(id) {
    const rs = await req.get({
      url: `${URLS.WBS_SSL_PRELOAD}/${id}`
    });
    return rs.data;
  },
  //- 状态
  async status(id, data) {
    const rs = await req.postJson({
      url: `${URLS.WBS_SSL_STATUS}/${id}`,
      data
    });
    return rs.data;
  },
  //- 创建
  async planSave(data) {
    const rs = await req.postJson({
      url: URLS.WBS_SSL_PLAN_SAVE,
      data
    });
    return rs.data;
  },
  // 套餐列表
  async planList(data) {
    const rs = await req.postJson({
      url: URLS.WBS_SSL_PLAN_LIST,
      data
    });
    return rs.data;
  },
  //- 删除
  async planDelete(id) {
    const rs = await req.get({
      url: `${URLS.WBS_SSL_PLAN_DELETE}/${id}`
    });
    return rs.data;
  },
  //- 用户开通的套餐
  async planUser() {
    const rs = await req.get({
      url: URLS.WBS_SSL_PLAN_USER
    });
    return rs.data;
  },
}