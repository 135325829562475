
export default {
  layout: "tools",
  head() {
    return {
      title: this.$app.getTitle("站长开发者工具大全"),
    };
  },
  data() {
    return {
      tools: [
        {
          name: "编码类",
          items: [
            {
              name: "Base64在线编码",
              desc: "本工具用于Base64在线编码和解码",
              path: "/tools/btoa",
            },
            {
              name: "Punycode在线编码解码",
              desc: "本工具用于Punycode在线编码和解码",
              path: "/tools/punycode",
            },
          ],
        },

        {
          name: "站长工具类",
          items: [
            {
              name: "搜索引擎站长平台入口",
              desc: "直达多个搜索引擎站长平台",
              path: "/tools/webmaster",
            },
          ],
        },

        {
          name: "查询类",
          items: [
            {
              name: "百度真假蜘蛛查询",
              desc: "查询来源爬虫IP是否为百度等搜索引擎蜘蛛",
              path: "/tools/spider",
            },
          ],
        },

        {
          name: "教程类",
          items: [
            {
              name: "极客开发教程",
              desc: "值得收藏的优质编程语言手册",
              path: "https://www.jkkf.cn/?from=mingcui_tools_index",
              native: true,
            },
          ],
        },
      ],
    };
  },
  methods: {},
};
