
import { mapState } from "vuex";
import smsApi from "~/utils/api/sms";
import aliyunCaptcha from "~/utils/aliyunCaptcha";

/// 发送间隔 秒
const defaultSendSMSDuration = 60;

// 发送成功后触发done回调事件
export default {
  props: {
    mobile: {
      type: String,
      default: "",
    },
    verifyType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      sendButtonText: "发送验证码",
      loading: false,
      sendSMSDuration: 0, // 发送间隔
      //----- 仅阿里云验证码时可用
      captchaVerifyParam: null,
    };
  },
  computed: {
    ...mapState("bootstrap", { bootstrap: (state) => state.bootstrap }),
    /**
     * 站点设置
     */
    setSite() {
      const { bootstrap } = this;
      return bootstrap.setSite || {};
    },
    /**
     * 是否禁用按钮
     */
    disabled() {
      const { mobile, sendSMSDuration } = this;
      if (sendSMSDuration > 0) {
        return true;
      }

      return this.$_.isEmpty(mobile) ? true : false;
    },
    /**
     * 系统是否启用验证码
     * 从bootstrap的site_site中取得相关配置判断是否启用
     */
    captchaEnabled() {
      const { setSite } = this;

      // 返回是否开启
      return setSite.captchaEnabled || false;
    },

    /**
     * 验证码提供商
     */
    captchaProvider() {
      const { setSite } = this;
      return setSite.captchaProvider || null;
    }
  },
  methods: {
    /**
     * 按钮点击事件回调
     */
    async handleButtonClick() {
      const { captchaEnabled, captchaProvider } = this;

      // 系统未开启注册启用验证码，直接发起请求
      if (!captchaEnabled) {
        await this.send();
        return;
      }

      // 调用验证码模块，传入服务端配置
      // 除了图形验证码外，第三方验证码调用utils/captcha.js 验证码模块自行封装处理
      // 封装的验证码回调 callbackData
      try {

        // 如果使用阿里云
        if (captchaProvider == "aliyunCaptcha") {
          aliyunCaptcha.show(async (captchaVerifyParam) => {
            this.captchaVerifyParam = JSON.parse(captchaVerifyParam);

            const ok = await this.send();
            const captchaResult = ok === true ? true : false;

            // 2.构造标准返回参数
            const verifyResult = {
              captchaResult, // 验证码验证是否通过，boolean类型，必选
              bizResult: null, // 业务验证是否通过，boolean类型，可选；若为无业务验证结果的场景，bizResult可以为空
            };

            aliyunCaptcha.close();
            
            return verifyResult;
          });
          return;

        }


      } catch (error) {
        // 无可用的验证码模块
        this.$toast.show(error);
      }
    },
    /**
     * 发送短信验证码
     */
    async send() {
      const { mobile, verifyType, captchaVerifyParam, captchaEnabled, captchaProvider } = this;

      /// 校验手机号
      if (this.$_.isEmpty(mobile)) {
        this.$toast.show("请先输入手机号");
        return;
      }

      /// 防止函数错误顺序调用
      let captchaDone = captchaEnabled && !this.$_.isEmpty(captchaVerifyParam) && captchaProvider == "aliyunCaptcha";

      // 未完成
      if (!captchaDone) {
        this.$toast.show("请先完成安全验证");
        return;
      }

      /// 验证码发送类型不能为空
      if (this.$_.isEmpty(verifyType)) {
        this.$toast.show("验证码发送类型不能为空");
        return;
      }

      this.loading = true;

      try {
        let data = { verifyType, mobile };

        // 如果传入回调参数
        if (!this.$_.isEmpty(captchaVerifyParam)) {
          data = {
            ...data,
            ...captchaVerifyParam
          }
        }

        // 处理aliyunCaptchaVerifyResult
        const rs = await smsApi.send(data);

        // 发送成功
        // 倒计时
        if (rs) {
          this.makeInterval();
          this.$emit("done", rs.data);
          return true;
        }
      } catch (err) { 
        console.error(err);
      }

      this.loading = false;
    },
    /**
     * 倒计时
     */
    makeInterval() {
      this.sendSMSDuration = defaultSendSMSDuration;

      const handler = setInterval(() => {
        if (this.sendSMSDuration > 0) {
          this.sendSMSDuration--;
          this.sendButtonText = `${this.sendSMSDuration + 1}s`;
          return;
        }

        this.sendSMSDuration = 0;
        this.sendButtonText = "重新发送";
        clearInterval(handler);
      }, 1000);
    },
  },
};
