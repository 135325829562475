
export default {
  name: "page-product-wbs",
  head() {
    return {
      title: this.$app.getTitle(`https证书过期怎么办? 快来用网站监控服务 网站证书监控 SSL证书过期监控 网站状态监控 免费网站监控 免费证书监控`),
    }
  },
  data() {
    return {
      product: {
        name: "网站监控服务",
        englishName: "Website Status",
        description: "为您提供一站式网站监控服务，快速订阅宕机状态。提高网站在线率并获得更加稳定的用户体验。",
        // 头部组件
        header: {
          background: "#FFFFFF", // 默认为黑色
          // navbar: {
          //   backgroundColor: '#000000',
          //   mode: "dark",
          // },
          image: "/images/svg/product/wbs.svg",
          actions: [
            { name: "当前已经下线", class: "btn-primary", click: this.handleStartButtonClicked },
            { name: "更多产品信息", class: "btn-outline-dark", to: "/threads/content/653c4250-050c-11ef-8b4e-0c42a1499c1d.html" },
          ],
          shortDesc: {
            text: "为您的网站保驾护航",
          }
        },

        // 产品特性
        features: {
          title: "全程自动化，高自定义支持",
          sub: "开箱即用，您可以根据需求配置请求参数等",
          items: [
            { feature: "价格更低", desc: "一个域名监控一年，免费领取即可使用", icon: "/images/svg/product/domain/coupon.svg" },
            { feature: "高自定义", desc: "您可以通过自定义请求参数发送请求", icon: "/images/svg/product/domain/safe.svg" },
            { feature: "通知送达", desc: "发生宕机，马上下发到多个通知渠道", icon: "/images/svg/product/domain/world.svg" },
            { feature: "服务更优", desc: "人工和工单系统支持，为业务安全护航", icon: "/images/svg/product/domain/service.svg" },
          ]
        },

        // 常见问题
        // {question:"", answer:""},
        faq: {
          title: "用户常问",
          sub: "关于网站监控，很多用户会提问",
          items: [
            { question: "监控服务免费吗", answer: "您可以免费试用，但会受到次数和频率限制，免费的套餐也会限制域名个数。由于套餐价格低廉，推荐您开通付费监控服务。" },
            { question: "可以自行配置请求参数吗", answer: "可以，支持标准Cron表达式，并且支持HTTP请求参数自定义无论是Header还是请求结果回调" },
            { question: "支持什么协议", answer: "目前支持触发HTTP请求，后期将根据需要增加socket或者TCP Client等" },

          ]
        }
      }
    }
  },
  methods: {
    // 点击注册按钮
    handleStartButtonClicked() {
      this.$toast.show("很抱歉，我们不在接受当前业务新开");
    }
  }
}
