import { render, staticRenderFns } from "./Nav.vue?vue&type=template&id=4be160dd&lang=pug"
import script from "./Nav.vue?vue&type=script&lang=js"
export * from "./Nav.vue?vue&type=script&lang=js"
import style0 from "./Nav.vue?vue&type=style&index=0&id=4be160dd&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonLogo: require('/Users/weizhang/Projects/mingcui_web/components/common/Logo.vue').default,SearchFlatTextfield: require('/Users/weizhang/Projects/mingcui_web/components/search/FlatTextfield.vue').default,UserStatusButton: require('/Users/weizhang/Projects/mingcui_web/components/user/StatusButton.vue').default})
