
import { mapState } from "vuex";

export default {
  name: "page-about",
  head() {
    return {
      title: this.$app.getTitle("关于我们"),
    };
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("bootstrap", { setSite: (state) => state.setSite }),
  },
  mounted(){
    this.$store.dispatch("navbar/updateNavbar", {
      backgroundColor: "#000000",
      mode: "dark",
      borderBottom: "none"
    });
  },
  beforeDestroy() {
    this.$store.dispatch("navbar/updateNavbar", null);
  },
  methods: {
  },
};
