 
export default {
  props: {
    thread: {
      type: Object,
      default(){
        return null;
      }
    }
  },
  data(){
    return {}
  },
  methods: {

  }
}
