
import { mapState } from "vuex";
import threadsApi from "~/utils/api/threads";
import usersApi from "~/utils/api/users";

export default {
  name: "page-studio",
  head() {
    return {
      title: this.$app.getTitle("创作中心"),
    };
  },
  data() {
    return {
      medium: null, // 用户媒体号
      loadingUserMedium: false,
    };
  },
  computed: {
    ...mapState("users", {
      user: (state) => state.user,
      isAdminUser: (state) => state.isAdminUser,
      loadingUserInfo: (state) => state.loadingUserInfo,
    },),
    ...mapState("editor", {
      submiting: (state) => state.submiting,
    },),
    // 用户是否有编辑权限
    userHasPermission() {
      const { user, isAdminUser, loadingUserInfo, medium } = this;
      if (this.$_.isEmpty(user) || loadingUserInfo) {
        return false;
      }

      /// 如果用户是管理员则有权限
      if (isAdminUser) {
        return true;
      }

      /// 如果开通了媒体号则有权限
      if (!this.$_.isEmpty(medium) && medium.isChecked) {
        return true;
      }

      /// TODO: 如果普通用户，检测是否申请并开通媒体号，否则不允许使用
      return false;
    }
  },
  mounted() {
    this.$nextTick(async () => {
      await this.$store.dispatch('users/redirect');
      await this.restoreUserMedium();
      // 一定要在用户信息加载完成后执行
      this.onbeforeunload();
    });
  },
  beforeRouteLeave: async function (_to, _from, next) {
    const { user } = this;
    // 仅在客户端和登录状态下执行
    if (this.$_.isEmpty(user)) {
      next();
      return;
    }

    // 防止在询问前跳转路由
    next(false)

    const rs = await this.$toast.confirm({
      message: '您还未保存页面内容，确定需要离开吗?',
      title: '提示',
      confirmButtonText: '继续编辑',
      cancelButtonText: '离开页面',
      type: 'warning'
    });
    if (!rs) {
      window.onbeforeunload = null;
      next();
    }
  },
  methods: {
    /**
     * 防止浏览器刷新
     */
    onbeforeunload() {
      const { user } = this;

      // 仅在客户端和登录状态下执行
      if (!this.$C.isClient || this.$_.isEmpty(user)) {
        return;
      }


      // 离开页面清除onbeforeunload事件
      window.onbeforeunload = function (e) {
        e = e || window.event;
        if (e) {
          e.returnValue = '系统可能不会保存您所做的更改。';
        }

        return "系统可能不会保存您所做的更改。";
      };
    },
    /**
     * 还原用户媒体号申请表单信息
     */
    async restoreUserMedium() {

      this.loadingUserMedium = true;

      try {
        const rs = await usersApi.mediumDetail();
        if (rs && rs.data) {
          this.medium = rs.data;
        }
      } catch (err) {
        console.error(err);
      }

      this.loadingUserMedium = false;
    },
    /**
     * 用户提交保存
     */
    async handleSave(e) {
      const data = { ...e, categoryID: e.selectedCategory };

      // 更新编辑器状态
      await this.$store.dispatch("editor/updateSubmitingState", true);

      try {
        const rs = await threadsApi.create({ ...data });
        if (rs) {
          window.onbeforeunload = null;
  
          this.$toast.notify({
            title: "成功",
            message: `文章${e.isDraft ? '保存为草稿' : '发布'}成功`,
            type: "success",
          });
  
          // 是否自动提交到搜索引擎
          // submitToSearchEngine
  
          // 更新草稿箱
          if (e.isDraft) {
            await this.$store.dispatch("editor/updateDraftList");
            return;
          }
  
          // 如果不是草稿的话，会刷新来充值编辑器
          setTimeout(async () => {
            // 仅在生产下跳转，开发环境中不重新加载
            if (!this.$C.isProduction) {
              return;
            }
  
            try {
              // const rs = await this.$toast.confirm({
              //   title: "提示",
              //   message: `是否为您自动刷新编辑器`,
              // });
              // if (rs) {
              //   window.location.reload();
              // }
              window.location.reload();
            } catch (err) {
              console.warn(err);
            }
  
          }, 1500);
        }
      } catch (err) {
        console.error(err);
      }
      console.info('Content save event callback: ', e);

      // 关闭编辑器状态
      await this.$store.dispatch("editor/updateSubmitingState", false);

    }
  },
};
