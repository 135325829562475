import Common from '~/utils/common';
import app from '~/nuxt.app';

/// 生产和开发环境不同，上线时注意配置
let baseUrl = Common.isProduction ? app.apiBaseUrl : 'http://localhost:3004/api';
/// 将服务端渲染转发到内网
if (!Common.isClient && Common.isProduction) {
  baseUrl = app.apiInternalBaseUrl;
}

/// 导出
export const URLS = {

  /// Bootstrap
  BOOTSTRAP: `${baseUrl}/v1/system/bootstrap`,
  SYSTEM_SERVER_TIME: `${baseUrl}/v1/system/servertime`,
  SYSTEM_SET_SITE: `${baseUrl}/v1/system/setsite`,
  SYSTEM_OVERVIEW: `${baseUrl}/v1/system/overview`,

  /// 用户
  /// 用户名密码登录
  USERS_PASSWORD_LOGIN: `${baseUrl}/v1/users/login`,
  USERS_SMS_LOGIN: `${baseUrl}/v1/users/login/sms`,
  USERS_INFO: `${baseUrl}/v1/users/current`,
  USERS_REFRESH_TOKEN: `${baseUrl}/v1/users/refresh-token`,
  USERS_DETAIL: `${baseUrl}/v1/users/detail`,
  USERS_CREATE: `${baseUrl}/v1/users/create`,
  USERS_NAME_VALIDATE: `${baseUrl}/v1/users/name-validate`,
  USERS_RESET_PASSWORD: `${baseUrl}/v1/users/reset_password`,
  USERS_LIST: `${baseUrl}/v1/users/list`,
  USERS_STATUS: `${baseUrl}/v1/users/status`,
  USERS_PROFILES: `${baseUrl}/v1/users/profiles`,
  USERS_PROFILES_MODIFY: `${baseUrl}/v1/users/profiles/modify`,
  USER_IDENTIFY: `${baseUrl}/v1/users/identify`,
  USER_DEVICE_BIND: `${baseUrl}/v1/users/device/bind`,
  USER_ACCESS_REST: `${baseUrl}/v1/users/access/reset`,
  USER_ACCESS_DETAIL: `${baseUrl}/v1/users/access/detail`,

  // 地址
  USER_ADDRESS_LIST: `${baseUrl}/v1/users/address/list`,
  USER_ADDRESS_DELETE: `${baseUrl}/v1/users/address/delete`,
  USER_ADDRESS_SAVE: `${baseUrl}/v1/users/address/save`,

  // 媒体号
  USER_MEDIUM_DELETE: `${baseUrl}/v1/users/medium/delete`,
  USER_MEDIUM_SAVE: `${baseUrl}/v1/users/medium/save`,
  USER_MEDIUM_DETAIL: `${baseUrl}/v1/users/medium/detail`,
  USER_MEDIUM_REVIEW: `${baseUrl}/v1/users/medium/review`,
  USER_MEDIUM_REVIEW_LIST: `${baseUrl}/v1/users/medium/review/list`,

  // 钱包
  USER_WALLET_DETAIL: `${baseUrl}/v1/users/wallet/detail`,
  USER_WALLET_HISTORY_LIST: `${baseUrl}/v1/users/wallet/history/list`,

  /// 短信
  SMS_SEND: `${baseUrl}/v1/sms/send`,

  /// 主题
  THREADS_CATEGORIES_CACHED: `${baseUrl}/v1/threads/categories-cached`,
  THREADS_CATEGORIES: `${baseUrl}/v1/threads/categories`,
  THREADS_CATEGORIES_CREATE: `${baseUrl}/v1/threads/categories/create`,
  THREADS_CATEGORIES_UPDATE: `${baseUrl}/v1/threads/categories/update`,
  THREADS_CATEGORIES_DELETE: `${baseUrl}/v1/threads/categories/delete`,
  THREADS_LIST: `${baseUrl}/v1/threads/list`,
  THREADS_LIST_LITE: `${baseUrl}/v1/threads/list-lite`,
  THREADS_DELETE: `${baseUrl}/v1/threads/delete`,
  THREADS_DETAIL_HASHED: `${baseUrl}/v1/threads/detail/hashed`,
  THREADS_DETAIL_TEMPORARY: `${baseUrl}/v1/threads/detail/temporary`,
  THREADS_DETAIL_HASHED_CHECKED: `${baseUrl}/v1/threads/detail/hashed/checked`,
  THREADS_DETAIL_HASHDED_SIMPLIFY: `${baseUrl}/v1/threads/detail/hashed/simplify`,
  THREADS_SAVE: `${baseUrl}/v1/threads/save`,
  THREADS_LIST_USER_UNCHECKED: `${baseUrl}/v1/threads/list-user-unchecked`,
  THREADS_LIST_UNCHECKED: `${baseUrl}/v1/threads/list-unchecked`,
  THREADS_VIEWCOUNT: `${baseUrl}/v1/threads/viewcount`,
  THREADS_POST_CREATE: `${baseUrl}/v1/threads/post/create`,
  THREADS_POST_LIST: `${baseUrl}/v1/threads/post/list`,
  THREADS_SITEMAP: `${baseUrl}/v1/threads/sitemap`,
  THREADS_REVIEW: `${baseUrl}/v1/threads/review`,
  THREADS_PREMIUM: `${baseUrl}/v1/threads/premium`,

  // Thread tag
  THREADS_TAG_CREATE: `${baseUrl}/v1/threads/tag/create`,
  THREADS_TAG_DELETE: `${baseUrl}/v1/threads/tag/delete`,
  THREADS_TAG_LIST: `${baseUrl}/v1/threads/tag/list`,
  THREADS_TAG_LIST_ALL: `${baseUrl}/v1/threads/tag/list_all`,
  THREADS_TAG_RELATE: `${baseUrl}/v1/threads/tag/relate/public`,
  THREAD_TAG_ITEMS: `${baseUrl}/v1/threads/tag/items`,
  THREAD_BATCH_DELETE: `${baseUrl}/v1/threads/batch-delete`,

  /// 问答
  QUESTION_LIST: `${baseUrl}/v1/question/list`,
  QUESTION_LIST_LITE: `${baseUrl}/v1/question/list-lite`,
  QUESTION_DETAIL: `${baseUrl}/v1/question/detail`,
  QUESTION_DELETE: `${baseUrl}/v1/question/delete`,
  QUESTION_DETAIL_CHECKED: `${baseUrl}/v1/question/detail/checked`,
  QUESTION_DETAIL_SIMPLIFY: `${baseUrl}/v1/question/detail/simplify`,
  QUESTION_SAVE: `${baseUrl}/v1/question/save`,
  QUESTION_VIEWCOUNT: `${baseUrl}/v1/question/viewcount`,
  QUESTION_POST_CREATE: `${baseUrl}/v1/question/post/create`,
  QUESTION_POST_LIST: `${baseUrl}/v1/question/post/list`,
  QUESTION_BATCH_REVIEW: `${baseUrl}/v1/question/batch-review`,
  QUESTION_BATCH_DELETE: `${baseUrl}/v1/question/batch-delete`,
  QUESTION_ATTACHMENT_CREATE: `${baseUrl}/v1/question/attachment/create`,
  QUESTION_ATTACHMENT_DELETE: `${baseUrl}/v1/question/attachment/delete`,

  //---文件系统-----
  FS_CREDENTIAL: `${baseUrl}/v1/fs/credential`,
  FS_DOWNLOAD_IMAGE: `${baseUrl}/v1/fs/download_image`,

  /// 表情
  EMOJI_QQ: `${baseUrl}/v1/emoji/qq`,

  /// 举报
  REPORT_CREATE: `${baseUrl}/v1/report/create`,
  REPORT_LIST: `${baseUrl}/v1/report/list`,
  REPORT_MARK: `${baseUrl}/v1/report/mark`,

  /// 公告
  ANNOUNCEMENT_CREATE: `${baseUrl}/v1/announcement/create`,
  ANNOUNCEMENT_DELETE: `${baseUrl}/v1/announcement/delete`,
  ANNOUNCEMENT_DETAIL: `${baseUrl}/v1/announcement/detail`,
  ANNOUNCEMENT_LIST: `${baseUrl}/v1/announcement/list`,

  /// 验证码
  CAPTCHA_GENERATE_IMAGE: `${baseUrl}/v1/captcha/generate`,

  /// 友情链接
  EXTERNAL_LINK_CREATE: `${baseUrl}/v1/external_link/create`,
  EXTERNAL_LINK_DELETE: `${baseUrl}/v1/external_link/delete`,
  EXTERNAL_LINK_LIST: `${baseUrl}/v1/external_link/list`,
  EXTERNAL_LINK_LIST_DISPLAY: `${baseUrl}/v1/external_link/list/display`,

  /// 搜索引擎
  SEARCH_ENGINE_POST_URLS: `${baseUrl}/v1/search_engine/post`,

  /// 内容创作工具
  CONTENT_PSEUDO: `${baseUrl}/v1/content/pseudo`,
  CONTENT_KEYWORD_INDEX: `${baseUrl}/v1/content/keywords/index`,
  CONTENT_CHAT_COMPLETION: `${baseUrl}/v1/content/chat/completion`,

  // 城市联动
  AREA_CITY_JSON: `${app.baseHost}/js/area/area_format_object.json`,

  // Pexels
  PEXELS_SEARCH: `${baseUrl}/v1/pexels/search`,

  // 搜索
  SEARCH_SYNC_PUBLIC: `${baseUrl}/v1/search/sync/public`,
  SEARCH_DO: `${baseUrl}/v1/search/do`,
  SEARCH_INDEX_CLEAN: `${baseUrl}/v1/search/index/clean`,

  // 订单
  ORDER_CREATE: `${baseUrl}/v1/order/create`,
  ORDER_CANCEL: `${baseUrl}/v1/order/cancel`,
  ORDER_LIST: `${baseUrl}/v1/order/list`,
  ORDER_DETAIL: `${baseUrl}/v1/order/detail`,
  ORDER_DELIVER: `${baseUrl}/v1/order/deliver`,

  // 支付
  PAYMENT_DO: `${baseUrl}/v1/payment/do`,

  // Hero 相关
  HERO_SAVE: `${baseUrl}/v1/hero/gallery/save`,
  HERO_DELETE: `${baseUrl}/v1/hero/gallery/delete`,
  HERO_LIST: `${baseUrl}/v1/hero/gallery/list`,

  // 首页相关
  INDEX_COMBINED: `${baseUrl}/v1/index/combined`,

  // 团队相关
  TEAM_SAVE: `${baseUrl}/v1/team/save`,
  TEAM_MINE: `${baseUrl}/v1/team/mine`,

  // TLD相关
  TLD_BOOTSTRAP: `${baseUrl}/v1/tld/bootstrap`,
  TLD_REGISTRAR_SAVE: `${baseUrl}/v1/tld/registrar/save`,
  TLD_REGISTRAR_DELETE: `${baseUrl}/v1/tld/registrar/delete`,
  TLD_REGISTRAR_DISCOUNT_DELETE: `${baseUrl}/v1/tld/registrar/discount/delete`,
  TLD_REGISTRAR_DISCOUNT_SAVE: `${baseUrl}/v1/tld/registrar/discount/save`,
  TLD_SAVE: `${baseUrl}/v1/tld/save`,
  TLD_DELETE: `${baseUrl}/v1/tld/delete`,
  TLD_DETAIL: `${baseUrl}/v1/tld/detail`,
  TLD_LITE_LIST: `${baseUrl}/v1/tld/list/lite`,
  TLD_LIST: `${baseUrl}/v1/tld/list`,
  TLD_ENABLE: `${baseUrl}/v1/tld/enable`,
  TLD_PRICE_SYNC: `${baseUrl}/v1/tld/price/sync`,
  TLD_RECOMMEND: `${baseUrl}/v1/tld/recommend`,

  // 域名相关
  DOMAIN_AVAILABILITY: `${baseUrl}/v1/domain/availability`,
  DOMAIN_CONTACT_SAVE: `${baseUrl}/v1/domain/contact/save`,
  DOMAIN_CONTACT_DELETE: `${baseUrl}/v1/domain/contact/delete`,
  DOMAIN_CONTACT_LIST: `${baseUrl}/v1/domain/contact/list`,
  DOMAIN_CONTACT_DETAIL: `${baseUrl}/v1/domain/contact/detail`,
  DOMAIN_CONTACT_WFC: `${baseUrl}/v1/domain/contact/wfc`,
  DOMAIN_CONTACT_REFRESH: `${baseUrl}/v1/domain/contact/refresh`,
  DOMAIN_LIST: `${baseUrl}/v1/domain/list`,
  DOMAIN_ADD: `${baseUrl}/v1/domain/add`,
  DOMAIN_DETAIL: `${baseUrl}/v1/domain/detail`,
  DOMAIN_REFRESH: `${baseUrl}/v1/domain/refresh`,
  DOMAIN_ABANDON: `${baseUrl}/v1/domain/abandon`,
  DOMAIN_AUTORENEW: `${baseUrl}/v1/domain/autorenew`,
  DOMAIN_LOCK: `${baseUrl}/v1/domain/lock`,
  DOMAIN_CERT: `${baseUrl}/v1/domain/cert`,
  DOMAIN_NS_SAVE: `${baseUrl}/v1/domain/ns/save`,
  DOMAIN_DNS_RECORD_SAVE: `${baseUrl}/v1/dns/record/save`,
  DOMAIN_DNS_RECORD_DELETE: `${baseUrl}/v1/dns/record/delete`,
  DOMAIN_DNS_RECORD_LIST: `${baseUrl}/v1/dns/record/list`,
  DOMAIN_RENEW_PRICE: `${baseUrl}/v1/renew/price`,
  DOMAIN_COMMENT: `${baseUrl}/v1/domain/comment`,
  DOMAIN_MODIFY_CONTACT: `${baseUrl}/v1/domain/modify/contact`,

  // 域名交易
  DOMAIN_TRADE_FIXED_SYNCALL: `${baseUrl}/v1/domain/trade/fixed/sync_all`,
  DOMAIN_TRADE_DETAIL: `${baseUrl}/v1/domain/trade/detail`,
  DOMAIN_TRADE_LIST: `${baseUrl}/v1/domain/trade/list`,
  DOMAIN_TRADE_TLDS: `${baseUrl}/v1/domain/trade/tlds`,

  DOMAIN_DNSSEC_ADD: `${baseUrl}/v1/domain/dnssec/add`,
  DOMAIN_DNSSEC_DEL: `${baseUrl}/v1/domain/dnssec/del`,
  DOMAIN_DNSSEC_SYNC: `${baseUrl}/v1/domain/dnssec/sync`,

  // 域名转入
  DOMAIN_TRANSFER_CHECK: `${baseUrl}/v1/domain/transfer/check`,
  DOMAIN_TRANSFER_LIST: `${baseUrl}/v1/domain/transfer/list`,
  DOMAIN_TRANSFER_REFRESH: `${baseUrl}/v1/domain/transfer/refresh`,
  DOMAIN_TRANSFER_CANCEL: `${baseUrl}/v1/domain/transfer/cancel`,


  // 控制台相关
  CONSOLE_OVERVIEW: `${baseUrl}/v1/console/overview`,

  // 工单相关
  TICKET_BOOTSTRAP: `${baseUrl}/v1/ticket/bootstrap`,
  TICKET_SAVE: `${baseUrl}/v1/ticket/save`,
  TICKET_LIST: `${baseUrl}/v1/ticket/list`,
  TICKET_MANAGER_LIST: `${baseUrl}/v1/ticket/manager/list`,
  TICKET_DETAIL: `${baseUrl}/v1/ticket/detail`,
  TICKET_DELETE: `${baseUrl}/v1/ticket/delete`,
  TICKET_CLOSE: `${baseUrl}/v1/ticket/close`,
  TICKET_SCORE: `${baseUrl}/v1/ticket/score`,
  TICKET_REVIEW: `${baseUrl}/v1/ticket/review`,
  TICKET_HISTORY_SAVE: `${baseUrl}/v1/ticket/history/save`,
  TICKET_HISTORY_LIST: `${baseUrl}/v1/ticket/history/list`,
  TICKET_HISTORY_DELETE: `${baseUrl}/v1/ticket/history/delete`,

  // WBS
  WBS_SSL_BOOTSTRAP: `${baseUrl}/v1/wbs/ssl/bootstrap`,
  WBS_SSL_SAVE: `${baseUrl}/v1/wbs/ssl/save`,
  WBS_SSL_LIST: `${baseUrl}/v1/wbs/ssl/list`,
  WBS_SSL_DELETE: `${baseUrl}/v1/wbs/ssl/delete`,
  WBS_SSL_DETAIL: `${baseUrl}/v1/wbs/ssl/detail`,
  WBS_SSL_STATUS: `${baseUrl}/v1/wbs/ssl/status`,
  WBS_SSL_PRELOAD: `${baseUrl}/v1/wbs/ssl/preload`,
  WBS_SSL_PLAN_SAVE: `${baseUrl}/v1/wbs/ssl/plan/save`,
  WBS_SSL_PLAN_LIST: `${baseUrl}/v1/wbs/ssl/plan/list`,
  WBS_SSL_PLAN_USER: `${baseUrl}/v1/wbs/ssl/plan/user`,
  WBS_SSL_PLAN_DELETE: `${baseUrl}/v1/wbs/ssl/plan/delete`,

  // SSL
  SSL_BOOTSTRAP: `${baseUrl}/v1/ssl/bootstrap`,
  SSL_CSR_SAVE: `${baseUrl}/v1/ssl/csr/save`,
  SSL_CSR_LIST: `${baseUrl}/v1/ssl/csr/list`,
  SSL_CSR_DELETE: `${baseUrl}/v1/ssl/csr/delete`,


  // DATA
  DATA_SPIDER_LOOKUP: `${baseUrl}/v1/data/spider/lookup`,

}