import { render, staticRenderFns } from "./login.vue?vue&type=template&id=300e7516&lang=pug"
import script from "./login.vue?vue&type=script&lang=js"
export * from "./login.vue?vue&type=script&lang=js"
import style0 from "./login.vue?vue&type=style&index=0&id=300e7516&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UserLoginTab: require('/Users/weizhang/Projects/mingcui_web/components/user/login/Tab.vue').default,SmsSendButton: require('/Users/weizhang/Projects/mingcui_web/components/sms/SendButton.vue').default,CommonPrivacyCheck: require('/Users/weizhang/Projects/mingcui_web/components/common/PrivacyCheck.vue').default})
