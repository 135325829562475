import isEmpty from 'lodash/isEmpty';
import { MessageBox } from 'element-ui';

// 注意按需引入样式
// import '~/styles/element/message-box.css';

export default {
  // 验证码实例
  captcha: null,

  // 初始化，但不实际进行点击的
  // 注意，初始化后，实际上会创建一个对话框，此时
  // callback 中取得 captchaVerifyParam
  async show(callback, sceneId, onBizResultCallback) {

    // 务必传入回调函数，将用于API后续请求的
    if (typeof callback !== "function") {
      throw "callback function invalid";
    }

    // 前端可能传入不同的场景ID
    let scene = sceneId;
    if (isEmpty(scene)) {
      scene = "1oixh7aj";
    }

    // 如果没有正确传入onBizResultCallback
    if (typeof onBizResultCallback !== "function") {
      onBizResultCallback = function (bizResult) {
        if (bizResult === true) {
          console.log("业务验证通过");
          return;
        }
      }
    }

    // 延迟执行，因为下面的$alert需要挂载dom
    setTimeout(() => {
      // 弹出式
      initAliyunCaptcha({
        SceneId: scene, // 场景ID。根据步骤二新建验证场景后，您可以在验证码场景列表，获取该场景的场景ID
        prefix: 'pkm8wr', // 身份标。开通阿里云验证码2.0后，您可以在控制台概览页面的实例基本信息卡片区域，获取身份标
        mode: 'embed', // 验证码模式。popup表示要集成的验证码模式为弹出式。无需修改
        element: '#captcha-element', //页面上预留的渲染验证码的元素，与原代码中预留的页面元素保持一致。
        button: 'div', // 触发验证码弹窗的元素。button表示单击登录按钮后，触发captchaVerifyCallback函数。您可以根据实际使用的元素修改element的值
        captchaVerifyCallback: callback, // 业务请求(带验证码校验)回调函数，无需修改
        onBizResultCallback: onBizResultCallback, // 业务请求结果回调函数，无需修改
        getInstance: this.getInstance, // 绑定验证码实例函数，无需修改
        immediate: true, // 嵌入式下，完成验证后，是否立即发送验证请求（调用captchaVerifyCallback函数）。
        slideStyle: {
          width: 300,
          height: 40,
        }, // 滑块验证码样式，支持自定义宽度和高度，单位为px。其中，width最小值为320 px
        language: 'cn', // 验证码语言类型，支持简体中文（cn）、繁体中文（tw）、英文（en）
      });
    }, 10);

    // 安全认证弹出
    MessageBox.alert(`<div><p style="margin-bottom: 10px;">请先完成下方的安全验证后继续</p><div id="captcha-element">正在加载验证码，请稍等</div></div>`, '安全认证', {
      closeOnClickModal: false,
      dangerouslyUseHTMLString: true,
      showCancelButton: false,
      confirmButtonText: '关闭',
    });
  },

  // 绑定验证码实例函数。该函数为固定写法，无需修改
  getInstance(instance) {
    this.captcha = instance;
  },

  // 关闭
  close() {
    MessageBox.close();
  }

}