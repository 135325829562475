import ticketApi from "~/utils/api/ticket";
import isEmpty from 'lodash/isEmpty';

export const state = () => ({
  bootstrap: null,
  ticketStatusList: []
})

export const mutations = {
  //- update bootstrap information
  updateBootstrap(state, bootstrap) {
    state.bootstrap = bootstrap;

    /// 更新bootstrap多想配置
    if (typeof bootstrap === "object") {
      state.ticketStatusList = bootstrap.ticketStatusList || [];
    }
  },
}

export const actions = {
  //- 启动配置
  async bootstrap(context) {
    if (!isEmpty(context.state.bootstrap)) {
      return;
    }

    const rs = await ticketApi.bootstrap();
    if (rs) {
      context.commit("updateBootstrap", rs.data);
      return true;
    }
    return;
  },
}

export const namespaced = true;
