
import { mapState } from "vuex";

export default {
  props: {
    value: {
      type: Number,
      default: null
    }
  },
  computed: {
    ...mapState("threads", {
      threadCatgories: (state) => state.threadCatgories,
    }),
  },
  mounted() {
    this.$nextTick(async () => {
      await this.updateThreadCatgories();

      // 自动选择
      this.autoCompl();
    });
  },
  data() {
    return {
      selectedItem: this.value,
    }
  },
  methods: {
    /**
     * 自动选中
     */
    autoCompl() {
      const { selectedItem } = this;
      if (selectedItem != null) {
        return;
      }

      const { threadCatgories } = this;
      if (this.$_.isEmpty(threadCatgories)) {
        return;
      }

      const firstCat = threadCatgories[0];
      this.selectedItem = firstCat.id || null;
      this.$emit('input', firstCat.id || null);
    },
    // 取分类列表
    async updateThreadCatgories() {
      const { threadCatgories } = this;
      if (!this.$_.isEmpty(threadCatgories)) {
        return;
      }

      await this.$store.dispatch("threads/updateThreadCatgories");
    },
    /**
     * 选中
     * @param {*} e 
     */
    handleChange() {
      const { selectedItem } = this;
      this.$emit('input', selectedItem);
    }
  },
  watch: {
    value(val, _) {
      if (val == this.selectedItem) {
        return;
      }
      this.selectedItem = val;
    }
  }
}
