import req from "~/utils/request";
import { URLS } from "~/utils/request/requestUrls";

export default {

  /**
   * 组合式首页接口
   * @returns 
   */
  async combined() {
    const rs = await req.get({
      url: URLS.INDEX_COMBINED
    });
    return rs.data;
  },
}