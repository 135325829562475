import { render, staticRenderFns } from "./default.vue?vue&type=template&id=60cfb73f&lang=pug"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonDebugBanner: require('/Users/weizhang/Projects/mingcui_web/components/common/DebugBanner.vue').default,CommonNav: require('/Users/weizhang/Projects/mingcui_web/components/common/Nav.vue').default,SetSiteCloseSite: require('/Users/weizhang/Projects/mingcui_web/components/setSite/CloseSite.vue').default,CommonFooter: require('/Users/weizhang/Projects/mingcui_web/components/common/Footer.vue').default,EasyGoTop: require('/Users/weizhang/Projects/mingcui_web/components/easy_go_top/EasyGoTop.vue').default,MobileBottomNavigator: require('/Users/weizhang/Projects/mingcui_web/components/mobile/BottomNavigator.vue').default})
