import req from "~/utils/request";
import { URLS } from "~/utils/request/requestUrls";

export default {
  /// 使用标题查询搜索引擎关键字列表
  async do(data) {
    const rs = await req.postJson({
      url: URLS.PAYMENT_DO,
      data
    });
    return rs.data;
  },
}