
export default {
  props: {
    icon: {
      type: String,
      default: "",
    },
    iconSize: {
      type: Number,
      default: 16,
    },
    iconColor: {
      type: String,
      default: "#000000",
    },
  },
};
