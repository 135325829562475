
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      agree: this.value,
    };
  },
  watch: {
    /**
     * 监听变化
     * @param {*} val
     * @param {*} _
     */
    agree(v, _) {
      this.$emit("input", v);
    },
  },
};
