import isEmpty from 'lodash/isEmpty';
import enc from '~/utils/enc';

//- client side storage
export default class Storage {
  constructor() {
    if (!process.client) {
      return;
    }

    this.store = localStorage || window.localStorage;
  }

  //- set
  set(key, val) {
    if (!process.client) {
      return;
    }

    try {
      this.store.setItem(key, enc.encrypt(val.toString()));
    } catch (error) {
      throw error;
    }
  }

  //- get
  get(key) {
    if (!process.client) {
      return "";
    }

    try {
      let val = window.localStorage.getItem(key);
      if (isEmpty(val)) {
        return "";
      }
      return enc.decrypt(val);
    } catch (error) {
      throw error;
    }
  }

  // 删除
  del(key) {
    this.store.removeItem(key);
  }

  //- clean all
  clean() {
    this.store.clear();
  }
}
