const pkg = require("../package.json");
const app = require("../nuxt.app");

module.exports = {
  /**
   * 是否是生产模式
   * @returns bool
   */
  get isProduction() {
    return process.env.NODE_ENV === 'production';
  },

  /**
   * 是否是客户端
   */
  get isClient() {
    return !process.server;
  },

  // 取版本
  get version() {
    return `${pkg.version}+${pkg.build}`;
  },

  // 取版本
  get build() {
    return pkg.build;
  },

  /**
   * 应用包路径
   */
  get bundlePath() {
    return `/${pkg.name}/${pkg.version}/${pkg.build}`;
  },


  /**
   * 发布路径
   */
  get publicPath() {
    const { isProduction, bundlePath } = this;
    // return bundlePath;
    return isProduction ? `${app.cdnDomain}${bundlePath}` : bundlePath;
  },


  /**
   * 取得判断设备是否是IphoneX
   */
  get isIphoneX(){
    if (typeof window !== 'undefined' && window) {
      var faultTolerantVal = 10; // 容错值
      return /iphone/gi.test(window.navigator.userAgent) && (window.screen.height + faultTolerantVal) >= 812;
    }
    return false;
  }
}