import req from "~/utils/request";
import { URLS } from "~/utils/request/requestUrls";

export default {
  //- 取问题详情
  async detail(id) {
    const rs = await req.get({
      url: `${URLS.QUESTION_DETAIL}/${id}`
    });
    return rs.data;
  },
  //- 取问题详情
  async detailChecked(id) {
    const rs = await req.get({
      url: `${URLS.QUESTION_DETAIL_CHECKED}/${id}`
    });
    return rs.data;
  },
  //- 简化的
  async detailSimplify(id) {
    const rs = await req.get({
      url: `${URLS.QUESTION_DETAIL_SIMPLIFY}/${id}`
    });
    return rs.data;
  },
  //- 列表
  async list(data) {
    const rs = await req.postJson({
      url: URLS.QUESTION_LIST,
      data
    });
    return rs.data;
  },
  //- 精简版
  async listLite(data) {
    const rs = await req.postJson({
      url: URLS.QUESTION_LIST_LITE,
      data
    });
    return rs.data;
  },
  //- 创建问题
  async create(data) {
    const rs = await req.postJson({
      url: URLS.QUESTION_SAVE,
      data
    });
    return rs.data;
  },
  //- 删除问题
  async delete(id) {
    const rs = await req.get({
      url: `${URLS.QUESTION_DELETE}/${id}`
    });
    return rs.data;
  },
  //- 问题查看次数统计
  async viewCount(id) {
    const rs = await req.get({
      url: `${URLS.QUESTION_VIEWCOUNT}/${id}`
    });
    return rs.data;
  },
  // 发布回答
  async questionPostCreate(questionID, data) {
    const rs = await req.postJson({
      url: `${URLS.QUESTION_POST_CREATE}/${questionID}`,
      data
    });
    return rs.data;
  },
  // 回答列表
  async questionPostList(questionID, data) {
    const rs = await req.postJson({
      url: `${URLS.QUESTION_POST_LIST}/${questionID}`,
      data
    });
    return rs.data;
  },
  //- 批量审核
  async batchReview(data) {
    const rs = await req.postJson({
      url: URLS.QUESTION_BATCH_REVIEW,
      data
    });
    return rs.data;
  },
  //- 批量删除
  async batchDelete(data) {
    const rs = await req.postJson({
      url: URLS.QUESTION_BATCH_DELETE,
      data
    });
    return rs.data;
  },
  //- 创建附件
  async attachmentCreate(data) {
    const rs = await req.postJson({
      url: URLS.QUESTION_ATTACHMENT_CREATE,
      data
    });
    return rs.data;
  },
  //- 删除附件
  async attachmentDelete(id) {
    const rs = await req.get({
      url: `${URLS.QUESTION_ATTACHMENT_DELETE}/${id}`
    });
    return rs.data;
  },
}