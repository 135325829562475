
import quetionApi from "~/utils/api/question";

export default {
  props: {
    /**
     * 关联问题
     */
    question: {
      type: Object,
      default() {
        return null;
      }
    }
  },
  data() {
    return {
      content: "",
      isAnnoy: true,
      loading: false,
    }
  },
  methods: {
    /**
     * 发布
     */
    async handleClick() {
      const { content, isAnnoy, question } = this;

      if (this.$_.isEmpty(content)) {
        this.$toast.show("请先填写内容");
        return;
      }

      if (this.$_.isEmpty(question)) {
        this.$toast.show("问题配置错误，请稍等问题初始化");
        return;
      }

      this.loading = true;

      try {
        const rs = await quetionApi.questionPostCreate(question.id, {
          content,
          isAnnoy
        });
        if (rs) {
          this.content = "";
          this.isAnnoy = true;
          this.$toast.notify({
            title: "发布成功",
            message: "您的回答发布成功将在审核通过后显示（2个工作日）",
            type: "success",
          });
          this.$emit('created', rs.data || null);
        }
      } catch (error) {

      }

      this.loading = false;
    }
  }
}
