
import userMixins from "~/mixins/user";
import fingerPrintjs2Mixin from '~/mixins/fingerPrintjs2Mixin';

export default {
  mixins: [userMixins, fingerPrintjs2Mixin],
  head() {
    return {
      title: this.$app.getTitle("用户登录"),
    };
  },
  data() {
    return {
      username: "",
      password: "",
      loading: false,
      agree: true,
      // 登录后回调的位置
      callback: this.$route.query.callback || "",
      // 登录模式
      mode: 'sms',

      //- 短信验证码登录时的参数
      // 发送短信回调数据
      smsCallbackData: {},
      mobile: "",
      verifyCode: ""
    };
  },
  computed: {
    /**
     * 是否禁用登录按钮
     */
    disablePasswordLoginButton() {
      const { loading, password, username, agree } = this;
      if (
        !agree ||
        loading ||
        this.$_.isEmpty(username) ||
        this.$_.isEmpty(password)
      ) {
        return true;
      }
      return false;
    },
    /**
     * 是否禁用短信登录按钮
     */
    disableSMSLoginButton() {
      const { loading, mobile, verifyCode, smsCallbackData, agree } = this;
      if (
        !agree ||
        loading ||
        this.$_.isEmpty(mobile) ||
        this.$_.isEmpty(smsCallbackData) ||
        this.$_.isEmpty(verifyCode)
      ) {
        return true;
      }
      return false;
    }
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.hasLoginedNotice();
      }, 2000);
    });
  },
  methods: {
    /**
     * 短信验证码发送回调
     * @param {*} e
     */
    handleSendSMS(d) {
      console.log(d);
      this.smsCallbackData = d;
    },
    /**
     * 使用短信验证码登录
     */
    async handleSMSLogin() {
      const { smsCallbackData, mobile, verifyCode } = this;

      if (this.$_.isEmpty(smsCallbackData)) {
        this.$toast.show("请先发送短信验证码");
        return;
      }

      if (this.$_.isEmpty(mobile)) {
        this.$toast.show("请输入手机号");
        return;
      }

      if (this.$_.isEmpty(verifyCode)) {
        this.$toast.show("请输入短信验证码");
        return;
      }

      this.loading = true;
      let ok = false;

      // 登录处理在状态管理中进行完成
      try {
        ok = await this.$store.dispatch("users/smsLogin", {
          ...smsCallbackData,
          mobile,
          verifyCode
        });
      } catch (err) {
        console.error(err);
      }

      this.loading = false;

      if (!ok) {
        return;
      }

      //- 成功的回调
      this.successCallback();
    },
    /**
     * 用户登录
     */
    async handleLogin() {
      const { username, password } = this;

      this.loading = true;
      let ok = false;

      try {
        ok = await this.$store.dispatch("users/passwordLogin", {
          username,
          password,
        });
      } catch (err) {
        console.error(err);
      }

      this.loading = false;

      if (!ok) {
        return;
      }

      //- 成功的回调
      this.successCallback();
    },

    /// 处理登录成功后的回调
    successCallback() {
      this.bindUserFingerPrintHash();
      this.$toast.show({ message: "登录成功，即将跳转...", type: "success" });

      setTimeout(() => {
        const { callback } = this;
        const forwardUri = this.$_.isEmpty(callback) ? "/" : decodeURI(callback);
        window.location.href = forwardUri;
      }, 1000);
    },
  },
};
