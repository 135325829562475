
import debounce from "lodash/debounce";
import userApi from "~/utils/api/users";
import userMixins from "~/mixins/user";

export default {
  mixins: [userMixins],
  name: "page-user-create",
  head() {
    return {
      title: this.$app.getTitle("用户注册"),
    };
  },
  data() {
    return {
      mobile: "",
      username: "",
      password: "",
      // 短信验证码
      verifyCode: "",
      registerReason: "website",
      loading: false,
      agree: true,
      // 检查用户名是否可用
      usernameAvailable: false,
      usernameErrorNotice: "",
      usernameValidating: false,
      // 发送短信回调数据
      smsCallbackData: {},
    };
  },
  computed: {
    /**
     * 是否禁用登录按钮
     */
    disableButton() {
      const { loading, password, mobile, username, agree } = this;
      if (
        !agree ||
        loading ||
        this.$_.isEmpty(username) ||
        this.$_.isEmpty(mobile) ||
        this.$_.isEmpty(password)
      ) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.hasLoginedNotice();
      }, 2000);
    });
  },
  methods: {
    /**
     * 短信验证码发送回调
     * @param {*} e
     */
    handleSendSMS(d) {
      console.log(d);
      this.smsCallbackData = d;
    },
    /**
     * 用户注册
     */
    async handleRegister() {
      const { disableButton, usernameAvailable, smsCallbackData } = this;
      if (disableButton) {
        this.$toast.show("请完善表单后继续");
        return;
      }

      if (!usernameAvailable) {
        this.$toast.show("用户名已经被注册");
        return;
      }

      if (this.$_.isEmpty(smsCallbackData)) {
        this.$toast.show("请先发送短信验证码");
        return;
      }

      //- 开始发送注册请求
      this.loading = true;

      try {
        const { username, mobile, password, verifyCode, registerReason } = this;
        //- TODO
        const rs = await userApi.create({
          username,
          mobile,
          password,
          verifyCode,
          registerReason,
          ...smsCallbackData,
        });
        if (rs) {
          //- 注册成功，前往登录
          this.$toast.notify({
            title: "感谢加入",
            message: "您的账号注册成功，现在您可以前往登录",
            type: "success",
          });

          setTimeout(async () => {
            await this.$store.dispatch("users/redirect", {
              // 不自动补全回调地址。防止登录后跳转回注册页
              preventCallbackRedirectLink: true,
            });
          }, 2000);
        }
      } catch (error) {}

      this.loading = false;
    },
    /**
     * 检测用户名可用性
     */
    checkUsernameAvailability: debounce(async (self, username) => {
      if (self.$_.isEmpty(username)) {
        self.usernameAvailable = false;
        self.usernameErrorNotice = "";
        return;
      }

      self.usernameValidating = true;

      try {
        const rs = await userApi.nameValidate({
          username,
        });
        if (rs) {
          self.usernameAvailable = rs.data.available || false;
          self.usernameErrorNotice = rs.data.errorMessage || "";
        }
      } catch (error) {
        console.error(error);
      }

      self.usernameValidating = false;
    }, 500),
  },
  watch: {
    /**
     * 用户名发生变化时，发起可用性检测
     * @param {*} val
     * @param {*} _
     */
    async username(val, _) {
      await this.checkUsernameAvailability(this, val);
    },
  },
};
