
import { mapState } from "vuex";
import domainApi from "~/utils/api/domain";
import orderApi from "~/utils/api/order";
import metasMixins from "~/mixins/metas";

export default {
  mixins: [metasMixins],
  data() {
    return {
      selectedContact: null,
      loading: false,
      contacts: [],
      orderCreating: false,
    }
  },
  computed: {
    ...mapState("carts", { domains: (state) => state.domains }),
  },
  mounted() {
    this.$nextTick(async () => {
      await this.$store.dispatch('carts/restoreFromLocal');
      await this.getContacts();
    });
  },

  methods: {
    /**
     * 刷新联系人列表
     */
    async handleRefreshContacts() {
      this.contacts = [];
      this.selectedContact = null;
      await this.getContacts();
    },
    /**
     * 获取联系人模板
     * @param {*} targetMetas 
     */
    async getContacts(targetMetas) {
      const { metas } = this;

      const willLoadMetas = targetMetas || metas;
      let data = {
        ...willLoadMetas,
      };

      try {
        this.loading = true;
        const rs = await domainApi.contactList({ ...data });
        if (rs) {
          this.metas = rs.metas;
          this.contacts = rs.data || [];
        }
      } catch (err) {
        console.error(err);
      }

      this.loading = false;
    },
    /**
     * 清空
     */
    async handleClearAll() {
      try {
        const rs = await this.$toast.confirm({
          title: "提示",
          message: "您是否确定清空购物车?",
        });
        if (rs) {
          this.$store.dispatch('carts/clearAll')
        }
      } catch (error) {
        console.warn("用户取消", error);
      }
    },
    /**
   * 分页变化
   * @param {*} val 
   */
    async handleCurrentChange(val) {
      const { metas } = this;
      let willLoadMeta = { ...metas };
      willLoadMeta.currentPage = val;

      await this.getContacts(willLoadMeta);
    },
    /**
     * 创建订单
     */
    async handleCreateOrder() {

      // 创建前请先登录
      await this.$store.dispatch("users/redirect");

      const { domains, selectedContact } = this;
      if (this.$_.isEmpty(domains)) {
        this.$toast.show({
          message: "请先查询域名添加至购物车在继续",
          type: "warning"
        });
        return;
      }

      // 检查用户ContactID联系人模板信息，让用户选择指定的注册模板
      if (!selectedContact) {
        this.$toast.show({
          message: "请先选择或创建指定的联系模板在进行注册",
          type: "warning"
        });
        return;
      }

      // 遍历domains，生成orderitem在提交创建订单
      let items = [];
      domains.forEach(element => {
        const item = {
          productType: "domain",
          name: `域名注册${element.domainName}`,
          action: "create",
          processData: {
            ...element,
            year: 1, // 强制设置为1
            contactID: selectedContact
          }
        };
        items.push(item);
      });
      console.info(items);
      // 创建订单

      this.orderCreating = true;

      try {
        const rs = await orderApi.create({
          "orderType": 2,
          "fee": 0,// orderType时，fee会被后端重新计算的
          items
        });
        if (rs) {
          const order = rs.data;
          // 下单后清空购物车
          await this.$store.dispatch('carts/clearAll');
          this.$toast.show({
            message: "订单创建成功，2秒后将带您继续支付订单",
            type: "success"
          });
          
          setTimeout(() => {
            window.location.href = `/console/order/detail/${order.id}`;
          }, 2000);
        }
      } catch (err) {
        console.error(err);
      }

      this.orderCreating = false;
    },
    /**
     * 删除购物车项
     * @param {*} item 
     */
    async handleDelete(item) {
      try {
        const rs = await this.$toast.confirm({
          title: "提示",
          message: `是否从购物车移除${item.domainName}`,
        })
        if (rs) {
          this.$store.dispatch('carts/deleteDomain', item.domainName)
        }
      } catch (error) {
        console.warn("用户取消", error);
      }
    }
  }
}
