
import easyGoTopMixin from '~/components/easy_go_top/mixins';
import { mapState } from "vuex";

export default {
  name: 'easy-go-top',
  props: {
    size: Number, // 大小
    color: String, // 字体颜色
    width: Number, // 宽度
    height: Number, // 高度
    radius: Number, // 圆角
    zIndex: Number, // z-index值
    right: Number, // 距离右侧距离
    bottom: Number, // 距离底部距离
    innerHTML: String, // 内容
    backgroundColor: String, // 背景色
    visibilityHeight: Number, // 可见高度
    customStyle: Object, // 自定义样式
    serviceButtonBackgroundColor: String, // 背景色
  },
  mixins: [easyGoTopMixin],
  data() {
    return {
      defaultParams: {
        color: '#fff',
        backgroundColor: '#000',
        serviceButtonBackgroundColor: "#FFFFFF",
        innerHTML: '<svg viewBox="5 0 12 18"><path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z"></path></svg>',
        visibilityHeight: 400,
        size: 50,
        zIndex: 1000,
        radius: 20,
        right: 20,
        bottom: 40
      },
      visible: false,
      interval: null,
      serviceButtonSvg: '<svg t="1678192890626" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2440"><path d="M554.666667 768v-42.666667h85.333333v42.666667h85.333333v-320C725.333333 341.333333 640 256 533.333333 256S341.333333 341.333333 341.333333 448V853.333333H213.333333v-256h42.666667v-149.333333C256 294.4 379.733333 170.666667 533.333333 170.666667S810.666667 294.4 810.666667 448V597.333333h42.666666v256h-298.666666v-85.333333z" fill="#444444" p-id="2441"></path></svg>',
      cartButtonSvg: '<?xml version="1.0" encoding="UTF-8"?><svg width="26" height="26" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M39 32H13L8 12H44L39 32Z" fill="none"/><path d="M3 6H6.5L8 12M8 12L13 32H39L44 12H8Z" stroke="#2365F2" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><circle cx="13" cy="39" r="3" stroke="#2365F2" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><circle cx="39" cy="39" r="3" stroke="#2365F2" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg>'
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.$nextTick(async () => {
      await this.$store.dispatch('carts/restoreFromLocal');
    });
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    ...mapState("carts", { domains: (state) => state.domains }),
    customParams() {
      const customParams = {
        color: this.color,
        backgroundColor: this.backgroundColor,
        innerHTML: this.innerHTML,
        zIndex: this.zIndex,
        visibilityHeight: this.visibilityHeight,
        width: this.width,
        height: this.height,
        size: this.size,
        right: this.right,
        bottom: this.bottom,
        radius: this.radius,
        customStyle: this.customStyle
      }
      for (const key in customParams) {
        if (customParams[key] === undefined) {
          delete customParams[key]
        }
      }
      return customParams
    },
    params() {
      // 合并默认参与与全局参数(自定义 > 全局 > 默认)
      return Object.assign({}, this.defaultParams, this.$goTopGlobalParams, this.customParams)
    },
    computedWidth() {
      return this.width || this.params.width || this.params.size || this.size
    },
    computedHeight() {
      return this.height || this.params.height || this.params.size || this.size
    },
    /**
     * 返回顶部按钮样式
     */
    styles() {
      const params = this.params
      const styles = {
        width: this.computedWidth + 'px',
        height: this.computedHeight + 'px',
        lineHeight: this.computedHeight + 'px',
        fontSize: this.computedWidth + 'px',
        color: params.color,
        zIndex: params.zIndex,
        right: params.right + 'px',
        borderRadius: params.radius + '%',
        backgroundColor: params.backgroundColor
      }
      if (this.visible === false) {
        styles.bottom = (-this.computedHeight) + 'px'
        styles.opacity = 0
      } else {
        styles.bottom = params.bottom + 'px'
        styles.opacity = 1
      }
      return Object.assign({}, styles, params.customStyle)
    },

    /**
     * 服务按钮颜色
     */
    servieButtonstyles() {
      const params = this.params
      const styles = {
        width: this.computedWidth + 'px',
        height: this.computedHeight + 'px',
        color: params.color,
        zIndex: params.zIndex,
        right: params.right + 'px',
        borderRadius: params.radius + '%',
        backgroundColor: params.serviceButtonBackgroundColor,
        padding: '10px',
      }

      styles.bottom = this.computedHeight + params.bottom + 10 + 'px'
      styles.opacity = 1

      return Object.assign({}, styles, params.customStyle)
    },

    cartButtonstyles() {
      const params = this.params
      const styles = {
        width: this.computedWidth + 'px',
        height: this.computedHeight + 'px',
        color: params.color,
        zIndex: params.zIndex,
        right: params.right + 'px',
        borderRadius: params.radius + '%',
        backgroundColor: params.serviceButtonBackgroundColor,
        padding: '10px',
      }

      styles.bottom = this.computedHeight + params.bottom + 70 + 'px'
      styles.opacity = 1

      return Object.assign({}, styles, params.customStyle)
    }
  },
  methods: {
    handleScroll() {
      // 滚动距离 > 可见距离
      this.visible = window.pageYOffset > this.params.visibilityHeight
    },
    // 回到顶部
    handleBackTop() {
      this.$goTop()
    },
    // 点击购物车
    handleClickCartButton() {
      this.$router.push("/product/domain/search");
    }
  }
}
