import { render, staticRenderFns } from "./add.vue?vue&type=template&id=4d83090d&scoped=true&lang=pug"
import script from "./add.vue?vue&type=script&lang=js"
export * from "./add.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d83090d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CitySelection: require('/Users/weizhang/Projects/mingcui_web/components/city/Selection.vue').default})
