import isEmpty from 'lodash/isEmpty';
import Storage from '~/utils/storage';


const _localDomainCartStorageKey = "dom_cart";

export const state = () => ({
  // 购物车域名列表
  domains: [],
})

export const mutations = {
  //- update bootstrap information
  updateDomains(state, domains) {
    state.domains = domains;
  },
}


export const actions = {
  //- 从本地还原
  restoreFromLocal(context){
    const data = new Storage().get(_localDomainCartStorageKey);
    if(isEmpty(data)){
      return;
    }

    const domains = JSON.parse(data);
     // 更新
     context.commit("updateDomains", [...domains]);
  },
  //- 更新购物车域名列表
  //- 注意，加入的应该是域名查询结果对象
  addDomains(context, domains) {
    // 不允许空的
    if (isEmpty(domains) || typeof domains !== 'object') {
      return;
    }

    // 取域名，在合并
    const olddomains = context.state.domains;
    const merged = [
      ...olddomains,
      ...domains];
      
    // 查重
    let temp = []; //一个新的临时数组
    merged.forEach((it) => {
      const findRs = temp.find(el => el.domainName == it.domainName);
      if(!findRs){
        temp.push(it)
      }
    })
    
    // 更新
    context.commit("updateDomains", [...temp]);

    // 保存在本地
    new Storage().set(_localDomainCartStorageKey, JSON.stringify([...temp]))
  },

  //- 清空域名购物车
  clearAll(context) {
    context.commit("updateDomains", []);

    // 清空本地数据
    new Storage().del(_localDomainCartStorageKey)
  },

  //- 删除指定
  deleteDomain(context, domainName) {
    const domains = context.state.domains;
    if (isEmpty(domains)) {
      return;
    }

    // 移除匹配项
    const domainFilter = domains.filter((it) => it.domainName != domainName);
    context.commit("updateDomains", [...domainFilter]);

    // 更新本地数据
    new Storage().set(_localDomainCartStorageKey, JSON.stringify([...domainFilter]))
  }

}

export const namespaced = true;