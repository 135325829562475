
export default {
  head() {
    return {
      title: this.$app.getTitle('完整版提问编辑器'),
    };
  },
  data() {
    return {};
  },
  mounted() {
    this.$nextTick(async () => {
      await this.$store.dispatch('users/redirect');
    });
  },
  methods: {
    /**
     * 发布问题成功回调
     */
     handleCreated(q){
      console.log(q);
      this.$router.push(`/faq/${q.id}.html`);
    }
  },
};
