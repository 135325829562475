import req from "~/utils/request";
import { URLS } from "~/utils/request/requestUrls";

export default {
   // 获取控制台总览信息
   async overview() {
    const rs = await req.get({
      url: URLS.CONSOLE_OVERVIEW,
    });
    return rs.data;
  },
}