import { render, staticRenderFns } from "./Editor.vue?vue&type=template&id=1159cf5d&scoped=true&lang=pug"
import script from "./Editor.vue?vue&type=script&lang=js"
export * from "./Editor.vue?vue&type=script&lang=js"
import style0 from "./Editor.vue?vue&type=style&index=0&id=1159cf5d&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1159cf5d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonIconfont: require('/Users/weizhang/Projects/mingcui_web/components/common/Iconfont.vue').default,EmojiSelector: require('/Users/weizhang/Projects/mingcui_web/components/emoji/Selector.vue').default,CommonButton: require('/Users/weizhang/Projects/mingcui_web/components/common/Button.vue').default})
