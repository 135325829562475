
import questionsApi from "~/utils/api/question";

export default {
  props: {
    /**
     * 关联文章
     */
    question: {
      type: Object,
      default() {
        return null
      }
    }
  },
  computed: {
    showQuickActions() {
      const { permissions } = this;
      if (this.$_.isEmpty(permissions)) {
        return false;
      }

      // 遍历权限对象
      let hasEditableItem = false;
      for (const key in permissions) {
        if (key != "readable" && permissions[key] == true) {
          hasEditableItem = true;
        }
      }

      return hasEditableItem;
    },
    /**
     * 帖子权限
     */
    permissions() {
      const { question } = this;
      if (this.$_.isEmpty(question) || this.$_.isEmpty(question.permission)) {
        return {};
      }

      return question.permission;
    }
  },
  methods: {
    /**
     * 删除
     */
    async handleDeleteIconClick() {
      const { question } = this;
      try {
        const ok = await this.$toast.confirm({
          title: "提示",
          message: "是否删除该问题，删除后不可恢复的?",
        });
        if (ok) {
          const done = await questionsApi.delete(question.id);
          if (done) {
            this.$router.go(-1);
          }

        }
      } catch (error) {
        console.warn("用户取消");
      }
    },
  }
}
