
export default {
  head() {
    return {
      title: this.$app.getTitle('媒体号'),
    };
  },
  data() {
    return {};
  },
  methods: {},
};
