
import { mapState } from "vuex";
import threadsApi from "~/utils/api/threads";

export default {
  props: {},
  data() {
    return {
      threadID: null, // 编辑模式
      name: "",
      content: "",
      selectedCategory: null,
      coverImage: null,
      seoKeywords: null,
      originData: null,
      // 是否直接审核发布
      // 仅管理员生效
      isChecked: false,
      submitToSearchEngine: false,
    };
  },
  computed: {
    ...mapState("users", {
      isAdminUser: (state) => state.isAdminUser,
      user: (state) => state.user
    }),
  },
  mounted() {
    this.$nextTick(() => {
      this.editModeAutoCompl();
    });
  },
  methods: {
    /**
     * 更新
     */
    handleGenerateKeywordIndex(val) {
      this.seoKeywords = val;
    },
    /**
     * 编辑模式下自动填表
     * 路由上出现query参数 update时将会被激活
     */
    async editModeAutoCompl() {
      const { user, isAdminUser } = this;
      const willUpdateThreadId = this.$route.query.update || 0;
      if (willUpdateThreadId == 0) {
        return;
      }

      try {
        const rs = await threadsApi.detailHashed(willUpdateThreadId);
        if (rs) {
          const willRestoreThread = rs.data || {};
          if (user.id != willRestoreThread.userID && !isAdminUser) {
            this.$toast.alert("非管理人员，暂不允许编辑他人发布的文章");
            return;
          }

          this.handleRestoreThread(willRestoreThread);
        }
      } catch (err) {
        console.error(err);
      }
    },
    /**
     * 选中草稿
     * @param {*} t
     */
    handleRestoreThread(t) {
      if (this.$_.isEmpty(t)) {
        return;
      }

      this.name = t.name || "";
      this.content = t.contentReviewed || "";
      this.selectedCategory = t.categoryID || null;
      this.threadID = t.id || null;
      this.coverImage = t.coverImage || null;
      this.seoKeywords = t.customKeywords || null;
      this.originData = {
        origin: t.origin || null,
        originUrl: t.originUrl || null
      }
      // console.log(t);
    },
    /**
     * 点击保存
     */
    handleSave(saveAsDraft) {
      const { name, content, selectedCategory, threadID, coverImage, seoKeywords, isChecked, originData, submitToSearchEngine } = this;
      if (this.$_.isEmpty(name)) {
        this.$toast.alert("请填写标题后在提交");
        return;
      }

      if (this.$_.isEmpty(content)) {
        this.$toast.alert("请填写内容后在提交");
        return;
      }

      if (selectedCategory == null) {
        this.$toast.alert("请选择分类后在提交");
        return;
      }

      // 如果保存为草稿，那么直接通过审核则失效
      let resetIsChecked = isChecked;
      if (saveAsDraft) {
        resetIsChecked = false;
      }

      // 保存
      let data = {
        name,
        content,
        selectedCategory,
        isDraft: saveAsDraft,
        threadID,
        coverImage,
        seoKeywords,
        formatType: "HTML",
        isChecked: resetIsChecked,
        submitToSearchEngine
      };

      if (!this.$_.isEmpty(originData)) {
        data = {
          ...data,
          ...originData,
        };
      }

      this.$emit("save", data);
    },
    handleCategorySelectionChange(e) {
      console.log("分类选择发生变化：", e);
    },
  },
};
