
import userApi from "~/utils/api/users";
import metasMixins from "~/mixins/metas";

export default {
  mixins: [metasMixins],
  data() {
    return {
      loading: true,
      address: []
    }
  },
  mounted() {
    this.$nextTick(async () => {
      await this.getAddress();
    });
  },
  methods: {
    /**
     * 删除
     */
    async handleDelete(item) {
      const ok = await this.$toast.confirm({
        title: "询问",
        message: "您是否真的要删除这个收货地址?",
      });
      if (!ok) {
        return;
      }

      try {
        const rs = await userApi.addressDelete(item.id);
        if (rs) {
          this.address = [];
          await this.getAddress();
        }
      } catch (err) {
        console.log(err);
      }


    },
    /**
     * 取地址列表
     */
    async getAddress(targetMetas) {
      const { metas } = this;

      const willLoadMetas = targetMetas || metas;
      let data = {
        ...willLoadMetas
      };

      try {
        this.loading = true;
        const rs = await userApi.addressList({ ...data });
        if (rs) {
          this.metas = rs.metas;
          this.address = rs.data || [];
        }
      } catch (err) {
        console.error(err);
      }

      this.loading = false;
    }
  }
}
