
import quetionApi from "~/utils/api/question";
import format from "~/utils/format";

export default {
  name: "page-question-detail",
  /**
   * 异步对象
   * @param {*} param0 
   */
  async asyncData({ params, error }) {
    let question = {};
    let posts = [];
    const id = params.id;
    try {

      // 查问答
      const rs = await quetionApi.detailChecked(id);
      if (rs) {
        question = rs.data;
      }

      // 加载一篇评论
      // 其实这种后期应该在接口上直接查，减少HTTP请求
      const postResp = await quetionApi.questionPostList(id);
      if (postResp) {
        posts = postResp.data || [];
      }

    } catch (err) {
      error({ status: 404, message: err.message || "未知错误" });
    }

    return {
      question,
      posts,
      // 覆盖loading
      loading: false
    }
  },
  head() {
    const { question } = this;

    let meta = [
      // 智能摘要
      // http://www.so.com/help/help_3_9.html
      {
        hid: "keywords",
        name: "keywords",
        content: question.name,
      },
      {
        hid: "description",
        name: "description",
        content: question.contentSnapshot || question.name,
      },
      // 字节时间因子
      {
        property: "bytedance:published_time",
        content: question.createdAt,
      },
      {
        property: "bytedance:lrDate_time",
        content: question.updatedAt,
      },
      {
        property: "bytedance:updated_time",
        content: question.updatedAt,
      },
    ];

    return {
      title: this.$app.getTitle(`${question.name || "问题详情页"}`),
      meta,
    };
  },
  data() {
    return {
      id: this.$route.params.id || "",
      loading: true,
      question: {},
      posts: [],
    };
  },
  computed: {
    authorName() {
      const { question } = this;
      if (question.isAnnoy) {
        return "匿名用户";
      }

      return question.author == null ? "未知" : question.author.username;
    },
    createdAt() {
      const { question } = this;
      return this.formatDate(question.createdAt || null);
    },
    cachedViewCounts() {
      const { question } = this;
      return question.cachedViewCounts || 0;
    },
    questionPostLink(){
      return `${this.$route.path}#question-posts`;
    },
    questionPostAnswerLink(){
      return `${this.$route.path}#create-answer`;
    }
  },
  methods: {
    /**
     * 答案创建回调
     * @param {*} post 
     */
    handleAnswerCreated(post) {
      if (this.$_.isEmpty(post)) {
        return;
      }

      // this.posts.push(post);
    },
    /**
     * 取答案
     */
    async getQuestionPostList() {
      this.loading = true;

      try {
        const { id } = this;
        const rs = await quetionApi.questionPostList(id);
        if (rs) {
          this.posts = rs.data || [];
        }
      } catch (err) {
        console.error(err)
      }

      this.loading = false;
    },
    /**
    * 格式化时间
    */
    formatDate(t) {
      return format.formatDate(t, "YYYY-MM-DD");
    },
    dateDiff(t) {
      return format.dateDiff(t);
    },
  },
};
