import req from "~/utils/request";
import { URLS } from "~/utils/request/requestUrls";

export default {
  /**
   * 域名可用性查询
   * @param {*} data 
   * @returns 
   */
  async availability(data) {
    const rs = await req.postJson({
      url: URLS.DOMAIN_AVAILABILITY,
      data
    });
    return rs.data;
  },
  /**
   * 保存联系人模板
   * @param {*} data 
   * @returns 
   */
  async contactSave(data) {
    const rs = await req.postJson({
      url: URLS.DOMAIN_CONTACT_SAVE,
      data
    });
    return rs.data;
  },
  /**
   * 删除联系人模板
   * @param {*} id 
   * @returns 
   */
  async contactDelete(id) {
    const rs = await req.get({
      url: `${URLS.DOMAIN_CONTACT_DELETE}/${id}`
    });
    return rs.data;
  },
  /**
   * 用户端联系人模板列表
   * @param {*} data 
   * @returns 
   */
  async contactList(data) {
    const rs = await req.postJson({
      url: URLS.DOMAIN_CONTACT_LIST,
      data
    });
    return rs.data;
  },
  /**
   * 获取联系人模板信息详情
   * @param {*} id 
   * @returns 
   */
  async contactDetail(id) {
    const rs = await req.get({
      url: `${URLS.DOMAIN_CONTACT_DETAIL}/${id}`
    });
    return rs.data;
  },
  /**
   * 刷新模板
   * @param {*} id 
   * @returns 
   */
  async contactRefresh(id) {
    const rs = await req.get({
      url: `${URLS.DOMAIN_CONTACT_REFRESH}/${id}`
    });
    return rs.data;
  },
  /**
   * 上传实名认证资料
   * @param {*} data 
   * @returns 
   */
  async contactWFC(data) {
    const rs = await req.postJson({
      url: URLS.DOMAIN_CONTACT_WFC,
      data
    });
    return rs.data;
  },
  /**
   * 查询域名列表
   * @param {*} data 
   * @returns 
   */
  async list(data) {
    const rs = await req.postJson({
      url: URLS.DOMAIN_LIST,
      data
    });
    return rs.data;
  },
  /**
   * 管理员外部域名入库
   * @param {*} data 
   * @returns 
   */
  async add(data) {
    const rs = await req.postJson({
      url: URLS.DOMAIN_ADD,
      data
    });
    return rs.data;
  },
  /**
   * 丢弃域名
   * @param {*} data 
   * @returns 
   */
  async abandon(id, data) {
    const rs = await req.postJson({
      url: `${URLS.DOMAIN_ABANDON}/${id}`,
      data
    });
    return rs.data;
  },
  /**
   * 取得域名详情
   * @param {*} id 
   * @returns 
   */
  async detail(id) {
    const rs = await req.get({
      url: `${URLS.DOMAIN_DETAIL}/${id}`
    });
    return rs.data;
  },
  /**
   * 刷新域名
   * @param {*} id 
   * @returns 
   */
  async refresh(id) {
    const rs = await req.get({
      url: `${URLS.DOMAIN_REFRESH}/${id}`
    });
    return rs.data;
  },
  /**
   * 设置自动续费
   * @param {*} data 
   * @returns 
   */
  async autoRenew(id, data) {
    const rs = await req.postJson({
      url: `${URLS.DOMAIN_AUTORENEW}/${id}`,
      data
    });
    return rs.data;
  },
  /**
   * 设置域名锁定状态
   * @param {*} data 
   * @returns 
   */
  async lock(id, data) {
    const rs = await req.postJson({
      url: `${URLS.DOMAIN_LOCK}/${id}`,
      data
    });
    return rs.data;
  },
  /**
   * 设置域名名称服务器
   * @param {*} data 
   * @returns 
   */
  async nsSave(id, data) {
    const rs = await req.postJson({
      url: `${URLS.DOMAIN_NS_SAVE}/${id}`,
      data
    });
    return rs.data;
  },
  /**
   * 保存解析记录
   * @param {*} id 
   * @param {*} data 
   * @returns 
   */
  async DNSRecordSave(id, data) {
    const rs = await req.postJson({
      url: `${URLS.DOMAIN_DNS_RECORD_SAVE}/${id}`,
      data
    });
    return rs.data;
  },
  /**
   * 解析记录列表
   * @param {*} id 
   * @param {*} data 
   * @returns 
   */
  async DNSRecordList(id, data) {
    const rs = await req.postJson({
      url: `${URLS.DOMAIN_DNS_RECORD_LIST}/${id}`,
      data
    });
    return rs.data;
  },
  /**
   * 删除解析记录
   * @param {*} id 
   * @param {*} data 
   * @returns 
   */
  async DNSRecordDelete(id, data) {
    const rs = await req.postJson({
      url: `${URLS.DOMAIN_DNS_RECORD_DELETE}/${id}`,
      data
    });
    return rs.data;
  },
  /**
   * 获取域名证书
   * @param {*} id 
   * @returns 
   */
  async cert(id) {
    const rs = await req.get({
      url: `${URLS.DOMAIN_CERT}/${id}`,
    });
    return rs.data;
  },
  /**
   * 查询单个域名续费价格
   * @param {*} id 
   * @returns 
   */
  async renewPrice(id, data) {
    const rs = await req.postJson({
      url: `${URLS.DOMAIN_RENEW_PRICE}/${id}`,
      data
    });
    return rs.data;
  },
  /**
   * 添加备注
   * @param {*} id 
   * @param {*} data 
   * @returns 
   */
  async comment(id, data) {
    const rs = await req.postJson({
      url: `${URLS.DOMAIN_COMMENT}/${id}`,
      data
    });
    return rs.data;
  },
  /**
   * 使用联系人模板过户
   * @param {*} id 
   * @param {*} data 
   * @returns 
   */
  async modifyContact(id, data) {
    const rs = await req.postJson({
      url: `${URLS.DOMAIN_MODIFY_CONTACT}/${id}`,
      data
    });
    return rs.data;
  },
  /**
   * DNSSEC添加
   * @param {*} id 
   * @param {*} data 
   * @returns 
   */
  async dnssecAdd(id, data) {
    const rs = await req.postJson({
      url: `${URLS.DOMAIN_DNSSEC_ADD}/${id}`,
      data
    });
    return rs.data;
  },
  /**
   * 删除DNSSEC
   * @param {*} id 
   * @returns 
   */
  async dnssecDel(id) {
    const rs = await req.get({
      url: `${URLS.DOMAIN_DNSSEC_DEL}/${id}`,
    });
    return rs.data;
  },
  /**
   * 同步DNSSEC
   * @param {*} id 
   * @returns 
   */
  async dnssecSync(id) {
    const rs = await req.get({
      url: `${URLS.DOMAIN_DNSSEC_SYNC}/${id}`,
    });
    return rs.data;
  },
}