
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("editor", {
      submiting: (state) => state.submiting,
    },),
  },
  methods: {
    handleSave(saveAsDraft) {
      this.$emit("save", saveAsDraft || false);
    },
  },
};
