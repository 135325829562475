
import orderApi from "~/utils/api/order";

export default {
  props: {
    plan: {
      type: Object,
      defualt: () => null
    }
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    async handleClickBuy() {
      // 未登录时自动跳转到登录页面
      await this.$store.dispatch('users/redirect');

      // 是否购买
      const rs = await this.$toast.confirm({
        title: "提示",
        message: "是否确认购买该套餐，一个套餐只能购买一次但可叠加其他套餐使用，建议您选择适合您的套餐",
        confirmButtonText: "立即购买"
      });
      if (!rs) {
        return;
      }


      // 遍历domains，生成orderitem在提交创建订单
      const {plan} = this;
      const items = [
        {
          productType: "wbsSSL",
          name: "网站监控 WBS_SSL 证书监控服务购买",
          action: "create",
          processData: {
            planID: plan.id
          }
        }
      ];

      this.loading = true;

      try {
        const rs = await orderApi.create({
          "orderType": 2,
          "fee": 0,// orderType时，fee会被后端重新计算的
          items
        });
        if (rs) {
          const order = rs.data;
          // 下单后清空购物车
          await this.$store.dispatch('carts/clearAll');
          this.$toast.show({
            message: "订单创建成功，2秒后将带您继续支付订单",
            type: "success"
          });

          setTimeout(() => {
            window.location.href = `/console/order/detail/${order.id}`;
          }, 2000);
        }
      } catch (err) {
        console.error(err);
      }

      this.loading = false;
    }
  }
}
