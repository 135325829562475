import { render, staticRenderFns } from "./PrivacyCheck.vue?vue&type=template&id=551303cb&scoped=true&lang=pug"
import script from "./PrivacyCheck.vue?vue&type=script&lang=js"
export * from "./PrivacyCheck.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "551303cb",
  null
  
)

export default component.exports