import { render, staticRenderFns } from "./QuickActions.vue?vue&type=template&id=21bb0c08&scoped=true&lang=pug"
import script from "./QuickActions.vue?vue&type=script&lang=js"
export * from "./QuickActions.vue?vue&type=script&lang=js"
import style0 from "./QuickActions.vue?vue&type=style&index=0&id=21bb0c08&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21bb0c08",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonIconfont: require('/Users/weizhang/Projects/mingcui_web/components/common/Iconfont.vue').default})
