import { render, staticRenderFns } from "./_page.vue?vue&type=template&id=52c6d83a&scoped=true&lang=pug"
import script from "./_page.vue?vue&type=script&lang=js"
export * from "./_page.vue?vue&type=script&lang=js"
import style0 from "./_page.vue?vue&type=style&index=0&id=52c6d83a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52c6d83a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ThreadsCarousel: require('/Users/weizhang/Projects/mingcui_web/components/threads/Carousel.vue').default,CommonButton: require('/Users/weizhang/Projects/mingcui_web/components/common/Button.vue').default,CommonEmpty: require('/Users/weizhang/Projects/mingcui_web/components/common/Empty.vue').default,ThreadsListTile: require('/Users/weizhang/Projects/mingcui_web/components/threads/ListTile.vue').default,CommonPagenationLink: require('/Users/weizhang/Projects/mingcui_web/components/common/PagenationLink.vue').default,ThreadsCategoryActions: require('/Users/weizhang/Projects/mingcui_web/components/threads/CategoryActions.vue').default,PageCardTile: require('/Users/weizhang/Projects/mingcui_web/components/page/CardTile.vue').default,ThreadsTagFlatList: require('/Users/weizhang/Projects/mingcui_web/components/threads/tag/FlatList.vue').default,CommonCard: require('/Users/weizhang/Projects/mingcui_web/components/common/Card.vue').default})
