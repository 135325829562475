import { render, staticRenderFns } from "./SearchBanner.vue?vue&type=template&id=658ebdda&scoped=true&lang=pug"
import script from "./SearchBanner.vue?vue&type=script&lang=js"
export * from "./SearchBanner.vue?vue&type=script&lang=js"
import style0 from "./SearchBanner.vue?vue&type=style&index=0&id=658ebdda&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "658ebdda",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DomainTradeTldsSelector: require('/Users/weizhang/Projects/mingcui_web/components/domain/trade/TldsSelector.vue').default,DomainTradeSortTypeSelector: require('/Users/weizhang/Projects/mingcui_web/components/domain/trade/SortTypeSelector.vue').default})
