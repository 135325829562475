
export default {
  data() {
    return {
      menus: [
        {
          group: "关于",
          items: [
            { name: "关于我们", url: "/about" },
            { name: "用户协议", native: true, url: "/legals/docs/policy.html" },
            { name: "隐私协议", native: true, url: "/legals/docs/privacy.html" },
          ],
        },
        // {
        //   group: "产品",
        //   items: [
        //     // { name: "媒体号", url: "/users/medium/landing" },
        //     { name: "域名服务", url: "/product/domain" },
        //     // { name: "网站监控", url: "/product/wbs" },
        //     { name: "工具库", url: "/tools" },
        //     { name: "Whois查询", native: true, url: "/whois" },
        //   ],
        // },
        // {
        //   group: "服务",
        //   items: [
        //     { name: "价格透明", url: "/upcoming" },
        //     { name: "安心经纪", url: "/upcoming" },
        //     { name: "品牌入住", url: "/upcoming" },
        //     { name: "投诉举报", url: "/system/report" },
        //   ],
        // },
        {
          group: "用户支持",
          items: [
            { name: "免费注册", url: "/users/create" },
            { name: "忘记密码", url: "/users/reset" },
            { name: "不良信息举报", native: true, url: "https://www.12377.cn/" },
          ],
        },
        {
          group: "开放",
          items: [
            { name: "投稿", url: "/studio" },
            { name: "友情链接", url: "/system/links" },
          ],
        },
      ],
    };
  },
};
