
export default {
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      seoKeywords: "",
    };
  },
  computed: {
    formatted() {
      const { seoKeywords } = this;
      let words = seoKeywords;
      //- 将中文逗号替换为英文
      words = words.replace("，", ",")
      words = words.replace(" ", ",")

      return words;
    },
  },
  methods: {
    /**
     * 输入
     */
    handleInput() {
      const { formatted } = this;
      this.$emit("input", formatted);
    },
  },
  watch: {
    /**
     * 值发生变化
     */
    value(newVal, oldVal) {
      if (newVal == oldVal) {
        return;
      }

      this.seoKeywords = newVal;
    },
  },
};
