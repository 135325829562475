import usersApi from "~/utils/api/users";

export const state = () => ({
  /// 钱包
  wallet: null,
})


export const mutations = {
  //- 正在加载用户状态
  updateWallet(state, wallet) {
    state.wallet = wallet;
  },
}


export const actions = {
  /**
   * 取用户钱包
   * @param {*} context 
   */
  async getWallet(context){
    try {
      const rs = await usersApi.WalletDetail();
      if(rs){
        context.commit("updateWallet", rs.data || null);
      }
    } catch (err) {
      console.error(err)
    }
  }
}


export const namespaced = true;
