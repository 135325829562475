import Vue from 'vue'
import VueQuillEditor from 'vue-quill-editor'

// https://www.npmjs.com/package/quill-paste-smart
import 'quill-paste-smart';
// 图片模块拓展
// https://github.com/NextBoy/quill-image-extend-module
// import {container, ImageExtend, QuillWatch} from 'quill-image-extend-module'

import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme

Vue.use(VueQuillEditor, /* { default global options } */);