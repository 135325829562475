import req from "~/utils/request";
import { URLS } from "~/utils/request/requestUrls";

export default {
   /**
   * 工单启动
   * @returns 
   */
   async bootstrap() {
    const rs = await req.get({
      url: URLS.TICKET_BOOTSTRAP
    });
    return rs.data;
  },
  //- 创建
  async save(data) {
    const rs = await req.postJson({
      url: URLS.TICKET_SAVE,
      data
    });
    return rs.data;
  },
  //- 删除
  async delete(id) {
    const rs = await req.get({
      url: `${URLS.TICKET_DELETE}/${id}`
    });
    return rs.data;
  },
  //- 详情
  async detail(id) {
    const rs = await req.get({
      url: `${URLS.TICKET_DETAIL}/${id}`
    });
    return rs.data;
  },
  //- 关闭
  async close(id) {
    const rs = await req.get({
      url: `${URLS.TICKET_CLOSE}/${id}`
    });
    return rs.data;
  },
  //- 处理中标记
  async review(id) {
    const rs = await req.get({
      url: `${URLS.TICKET_REVIEW}/${id}`
    });
    return rs.data;
  },
  //- 评分
  async score(id, data) {
    const rs = await req.postJson({
      url: `${URLS.TICKET_SCORE}/${id}`,
      data
    });
    return rs.data;
  },
  //- 列表
  async list(data) {
    const rs = await req.postJson({
      url: URLS.TICKET_LIST,
      data
    });
    return rs.data;
  },
  //- 管理员查询工单列表
  async managerList(data) {
    const rs = await req.postJson({
      url: URLS.TICKET_MANAGER_LIST,
      data
    });
    return rs.data;
  },
  //- 工单回复
  async historySave(id, data) {
    const rs = await req.postJson({
      url: `${URLS.TICKET_HISTORY_SAVE}/${id}`,
      data
    });
    return rs.data;
  },
  //- 工单列表
  async historyList(id, data) {
    const rs = await req.postJson({
      url: `${URLS.TICKET_HISTORY_LIST}/${id}`,
      data
    });
    return rs.data;
  },
  //- 删除工单回复
  async historyDelete(id) {
    const rs = await req.get({
      url: `${URLS.TICKET_HISTORY_DELETE}/${id}`
    });
    return rs.data;
  },
}