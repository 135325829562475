
export default {
  props: {
    /**
     * input拓展样式
     */
    extendedClass: {
      type: String,
      default: ""
    },
    /**
     * 按钮拓展样式
     */
    buttonExtendedClass: {
      type: String,
      default: ""
    },
  },
  data() {
    return {
      keyword: decodeURI(this.$route.params.keyword || ""),
      indexType: this.$route.params.indexType || "threads",
    }
  },
  methods: {
    /**
     * 用户提交搜索
     */
    handleSubmit() {
      const { keyword, indexType } = this;
      if (this.$_.isEmpty(keyword)) {
        return;
      }

      const encode = encodeURI(keyword);
      this.$router.push(`/search/${indexType}/${encode}`);
    }
  }
}
