
import threadsApi from "~/utils/api/threads";
import format from "~/utils/format";
import { saveAs } from 'file-saver';

export default {
  name: "page-threads-detail",
  async asyncData({ params, error }) {
    let thread;

    try {
      const rs = await threadsApi.detailHashedChecked(params.id);
      if (rs) {
        thread = rs.data;
      }
    } catch (err) {
      // 抛异常，
      // 如果错误是record not found, 并将Nuxtjs中的响应状态跟新为404
      error({ status: 503, message: err.message || "未知错误" });
    }

    return {
      thread,
      // 覆盖loading
      loading: false
    }
  },
  data() {
    return {
      id: this.$route.params.id || "",
      loading: true,
      thread: {},
      viewcountTimeoutID: null,
    };
  },
  head() {
    const { thread } = this;

    let meta = [
      // 智能摘要
      // http://www.so.com/help/help_3_9.html
      {
        hid: "keywords",
        name: "keywords",
        content: thread.customKeywords || thread.name,
      },
      {
        hid: "description",
        name: "description",
        content: thread.contentSnapshot || thread.name,
      },
      {
        property: "og:type",
        content: "article",
      },
      {
        property: "og:title",
        content: thread.name || '本站资讯',
      },
      {
        property: "og:release_date",
        content: thread.createdAt,
      },
      {
        property: "og:description",
        content: thread.contentSnapshot || thread.name,
      },
      // 字节时间因子
      {
        property: "bytedance:published_time",
        content: thread.createdAt,
      },
      {
        property: "bytedance:lrDate_time",
        content: thread.updatedAt,
      },
      {
        property: "bytedance:updated_time",
        content: thread.updatedAt,
      },
    ];

    /// 如果有封面图
    if (!this.$_.isEmpty(thread.coverImageUrl) || !this.$_.isEmpty(thread.images)) {
      const coverImageUrl = thread.coverImageUrl || thread.images[0];
      meta.push({
        property: "og:image",
        content: coverImageUrl,
      });
    }

    return {
      title: this.$app.getTitle(`${thread.name || "文章详情页"}`),
      meta,
    };
  },
  mounted() {
    this.$nextTick(async () => {
      this.viewCount();

      // 代码高亮
      if (hljs) {
        hljs.highlightAll();
      }
    });
  },
  /// 清除
  beforeDestroy() {
    clearTimeout(this.viewcountTimeoutID);
  },
  computed: {
    /**
     * 缓存的查看次
     */
    cachedViewCounts() {
      const { thread } = this;
      return thread.cachedViewCounts || 0;
    },
    /**
     * 作者名
     */
    authorName() {
      const { thread } = this;
      return thread.author == null ? "未知" : thread.author.username;
    },
    /**
     * 创建于
     */
    createdAt() {
      const { thread } = this;
      return this.formatDate(thread.createdAt || null);
    },
    // 关联推荐的主题
    recommendThreads() {
      const { thread } = this;
      if (this.$_.isEmpty(thread)) {
        return [];
      }

      return thread.recommendThreads || [];
    },
    /**
     * 内容区元素ID
     */
    contentElementId() {
      const { thread } = this;
      return `content-${thread.id || 0}`;
    },
  },
  methods: {
    /**
     * 点击查看来源
     * @param {*} url 
     */
    async handleOpenOrigin(url) {
      const ok = await this.$toast.confirm({
        title: '提示',
        message: `是否跳转到第三方网站查查阅原文`,
      });
      if (!ok) {
        return;
      }

      const win = window.open("_blank");
      win.location.href = url;
    },
    /**
     * 点击查看评论
     */
    handleViewComments() {
      $('html, body').animate({ scrollTop: $('#posts-first-page-list').offset().top }, 1000)
    },
    /**
     * 生成分享海报
     */
    handleSharePoster() {
      const { thread } = this;

      if (this.$_.isEmpty(thread)) {
        return;
      }

      //- 显示对话框
      $('#threadPosterModal').modal('show');
    },
    /**
     * 下载海报
     */
    downloadThreadPoster() {
      const { thread } = this;

      if (this.$_.isEmpty(thread)) {
        return;
      }

      //- 获取ID
      const domtoimage = require('dom-to-image');
      const node = document.getElementById('thread-poster');

      // 下载图片
      domtoimage.toBlob(node)
        .then(function (blob) {
          saveAs(blob, `t${thread.id}_share.png`);
        })
        .catch(function (error) {
          console.error('oops, something went wrong!', error);
        });
    },
    /**
     * 统计
     */
    viewCount() {
      const { thread, viewcountTimeoutID } = this;

      // 已经有在等待执行的
      if (viewcountTimeoutID != null || this.$_.isEmpty(thread)) {
        return;
      }

      try {
        /// 阅读至少*秒才统计
        this.viewcountTimeoutID = setTimeout(async () => {
          await threadsApi.viewCount(thread.id);
          this.viewcountTimeoutID = null;
        }, 1000 * 5);
        console.log("计时完毕", this.viewcountTimeoutID)
      } catch (err) {
        console.error(err);
      }
    },
    /**
     * 格式化时间
     */
    formatDate(t) {
      return format.formatDate(t, "YYYY-MM-DD");
    },
    /**
     * 预览图片
     * @param {*} url 
     */
    previewImg(url) {
      this.$hevueImgPreview(url)
    },
    /// 内容点击
    handleContentClick(e) {
      const srcElement = e.srcElement;
      if (srcElement.nodeName == "IMG") {
        this.previewImg(srcElement.src);
        return;
      }

    },
    /**
     * 点击举报按钮
     */
    async handleClickReportFlagButton() {
      const { thread } = this;

      if (this.$_.isEmpty(thread)) {
        return;
      }

      const ok = await this.$toast.confirm({
        title: '提示',
        message: `您将投诉这边文章，确定继续吗？`,
      });
      if (!ok) {
        return;
      }

      this.$router.push({
        path: `/system/report`, query: {
          resourceUrl: encodeURI(window.location)
        }
      });
    }
  },
};
