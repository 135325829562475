
export default {
  props: {
    // 没有内容时的提示
    content: {
      type: String,
      default: "暂无更多数据"
    },
    // 是否使用card渲染
    card: {
      type: Boolean,
      default: true,
    }
  }
}
