
export default {
  props: {
    /**
     * 主题信息，不包含内容
     */
    thread: {
      type: Object,
      default() {
        return null
      }
    }
  },
  methods: {
    /**
     * 点击举报按钮
     */
    handleClickReportFlagButton() {
      const { thread } = this;

      if (this.$_.isEmpty(thread)) {
        return;
      }

      this.$router.push({
        path: `/system/report`, query: {
          resourceUrl: encodeURI(window.location)
        }
      });
    }
  }
}
