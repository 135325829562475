import { render, staticRenderFns } from "./_id.html.vue?vue&type=template&id=56c097fb&lang=pug"
import script from "./_id.html.vue?vue&type=script&lang=js"
export * from "./_id.html.vue?vue&type=script&lang=js"
import style0 from "./_id.html.vue?vue&type=style&index=0&id=56c097fb&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ThreadsPoster: require('/Users/weizhang/Projects/mingcui_web/components/threads/Poster.vue').default,CommonIconfont: require('/Users/weizhang/Projects/mingcui_web/components/common/Iconfont.vue').default,ThreadsPremiumTag: require('/Users/weizhang/Projects/mingcui_web/components/threads/PremiumTag.vue').default,ThreadsQuickActions: require('/Users/weizhang/Projects/mingcui_web/components/threads/QuickActions.vue').default,ThreadsContentDetailActions: require('/Users/weizhang/Projects/mingcui_web/components/threads/ContentDetailActions.vue').default,ThreadsDetailNextAndPrev: require('/Users/weizhang/Projects/mingcui_web/components/threads/detail/NextAndPrev.vue').default,PostsEditor: require('/Users/weizhang/Projects/mingcui_web/components/posts/Editor.vue').default,PostsFirstPageList: require('/Users/weizhang/Projects/mingcui_web/components/posts/FirstPageList.vue').default,ThreadsAuthor: require('/Users/weizhang/Projects/mingcui_web/components/threads/Author.vue').default,CommonCard: require('/Users/weizhang/Projects/mingcui_web/components/common/Card.vue').default,ThreadsRecommends: require('/Users/weizhang/Projects/mingcui_web/components/threads/Recommends.vue').default})
