import { mapState } from "vuex";
import Fingerprint2 from 'fingerprintjs2';
import usersApi from "~/utils/api/users";

export default {
  computed: {
    ...mapState("users", {
      user: (state) => state.user,
    }),
  },
  methods: {
    /**
     * 绑定设备
     * 首先仅在登录时调用其次
     * 注意该方法一定要在确保用户已经登录后在调用
     * 否则将静默放弃绑定
     */
    bindUserFingerPrintHash() {
      Fingerprint2.get(async (components) => {
        const { user } = this;
        const values = components.map(function (component, index) {
          if (index === 0) { //把微信浏览器里UA的wifi或4G等网络替换成空,不然切换网络会ID不一样
            return component.value.replace(/\bNetType\/\w+\b/, '')
          }
          return component.value
        })

        //生成的标识码
        const dhash = Fingerprint2.x64hash128(values.join(''), 31);

        // 如果标识码生成成功，且用户登录
        if (this.$_.isEmpty(dhash) || this.$_.isEmpty(user)) {
          console.info("user dhash sync stopped ");
          return;
        }

        // 将用户的设备hash和账号关联，忽略错误
        // 这里的目标主要是，当账号登录时
        // console.log(dhash);
        await usersApi.deviceBind({
          dhash
        });

      })
    }
  },
}