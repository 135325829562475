import { render, staticRenderFns } from "./CoverImageEditor.vue?vue&type=template&id=4dc08669&scoped=true&lang=pug"
import script from "./CoverImageEditor.vue?vue&type=script&lang=js"
export * from "./CoverImageEditor.vue?vue&type=script&lang=js"
import style0 from "./CoverImageEditor.vue?vue&type=style&index=0&id=4dc08669&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4dc08669",
  null
  
)

export default component.exports