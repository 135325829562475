import Message from "element-ui/lib/message";
import Notification from "element-ui/lib/notification";
import MessageBox from "element-ui/lib/message-box";
import Loading from "element-ui/lib/loading";

export default {
  /**
   * 提示信息
   * @param {*} opts 
   * opts 如果是字符串则默认使用信息弹出提示，否则根据传入对象提示
   * type: warning, info, error
   * 参考 https://element.eleme.io/#/zh-CN/component/message
   */
  show(opts) {
    /// 传入字符串
    if (typeof opts === "string") {
      Message({
        message: opts
      });
      return;
    }
    let options = { ...opts };

    /// 弹出周期
    /// 默认一秒
    if (typeof options.duration == "undefined") {
      options.duration = 2000;
    }

    Message(options);
  },
  /**
   * 通知
   * 参考 https://element.eleme.io/#/zh-CN/component/notification
   */
  notify(opts) {
    if (typeof opts === "string") {
      Notification({
        title: '提醒',
        message: opts,
        duration: 3000,
        type: 'warning'
      });
      return;
    }

    let options = { ...opts };

    // 覆盖默认选项
    if (typeof options.showClose == "undefined") {
      options.showClose = false;
    }

    /// 弹出周期
    /// 默认一秒
    if (typeof options.duration == "undefined") {
      options.duration = 3000;
    }



    Notification(options);
  },
  /**
   * 
   * @param {*} opts 
   * opts: {title, message}
   * 用法https://element.eleme.io/#/zh-CN/component/message-box
   */
  alert(opts) {
    if (typeof opts === "string") {
      MessageBox.alert(
        opts,
        '提示',
      );
      return;
    }

    MessageBox.alert(
      opts.message || '未定义提示内容',
      opts.title || '提示',
      {
        closeOnClickModal: typeof opts.distinguishCancelAndClose === "undefined" ? false : opts.distinguishCancelAndClose,
      }
    );
  },
  /**
   * 
   * opts: {title, message, confirmButtonText, cancelButtonText, type}
   * 用法https://element.eleme.io/#/zh-CN/component/message-box
   */
  async confirm(opts) {
    try {
      const rs = await MessageBox.confirm(
        opts.message || '未定义提示内容',
        opts.title || '提示',
        {
          confirmButtonText: opts.confirmButtonText || "确定",
          cancelButtonText: opts.cancelButtonText || "取消",
          closeOnClickModal: typeof opts.distinguishCancelAndClose === "undefined" ? false : opts.distinguishCancelAndClose,
          type: opts.type || '',
          roundButton: false
        }
      );
      return rs == "confirm" ? true : false;
    } catch (error) {
      console.warn("user click cancel button");
    }

    return false;
  },
  /**
   * 
   * @returns loadinginstance
   */
  loading() {
    return Loading.service();
  }
}