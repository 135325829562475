
import data from "~/components/city/data.json";

export default {
  props: {
    isLarge: {
      type: Boolean,
      default: false
    },
    /**
     * 默认选中的地址
     */
    addr: {
      type: Object,
      default() {
        return null
      }
    }
  },
  data() {
    return {
      // 原始数据
      cityObjects: { ...data },
      state: null,
      city: null,
      county: null
    }
  },
  mounted() {
    this.$nextTick(async () => {
      this.autoCompl()
    });
  },
  methods: {
    /**
     * 保存地址
     * addr 传入的是文本字符串，所以要去遍历
     * todo
     */
    async autoCompl() {
      const { addr } = this;

    },
    /**
     * 变化时传递事件
     */
    emitChanged() {
      const { state, city, county } = this;

      let arr = [];
      arr.push(this.$_.isEmpty(state) ? '' : state.n);
      arr.push(this.$_.isEmpty(city) ? '' : city.n);
      arr.push(this.$_.isEmpty(county) ? '' : county.n);

      this.$emit('changed', arr);
    }
  },
  watch: {
    /**
     * 重选时清空
     */
    state() {
      this.city = null;
      this.county = null;
    },
    /**
    * 重选时清空
    */
    city() {
      this.county = null;
    }
  }
}
