
import format  from "~/utils/format";

export default {
  props: {
    post: {
      type: Object,
      default() {
        return null
      }
    }
  },
  methods: {
    /**
     * datetime Diff
     * @param {*} d 
     */
    dateDiff(d){
      return format.dateDiff(d)
    }
  }
}
