import Vue from 'vue';
import { Loading, Switch, Checkbox, Popover, Tooltip, Dialog, Pagination, Radio, RadioGroup, Input, Select, Option, Tag, InputNumber  } from 'element-ui';

// 按需引入样式
import '~/styles/element/loading.css';
import '~/styles/element/switch.css';
import '~/styles/element/input.css';
import '~/styles/element/checkbox.css';
import '~/styles/element/popover.css';
import '~/styles/element/tooltip.css';
import '~/styles/element/pagination.css';
import '~/styles/element/radio.css';
import '~/styles/element/select.css';
import '~/styles/element/radio-group.css';
import '~/styles/element/input-number.css';

// utils/toast
import '~/styles/element/message.css';
import '~/styles/element/message-box.css';
import '~/styles/element/notification.css';
import '~/styles/element/loading.css';
import '~/styles/element/dialog.css';

Vue.use(Loading);
Vue.use(Switch);
Vue.use(Checkbox);
Vue.use(Popover);
Vue.use(Tooltip);
Vue.use(Dialog);
Vue.use(Pagination);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Input);
Vue.use(Select);
Vue.use(Tag);
Vue.use(Option);
Vue.use(InputNumber);
