import req from "~/utils/request";
import { URLS } from "~/utils/request/requestUrls";

export default {
  /**
   * 创建TLD
   * @param {*} data 
   * @returns 
   */
  async save(data) {
    const rs = await req.postJson({
      url: URLS.TLD_SAVE,
      data
    });
    return rs.data;
  },
  /**
   * 删除
   * @returns 
   */
  async delete(id) {
    const rs = await req.get({
      url: `${URLS.TLD_DELETE}/${id}`
    });
    return rs.data;
  },
  /**
   * 详情
   * @param {*} id 
   * @returns 
   */
  async detail(id) {
    const rs = await req.get({
      url: `${URLS.TLD_DETAIL}/${id}`
    });
    return rs.data;
  },
  /**
   * 业务启动接口
   * @returns 
   */
  async bootstrap() {
    const rs = await req.get({
      url: URLS.TLD_BOOTSTRAP
    });
    return rs.data;
  },
  /**
   * 保存注册商
   * @param {*} data 
   * @returns 
   */
  async registrarSave(data) {
    const rs = await req.postJson({
      url: URLS.TLD_REGISTRAR_SAVE,
      data
    });
    return rs.data;
  },
  /**
   * 删除注册商
   * @param {*} id 
   * @returns 
   */
  async registrarDelete(id) {
    const rs = await req.get({
      url: `${URLS.TLD_REGISTRAR_DELETE}/${id}`
    });
    return rs.data;
  },
  /**
   * 保存注册商折扣信息
   * @param {*} data 
   * @returns 
   */
  async registrarDiscountSave(data) {
    const rs = await req.postJson({
      url: URLS.TLD_REGISTRAR_DISCOUNT_SAVE,
      data
    });
    return rs.data;
  },
  /**
   * 删除注册商折扣
   * @param {*} id 
   * @returns 
   */
  async registrarDiscountDelete(id) {
    const rs = await req.get({
      url: `${URLS.TLD_REGISTRAR_DISCOUNT_DELETE}/${id}`
    });
    return rs.data;
  },
  /**
   * tldLiteList
   * @param {*} data 
   * @returns 
   */
  async tldLiteList(data) {
    const rs = await req.postJson({
      url: URLS.TLD_LITE_LIST,
      data
    });
    return rs.data;
  },
  /**
   * 全量列表查询[仅管理员可用]
   * @param {*} data 
   * @returns 
   */
  async list(data) {
    const rs = await req.postJson({
      url: URLS.TLD_LIST,
      data
    });
    return rs.data;
  },
  /**
   * TLD上架
   * @param {*} id 
   * @param {*} data 
   * @returns 
   */
  async enable(id, data) {
    const rs = await req.postJson({
      url: `${URLS.TLD_ENABLE}/${id}`,
      data
    });
    return rs.data;
  },
  /**
   * 注册商价格同步
   * @param {*} id 
   * @param {*} data 
   * @returns 
   */
  async priceSync(data) {
    const rs = await req.postJson({
      url: URLS.TLD_PRICE_SYNC,
      data
    });
    return rs.data;
  },
  /**
   * 设置为推荐
   * @param {*} id 
   * @param {*} data 
   * @returns 
   */
  async recommend(id, data) {
    const rs = await req.postJson({
      url: `${URLS.TLD_RECOMMEND}/${id}`,
      data
    });
    return rs.data;
  },
}