

export default {
  data() {
    return {
      // 无需审核直接发布
      // 是否默认自动选中，该设置仅管理员权限生效
      isChecked: false,
      submitToSearchEngine: false
    };
  },
  methods: {
    /**
     * 直接通过审核变化
     * @param {*} val 
     */
    handleIsCheckedInput(val) {
      // 关联重置submitToSearchEngine
      if(!val){
        this.submitToSearchEngine = false;
        this.$emit('submit-to-search-engine-change', false);
      }

      this.$emit('is-checked-change', val);
    },
    /**
     * 直接通过审核变化
     * @param {*} val 
     */
     handleSubmitToSearchEngineInput(val) {
      const {isChecked} = this;

      // 未设置直接通过审核，实际上是没用的
      if(!isChecked){
        this.submitToSearchEngine = false;
        this.$toast.show("该选项仅在设置直接通过审核时才会生效");
        this.$emit('submit-to-search-engine-change', false);
        return;
      }
      
      this.$emit('submit-to-search-engine-change', val);
    }
  }
};
