
import fsApi from "~/utils/api/fs";
import coxMixins from "~/mixins/cos";
import { mapState } from "vuex";
import { Loading } from 'element-ui';
import contentApi from "~/utils/api/content";

export default {
  mixins: [coxMixins],
  props: {
    value: String,
    minHeight: {
      type: String,
      default: "100vh"
    },
    // 完整的toolbar模式
    fullMode: {
      type: Boolean,
      default: true
    }
  },
  data() {
    const { fullMode } = this;

    return {
      valueBinding: "",
      loading: false,
      chatCompletionContent: "",
      chatCompletionResult: "",
      editorOption: {
        theme: "snow",
        modules: {
          toolbar: {
            container: [
              // 完整模式
              !fullMode ? ["bold"] : [
                "bold",
                "italic",
                "underline",
                "strike",
              ], // toggled buttons
              // [
              //   // "blockquote", 
              //   "code-block"
              // ],

              [{ header: 1 }, { header: 2 }], // custom button values

              // 完整模式才显示全部
              !fullMode ? [
                { list: "bullet" }
              ] : [
                { list: "ordered" },
                { list: "bullet" }
              ],
              // [{ script: "sub" }, { script: "super" }], // superscript/subscript
              // [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
              // [{ direction: "rtl" }], // text direction

              // 添加 image 显示图片上传
              ["clean", "link", "image", "code-block"], // remove formatting button

              // !fullMode ? [] : [{ size: ["small", false, "large", "huge"] }], // custom dropdown
              !fullMode ? [] : [{ header: [1, 2, 3, false] }],

              !fullMode ? [] : [{ color: [] }, { background: [] }], // dropdown with defaults from theme
              // [{ font: [] }],
              // [{ align: [] }],
            ],
            handlers: {
              /// 图片上传回调
              'image': this.imageHandler
            },
          },
          clipboard: {
            allowed: {
              tags: ['a', 'b', 'strong', 'u', 's', 'h1', 'h2', 'h3', 'i', 'p', 'br', 'code', 'ul', 'ol', 'li', 'span', 'img', 'pre', 'div'],
              attributes: ['href', 'rel', 'target', 'alt', 'src', 'id', "class", "align", "color"]
            },
            keepSelection: false,
            substituteBlockElements: true,
            magicPasteLinks: true,
            // matchers: [

            //     /// 将markdown转换为html
            //     [Node.TEXT_NODE, function (node, delta) {
            //       const md = markdownit({ html: true });
            //       const html = md.render(node.data);
            //       console.log(html);

            //       return delta.compose(new Delta().insert(html));
            //     },
            //   ]
            // ],
            hooks: {
              // 粘贴HTML
              async beforeSanitizeElements(currentNode, _, __) {
                console.log(currentNode);

                const nodeName = currentNode.nodeName.toUpperCase();
                if (nodeName === 'IMG') {
                  // 原图片URL
                  const from = currentNode.src;
                  currentNode.src = "/images/badimg.png";

                  // alt 很重要，将作为异步更新src的依据
                  const randNum = Math.round(Math.random() * 10000);
                  const alt = `img-${Date.now()}-${randNum}`;
                  currentNode.alt = alt;

                  /// 提示
                  const loading = Loading.service({
                    text: "自动同步图片到本站，请稍等同步并加载完成后在提交..."
                  });

                  try {
                    //- 异步上传图片到服务器后改元素src
                    const rs = await fsApi.downloadImage({
                      from
                    });
                    if (rs) {
                      let img = document.querySelector(`img[alt="${alt}"]`)
                      if (img) {
                        img.src = rs.data.cdnUrl;
                      }
                    }
                  } catch (error) {
                  }

                  // 延迟关闭
                  setTimeout(() => {
                    loading.close();
                  }, 1000);
                }

                return currentNode;
              }
            },
          },
        },
      },
    };
  },
  computed: {
    ...mapState("bootstrap", {
      qcloud: (state) => state.qcloud,
    }),
    ...mapState("users", { isAdminUser: (state) => state.isAdminUser }),
    /// 图片CDN域名
    qcloudCOSCdnFileDomain() {
      const { qcloud } = this;
      return qcloud.qcloudCOSCdnFileDomain || '';
    }
  },
  mounted() {
    this.$nextTick(async () => {
    });
  },
  methods: {
    /**
     * 处理AI写文章，并插入
     */
    async handleChatCompletion() {
      const { chatCompletionContent, valueBinding } = this;

      if (this.$_.isEmpty(chatCompletionContent)) {
        this.$toast.show("请先输入用于AI书写的预料");
        return;
      }

      this.loading = true;

      try {
        const rs = await contentApi.chatCompletion({ content: chatCompletionContent });
        if (rs) {
          this.chatCompletionResult = rs.data;
          this.valueBinding = `${valueBinding}\r\n<h3>${chatCompletionContent}</h3>\r\n${rs.data.result}`;
          this.chatCompletionContent = "";
        }
      } catch (err) {
        console.error(err);
      }

      this.loading = false;

    },
    /**
     * 用户进行伪原创
     */
    async handlePseudo() {
      const { valueBinding } = this;

      if (this.$_.isEmpty(valueBinding)) {
        this.$toast.show("无可用于伪原创的内容");
        return;
      }

      this.loading = true;

      try {
        const rs = await contentApi.pseudo({ content: valueBinding });
        if (rs) {
          this.valueBinding = rs.data.output || valueBinding;
          this.$toast.notify({
            title: "操作成功",
            message: `本次操作涉及内容变动共计：${rs.data.replacedTimes || 0}处`,
            type: "success",
          });
        }
      } catch (err) {
        console.error(err);
      }

      this.loading = false;
    },
    onEditorChange($event) {
      this.$emit("input", $event.html || "");
    },
    /**
     * 托管图片上传
     * @param {*} e 
     */
    imageHandler(e) {
      const imgSelector = this.imageInputEl();
      if (!imgSelector) {
        return;
      }

      imgSelector.click();
    },
    /**
     * 图片选择
     */
    imageInputEl() {
      const imgSelector = document.getElementById("select-img");
      if (!imgSelector) {
        this.$toast.show('编辑器错误，无法找到用于上传文件的INPUT元素');
        return;
      }

      return imgSelector;
    },
    /**
     * 上传文件发生变化
     * @param {*} e 
     */
    async handleFileChanged(e) {

      const imgSelector = this.imageInputEl();
      if (!imgSelector) {
        return;
      }

      const file = imgSelector.files[0] || null;
      if (!file) {
        return;
      }

      console.log("开始上传文件：", file);
      // 上传文件
      this.putObject({
        file: file,
        done: async (ok, url) => {
          if (!ok) {
            this.$toast.show("上传失败，请重新试试");
            return;
          }

          const { qcloudCOSCdnFileDomain } = this;
          const cdnUrl = `${qcloudCOSCdnFileDomain}${url}`;
          console.log("文件上传成功", cdnUrl);

          const quill = this.editor();
          let selection = quill.getSelection(true);
          quill.insertEmbed(selection.index, "image", cdnUrl);
          imgSelector.value = null;
        },
      });
    },
    // 取编辑器实例
    editor() {
      return this.$refs.myQuillEditor.quill;
    },

  },
  watch: {
    value(val) {
      this.valueBinding = val;
    },
  },
};
