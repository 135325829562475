import usersApi from "~/utils/api/users";
import AuthHelper from "~/utils/request/auth";

const helper = new AuthHelper();

export const state = () => ({
  /// 登录用户
  user: null,
  /// 是否有管理权限
  isAdminUser: false,
  /// 是否正在加载用户信息
  loadingUserInfo: true,
})

export const mutations = {
  //- update bootstrap information
  updateUser(state, user) {
    state.user = user;

    // 关联更新
    if (user == null) {
      // 退出时要将isAdminUser设置为false
      state.isAdminUser = false;
      return;
    }

    // 如果有管理权限则更新isAdminUser
    const userGroup = user.userGroup;
    const isAdminUser = userGroup.group.isAdminUser || false;
    if (isAdminUser) {
      state.isAdminUser = true;
    }
  },
  //- 正在加载用户状态
  updateLoadingUserInfo(state, loading) {
    state.loadingUserInfo = loading;
  },
}

export const actions = {
  //- 使用用户名密码登录
  //- 如果登录失败，则返回false
  async passwordLogin(context, data) {
    const rs = await usersApi.passworLogin(data);
    if (!rs) {
      return;
    }

    // 保存票据
    helper.saveToken(rs.data);
    return await context.dispatch("restoreLoginState");
  },
  //- 使用短信验证码登录
  async smsLogin(context, data) {
    const rs = await usersApi.smsLogin(data);
    if (!rs) {
      return;
    }

    // 保存票据
    helper.saveToken(rs.data);
    return await context.dispatch("restoreLoginState");
  },
  //- 恢复登录状态
  async restoreLoginState(context) {
    const token = helper.getLocalStoredToken();

    //- loadingUserInfo默认为true，取值失败时同样要置为false才能在前端正常响应用户加载状态
    if (token == null) {
      context.commit("updateLoadingUserInfo", false);
      return;
    }

    //-设置正在恢复用户状态
    context.commit("updateLoadingUserInfo", true);

    try {
      const rs = await usersApi.info();
      if (rs) {
        context.commit("updateUser", rs.data);
        context.commit("updateLoadingUserInfo", false);
        return true;
      }
    } catch (err) {
      console.error(err);
    }

    context.commit("updateLoadingUserInfo", false);

    return;

  },
  //- 退出
  logout(context) {
    helper.clear();
    context.commit("updateLoadingUserInfo", false);
    context.commit("updateUser", null);
    context.dispatch("redirect");
  },
  /// 跳转到登录页面
  // 仅导航到登录页面，不关联登录结果
  async redirect(context, options) {

    // 先不要直接restoreLoginState
    const state = context.state;
    if (state && state.user != null) {
      return false;
    }

    /// 已经登录，则不需要跳转
    const hasLogin = await context.dispatch("restoreLoginState");
    if (hasLogin) {
      return false;
    }

    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    if (url.pathname.includes("/users/login")) {
      return false;
    }

    /// 不自动添加callback url
    if (options && options.preventCallbackRedirectLink) {
      if (this.app && this.app.router) {
        this.app.router.push("/users/login");
      }

      return true;
    }

    const move = `/users/login?callback=${encodeURI(currentUrl)}`;
    if (this.app && this.app.router) {
      this.app.router.push(move);
    }
    return true;
  }
}

export const namespaced = true;
