import systemApi from "~/utils/api/system";

export const state = () => ({
  // 字典
  dictionary: {
    userCounts: {
      label: "正常用户总量",
      to: "/users/list",
    },
    threadCounts: {
      label: "文章总量",
      to: "/threads/list"
    },
    questionCounts: {
      label: "问题总量",
      to: "/questions/list",
    },
    reportCounts: {
      label: "待处理投诉",
      to: "/reports/list",
    },
    checkedThreadCounts: {
      label: "过审的文章数",
      to: "/threads/list",
    },
    uncheckedThreadCounts: {
      label: "未过审的文章数",
      to: "/threads/review",
    },
    refusedThreadCounts: {
      label: "被拒绝的文章数",
      to: "/threads/review",
    },
    brandCounts: {
      label: "品牌总数",
      to: "/brands/list",
    },
    userMediumReviewing: {
      label: "媒体号待审总数",
      to: "/users/medium/list",
    },
    baiduZhanzhangTodayRemains: {
      label: "百度API提交当日配余",
      to: "/search-engine/posturls",
    },
    CPUPercent: {
      label: "服务器CPU使用率",
      to: "/",
    },
    memoPercent: {
      label: "服务器内存使用率",
      to: "/",
    },
    availableTldCounts: {
      label: "已经上架的TLD",
      to: "/tld/list",
    },
  },
  /// 数据总览
  overviews: null,
  loadingOverviews: false,
})


export const mutations = {
  //- 更新数据总览
  updateLoadingOverviews(state, loading) {
    state.loadingOverviews = loading || false;
  },
  //- 更新数据总览
  updateOverviews(state, overviews) {
    state.overviews = overviews;
  },
}


export const actions = {
  /**
   * 取得总览数据
   * @param {*} context 
   * silent: 不会设置loading
   */
  async getOverviews(context, silent) {
    if(!silent){
      context.commit("updateLoadingOverviews", true);
    }
   

    try {
      const rs = await systemApi.overview();
      if (rs) {
        context.commit("updateOverviews", rs.data || null);
      }
    } catch (err) {
      console.error(err)
    }

    context.commit("updateLoadingOverviews", false);
  }
}

export const namespaced = true;
