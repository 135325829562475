
import userApi from "~/utils/api/users";

export default {
  data() {
    return {
      formData: {
        mobile: "",
        isDefault: false,
        name: "",
        state: "",
        city: "",
        county: "",
        street: "",
        comment: ""
      },
      addr: [],
      loading: false,
    }
  },
  mounted() {
    this.$nextTick(async () => {
    });
  },
  methods: {
    /**
     * 改变省市区
     * @param {*} val 
     */
    handleCityChanged(val) {
      this.addr = val;
      if (typeof val != "object") {
        return;
      }

      if (val.length < 3) {
        return;
      }

      this.formData.state = val[0];
      this.formData.city = val[1];
      this.formData.county = val[2];
    },
    /**
     * 保存地址
     */
    async handleSaveAddr() { 
      const {formData} =this;

      this.loading = true;
      try {
        const rs = await userApi.addressSave({
          ...formData
        });
        if(rs){
          this.$router.push("/users/settings/address");
          this.$toast.notify({
            title: "成功",
            message: "已经为您保存收货地址",
            duration: 3000,
            type: 'success'
          });
        }
      } catch (err) {
        console.error(err);
      }

      this.loading = false;
    }
  }
}
