
export default {
  name: "page-product-domain",
  head() {
    return {
      title: this.$app.getTitle(`域名注册服务`),
    }
  },
  data() {
    return {
      product: {
        name: "域名服务",
        englishName: "Domain Registry",
        description: "由西部数码或腾讯云为您提供可信赖的域名注册服务，包含多种GTLD/NewGTLD。您可以使用更低的价格获得安全易用的注册服务支持，无论是新注册续费我们都会及时推出促销活动。",
        // 头部组件
        header: {
          background: "#FFFFFF", // 默认为黑色
          // navbar: {
          //   backgroundColor: '#000000',
          //   mode: "dark",
          // },
          image: "/images/svg/product/domain_names.svg",
          actions: [
            { name: "马上注册", class: "btn-primary", click: this.handleRegisterButtonClicked },
            { name: "立即转入", class: "btn-outline-primary", click: this.handleTransferButtonClicked },
            { name: "查看价格和促销", class: "btn-outline-secondary", to: "/product/domain/price" },
          ],
          shortDesc: {
            text: "节省更多注册和续费成本 30%↓",
          }
        },

        // 产品特性
        features: {
          title: "更低的价格，更多的后缀",
          sub: "既然能直接买下一个品牌，为什么还要从头打造？",
          items: [
            { feature: "价格更低", desc: "我们给您更低的注册价格，降低您的持有成本", icon: "/images/svg/product/domain/coupon.svg" },
            { feature: "更加安全", desc: "无论是我们自己还是合作提供商都在合规运营", icon: "/images/svg/product/domain/safe.svg" },
            { feature: "更多后缀", desc: "我们提供多种后缀满足于保护您的品牌和域名", icon: "/images/svg/product/domain/world.svg" },
            { feature: "服务更优", desc: "为您全程跟进域名购买需求，告诉我们您的心愿", icon: "/images/svg/product/domain/service.svg" },
            { feature: "均可备案", desc: "我司合作注册商和注册接口均为国内一线提供商", icon: "/images/svg/product/domain/ok.svg" },
            { feature: "自由转移", desc: "您可以放心转入传出域名，您有100%选择的权利", icon: "/images/svg/product/domain/transfer.svg" },
            { feature: "超多活动", desc: "无论是新注续费，我们都会同步所有促销活动价格更低", icon: "/images/svg/product/domain/price_down.svg" },
            { feature: "免费解析", desc: "我们提供免费的解析服务，或者您还可以使用其他服务商", icon: "/images/svg/product/domain/dns.svg" },
          ]
        },

        // 常见问题
        // {question:"", answer:""},
        faq: {
          title: "用户常问",
          sub: "关于域名注册，很多用户会提问",
          items: [
            { question: "为什么我要在名翠软件注册域名？", answer: "名翠软件域名注册服务安全，价格透明。使用无限制可自由转出除此之外还有更多的优惠活动。" },
            { question: "什么是溢价域名？", answer: "部分域名词汇，会被注册局保留，尤其是新后缀。这些溢价域名注册价格高于一般的词汇注册价格，有的续费也使用溢价域名的注册价格续费，有的则仅注册溢价，续费普通价格如.wang域名。如果有疑问请联系客服核实。" },
            { question: "在名翠软件注册的域名可以备案吗？", answer: "如果满足工信部备案(因地方管局规则差异可能影响)要求的后缀，是支持在国内进行备案的。您可以在 http://域名.信息 网站中查询" },
            { question: "名翠软件注册域名需要实名认证吗？", answer: "需要的，在命名审核和实名认证后才能进行解析使用。对于部分特殊后缀可能还需要进行不同级别的认证，注册时您会收到提醒。命名失败导致无法注册的，系统会全额退款。" },

          ]
        }
      }
    }
  },
  methods: {
    // 点击注册按钮
    handleRegisterButtonClicked() {
      this.$router.push("/product/domain/search")
    },
    handleTransferButtonClicked() {
      const w = window.open("_blank");
      w.location.href = "/console/domain/transfer/submit";
    }
  }
}
