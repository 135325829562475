
export default {
  props: {
    user: {
      type: Object,
      default(){
        return null
      }
    }
  }
}
