import { render, staticRenderFns } from "./Author.vue?vue&type=template&id=6809da94&scoped=true&lang=pug"
import script from "./Author.vue?vue&type=script&lang=js"
export * from "./Author.vue?vue&type=script&lang=js"
import style0 from "./Author.vue?vue&type=style&index=0&id=6809da94&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6809da94",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UserAvatar: require('/Users/weizhang/Projects/mingcui_web/components/user/Avatar.vue').default})
