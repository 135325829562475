

const defaultGatewayCode = "ALIPAY"

export default {
  props: {
    value: {
      type: String,
      default: defaultGatewayCode
    }
  },
  data() {
    return {
      selectedCode: this.value || defaultGatewayCode,
      gateways: [
        { gatewayCode: "ALIPAY", name: "支付宝", image: "/images/svg/alipay.svg" },
        // { gatewayCode: "WEIXIN_JSAPI", name: "微信支付", image: "/images/svg/weixin.svg" },
        // WALLET
      ]
    }
  },
  methods: {
    /**
     * 切换了其他的支付网关
     * @param {*} gt 
     */
    handleClick(gt){
      this.selectedCode = gt.gatewayCode;
      this.$emit('input', gt.gatewayCode);
    }
  }
}
