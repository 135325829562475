
import threadApi from "~/utils/api/threads";
import metasMixins from "~/mixins/metas";


export default {
  name: "page-posts-detail",
  mixins: [metasMixins],
  head() {
    const { metas, thread } = this;

    // 关键字等seo信息
    let meta = thread == null ? [] : [
      {
        hid: "keywords",
        name: "keywords",
        content: `${thread.customKeywords || thread.name}的相关评论`,
      },
      {
        hid: "description",
        name: "description",
        content: `${thread.contentSnapshot || thread.name}的文章相关评论`,
      },
    ];

    return {
      title: this.$app.getTitle(`${thread == null ? "文章" : thread.name}的评论第${metas.currentPage}页`),
      meta,
    };
  },
  async fetch() {
    await this.getDetailSimplify();
    await this.threadPostList();
  },
  data() {
    return {
      thread: null,
      posts: [],
      loading: true,
      threadID: this.$route.params.threadID || '0',
    }
  },
  computed: {
    /**
     * 基础路径
     */
    basePath() {
      const { threadID } = this;
      return `/posts/${threadID}`;
    }
  },
  methods: {
    /**
     * 点击查看文章
     */
    handleClick() {
      const { thread } = this;

      if (this.$_.isEmpty(thread)) {
        return;
      }

      this.$router.push(`/threads/content/${thread.hashID}.html`);

    },
    /**
     * 取文章简化详情
     */
    async getDetailSimplify() {
      const { threadID } = this;

      this.loading = true;

      try {
        const rs = await threadApi.detailSimplify(threadID);
        if (rs) {
          this.thread = rs.data || null;
        }
      } catch (err) {
        console.error(err);
      }

      this.loading = false;
    },
    /**
     * 评论列表
     */
    async threadPostList(targetMetas) {
      const { thread, metas } = this;

      const willLoadMetas = targetMetas || metas;
      let data = {
        ...willLoadMetas,
        // 仅加载顶级评论
        isParent: true
      };

      this.loading = true;

      try {
        const rs = await threadApi.threadPostList(thread.id, {
          ...data
        });
        if (rs) {
          const posts = rs.data || [];
          if (!this.$_.isEmpty(posts)) {
            this.posts.push(...posts);
          }
        }
      } catch (error) {

      }

      this.loading = false;
    }
  },
};
