
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("bootstrap", {
      setSite: (state) => state.setSite,
    }),
    /**
     * 是否显示
     */
    show() {
      const { setSite } = this;
      return setSite.isSiteOpen == null ? false : !setSite.isSiteOpen;
    },
    /**
     * 站点关闭原因
     */
    siteCloseReason() {
      const { setSite } = this;
      return setSite.siteCloseReason || "站点维护";
    },
  },
  methods: {
    handleClick() {
      const { siteCloseReason } = this;
      this.$toast.notify(siteCloseReason);
    },
  },
};
