
import contentApi from "~/utils/api/content";

export default {
  props: {
    value: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      // 返回的关键字
      keywords: [],
      // 加载中
      loading: false,
      selectedKeywords: [],
    }
  },
  computed: {
    /**
     * 根据选中的词语生成
     */
    generatedWords() {
      const { selectedKeywords } = this;
      if (this.$_.isEmpty(selectedKeywords)) {
        return "";
      }

      return selectedKeywords.map(it => it.word).join(",");
    }
  },
  methods: {
    /**
     * 复制
     */
    handleCopy() {
      const { generatedWords } = this;

      if (this.$_.isEmpty(generatedWords)) {
        this.$toast.notify("未选择关键字");
        return;
      }

      if (navigator.clipboard) {
        navigator.clipboard.writeText(generatedWords);
        this.$toast.notify({
          title: "复制成功",
          message: `已经为您复制到剪辑版`,
          type: "success",
        });
        return;
      }
    },
    /**
     * 和编辑器完成绑定
     */
    genToStudio() {
      const { generatedWords } = this;
      if (this.$_.isEmpty(generatedWords)) {
        this.$toast.notify("覆盖成功，但未选择关键字");
      }

      this.$emit('gen', generatedWords);
    },
    /**
     * 是否已经选中
     * @param {*} k 
     */
    wordSelected(k) {
      const { selectedKeywords } = this;

      return selectedKeywords.find(it => it.word == k.word) ? true : false;
    },
    /**
     * 选项为toggle
     */
    handleSelect(k) {
      const { selectedKeywords } = this;

      const has = selectedKeywords.find(it => it.word == k.word);
      if (has) {
        this.selectedKeywords = selectedKeywords.filter(it => it.word != k.word);
        return;
      }

      this.selectedKeywords.unshift(k);
    },
    /**
     * 生成关键字列表
     */
    async gen() {
      const { value } = this;
      if (this.$_.isEmpty(value)) {
        this.$toast.notify("请先输入标题");
        return;
      }

      this.loading = true;
      this.keywords = [];
      this.selectedKeywords = [];
      try {
        const rs = await contentApi.keywordIndex({
          title: value
        });
        if (rs) {
          const words = rs.data.keywords || [];
          // 移除重复的word
          let reset = [];
          words.forEach(it => {
            if (!reset.find(fd => fd.word == it.word)) {
              reset.push(it);
            }
          });

          this.keywords = reset;
        }
      } catch (err) {

      }
      this.loading = false;
    }
  }
}
