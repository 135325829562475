
import { mapState } from "vuex";
import coxMixins from "~/mixins/cos";
import quetionApi from "~/utils/api/question";

export default {
  mixins: [coxMixins],
  data() {
    return {
      // 正在上传
      uploading: false,
      // 创建的附件列表
      attachments: [],
    }
  },
  computed: {
    ...mapState("bootstrap", {
      qcloud: (state) => state.qcloud,
    }),
    /// 图片CDN域名
    qcloudCOSCdnFileDomain() {
      const { qcloud } = this;
      return qcloud.qcloudCOSCdnFileDomain || "";
    },
  },
  methods: {
    // 图片选择
    imageInputEl() {
      const imgSelector = document.getElementById("file-picker");
      if (!imgSelector) {
        this.$toast.show("无法找到用于上传文件的INPUT元素");
        return;
      }

      return imgSelector;
    },
    // 托管图片上传事件
    imageHandler() {
      const imgSelector = this.imageInputEl();
      if (!imgSelector) {
        return;
      }

      imgSelector.click();
    },
    // 上传文件发生变化 触发文件上传
    async handleFileChanged() {
      const imgSelector = this.imageInputEl();
      if (!imgSelector) {
        return;
      }

      const file = imgSelector.files[0] || null;
      if (!file) {
        return;
      }
      this.uploading = true;

      console.log("开始上传文件：", file);

      // 上传文件
      this.putObject({
        file: file,
        done: async (ok, url) => {
          this.uploading = false;
          if (!ok) {
            this.$toast.show("上传失败，请重新试试");
            return;
          }

          // 上传后的文件url，使用上传后的文件Url来创建附件
          try {
            const rs = await quetionApi.attachmentCreate({
              filePath: url,
              fileName: file.name || 'unknown',
              fileSize: new Number(file.size),
              fileMime: file.type
            });
            if (rs) {
              this.attachments.push(rs.data);

              // 双向绑定
              const { attachments } = this;
              this.$emit('input', [...attachments])
            }
          } catch (err) {
            console.error(err);
          }

        },
      });

      imgSelector.value = null;
    },
    /**
     * 删除附件
     */
    async handleDelete(attachment){
      if(this.$_.isEmpty(attachment)){
        return;
      }

      try {
        const rs = await quetionApi.attachmentDelete(attachment.id);
        if(rs){
          // 数组中移除
          const { attachments } = this;
          const as = attachments.filter(it => it.id != attachment.id);
          this.attachments = as;
          this.$emit('input', [...as])
        }
      } catch (err) {
        console.error(err);
      }
    }
  }

}
