import dayjs from "dayjs";

export default {
  /**
   * 格式化手机号
   * @param {*} val 
   * @returns 
   */
  formatPhone(phone) {
    phone += '';
    return phone.replace(/(\d{3})\d*(\d{4})/g, '$1***$2')
  },
  /**
   * 价格四舍五入
   * @param {*} price 
   * @returns 
   */
  priceToFixed(price){
    const num = Number(price);
    return num.toFixed(2);
  },
  /**
   * 格式化价格
   * @param {*} num 
   * @param {*} point 
   * @returns 
   */
  formatPrice(num, point) {
    // 将数字转换为字符串,然后通过split方法用.分隔,取到第0个
    let numStr = num.toString().split('.')[0]
    if (numStr.length < 4) { // 判断数字有多长,如果小于6,,表示10万以内的数字,让其直接显示
      return numStr;
    }
    // else if (numStr.length == 3) {
    //   let decimal = numStr.substring(numStr.length - 2, numStr.length - 2 + point)
    //   // 由千位,百位组成的一个数字
    //   return parseFloat(parseInt(num / 100) + '.' + decimal) + '百'
    // } 
    else if (numStr.length == 4) {
      let decimal = numStr.substring(numStr.length - 3, numStr.length - 3 + point)
      // 由千位,百位组成的一个数字
      return parseFloat(parseInt(num / 1000) + '.' + decimal) + '千'
    } else if (numStr.length == 5) {
      let decimal = numStr.substring(numStr.length - 4, numStr.length - 4 + point)
      // 由千位,百位组成的一个数字
      return parseFloat(parseInt(num / 10000) + '.' + decimal) + '万'
    } else if (numStr.length >= 6 && numStr.length <= 8) { // 如果数字大于6位,小于8位,让其数字后面加单位万
      let decimal = numStr.substring(numStr.length - 4, numStr.length - 4 + point)
      // 由千位,百位组成的一个数字
      return parseFloat(parseInt(num / 10000) + '.' + decimal) + '万'
    } else if (numStr.length > 8) { // 如果数字大于8位,让其数字后面加单位亿
      let decimal = numStr.substring(numStr.length - 8, numStr.length - 8 + point);
      console.log(decimal);
      return parseFloat(parseInt(num / 100000000) + '.' + decimal) + '亿'
    }
  },
  /**
   * 格式化时间
   * @param {*} d 
   * @param {*} f 
   * @returns 
   */
  formatDate(d, f) {
    return dayjs(d).format(f || 'YYYY-MM-DD')
  },
  /**
   * 比较时间差
   * @param {*} dateTimeStamp 
   * @returns 
   */
  dateDiff(dateTimeStamp) {
    // 时间字符串转时间戳
    let timestamp = new Date(dateTimeStamp).getTime();
    let minute = 1000 * 60;
    let hour = minute * 60;
    let day = hour * 24;
    let month = day * 30;
    let year = day * 365;
    let now = new Date().getTime();
    let diffValue = now - timestamp;
    let result;
    if (diffValue < 0) {
      return;
    }
    let yearC = diffValue / year;
    let monthC = diffValue / month;
    let weekC = diffValue / (7 * day);
    let dayC = diffValue / day;
    let hourC = diffValue / hour;
    let minC = diffValue / minute;
    if (yearC >= 1) {
      result = "" + parseInt(yearC) + "年前";
    } else if (monthC >= 1) {
      result = "" + parseInt(monthC) + "月前";
    } else if (weekC >= 1) {
      result = "" + parseInt(weekC) + "周前";
    } else if (dayC >= 1) {
      result = "" + parseInt(dayC) + "天前";
    } else if (hourC >= 1) {
      result = "" + parseInt(hourC) + "小时前";
    } else if (minC >= 1) {
      result = "" + parseInt(minC) + "分钟前";
    } else
      result = "刚刚";
    return result;
  },
  /**
   * 比较传入时间和当前时间的时差
   * @param {*} t 
   * @param {*} diff 
   * @returns 
   */
  dayJSDiffFormNow(t, diff){
    const date1 = dayjs(t)
    return date1.diff(dayjs(), diff || 'day') 
  }
}