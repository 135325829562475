
import { mapState } from "vuex";
import threadsApi from "~/utils/api/threads";

export default {
  props: {
    /**
     * 关联文章
     */
    thread: {
      type: Object,
      default() {
        return null
      }
    }
  },
  data() {
    const { thread } = this;

    return {
      isPremium: thread.isPremium || false,
    }
  },
  computed: {
    ...mapState("users", {
      isAdminUser: (state) => state.isAdminUser,
    },),
    showQuickActions() {
      const { permissions } = this;

      const {isAdminUser} = this;
      if(isAdminUser){
        return true;
      }
      
      //- 如果不是管理用户，检测权限配置
      if (this.$_.isEmpty(permissions)) {
        return false;
      }

      // 检测是否有编辑权限，只要readable除外有true的，就说明有权限，具体自己在组件判断如permissions.updatable
      let hasEditableItem = false;
      for (const key in permissions) {
        if (key != "readable" && permissions[key] == true) {
          hasEditableItem = true;
        }
      }

      return hasEditableItem;
    },
    /**
     * 帖子权限
     */
    permissions() {
      const { thread } = this;
      if (this.$_.isEmpty(thread) || this.$_.isEmpty(thread.permission)) {
        return {};
      }

      return thread.permission;
    }
  },
  methods: {
    /**
     * 设置或取消精选
     */
    async handleSetPremium() {
      const { thread, isPremium } = this;
      try {

        const ok = await this.$toast.confirm({
          title: "提示",
          message: "是否将本文设置为精选?",
        });
        if (ok) {
          const done = await threadsApi.premium(thread.id);
          if (done) {
            this.isPremium = !isPremium;
          }

        }
      } catch (error) {
        console.warn("用户取消");
      }
    },
    /**
     * 编辑
     */
    handleEditIconClick() {
      const { thread } = this;
      this.$router.push(`/studio?update=${thread.hashID}`);
    },
    /**
     * 删除
     */
    async handleDeleteIconClick() {
      const { thread } = this;
      try {
        const ok = await this.$toast.confirm({
          title: "提示",
          message: "是否删除该文章，删除后不可恢复的?",
        });
        if (ok) {
          const done = await threadsApi.delete(thread.id);
          if (done) {
            this.$router.go(-1);
          }

        }
      } catch (error) {
        console.warn("用户取消");
      }
    },
  }
}
