
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("users", {
      user: (state) => state.user,
      isAdminUser: (state) => state.isAdminUser,
    }),
    /**
     * 是否已经登录
     */
    hasLogin() {
      const { user } = this;
      return this.$_.isEmpty(user) ? false : true;
    },
  },
  data() {
    return {
      visible: false,
      menusItems: [
        {
          icon: "wodeguanzhu",
          name: "账号管理",
          path: "/users/settings"
        },
        {
          icon: "sign-out_line",
          iconSize: 18,
          name: "退出登录",
          action: this.handleLogout,
        },
      ],
    };
  },
  methods: {
    /**
     * 默认方法
     * @param {*} item 
     */
    handleItemClick(item) {

      // 如果path为空优先跳转
      if (!this.$_.isEmpty(item.path)) {
        this.$router.push({ path: item.path });
      }
    },
    /**
     * 点击管理后台
     */
    handleAdminButtonClick() {
      let win = window.open();
      win.location.href = "/manager";
    },
    /**
     * 退出登录
     */
    async handleLogout() {
      try {
        const rs = await this.$toast.confirm({
          title: "提示",
          message: "是否确认退出登录当前账户?",
        });
        if (rs) {
          await this.$store.dispatch("users/logout");
        }
      } catch (error) {
        console.warn("用户取消");
      }
    },
    /**
     * 登录
     */
    async handleLoginButtonClick() {
      // 跳转登录页
      await this.$store.dispatch("users/redirect");
    },
  },
};
