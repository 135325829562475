import req from "~/utils/request";
import { URLS } from "~/utils/request/requestUrls";

export default {

  /**
   * 删除
   * @returns 
   */
  async delete(id) {
    const rs = await req.get({
      url: `${URLS.EXTERNAL_LINK_DELETE}/${id}`
    });
    return rs.data;
  },
  /**
   * 创建
   * @param {*} data 
   * @returns 
   */
  async create(data) {
    const rs = await req.postJson({
      url: URLS.EXTERNAL_LINK_CREATE,
      data
    });
    return rs.data;
  },
  /**
   * 列表
   * @returns 
   */
  async list() {
    const rs = await req.get({
      url: URLS.EXTERNAL_LINK_LIST
    });
    return rs.data;
  },
  /**
   * 列出显示的
   * @returns 
   */
  async listDisplay() {
    const rs = await req.get({
      url: URLS.EXTERNAL_LINK_LIST_DISPLAY,
    });
    return rs.data;
  },
}