
import usersApi from "~/utils/api/users";

export default {
  data() {
    return {
      profiles: {
        // city
        // gender
        // birthday
      },
      loading: true
    }
  },
  mounted() {
    this.$nextTick(async () => {
      await this.getProfiles();
    });
  },
  methods: {
    /**
     * 保存
     */
    async handleSave() {
      const { profiles } = this;

      this.loading = true;

      try {
        const rs = await usersApi.profilesModify({
          ...profiles
        });
        if (rs) {
          this.$toast.notify({
            title: "成功",
            message: "已经为您更新个人资料",
            duration: 3000,
            type: 'success'
          });
        }
      } catch (err) {
        console.error(err);
      }

      this.loading = false;
    },
    /**
     * 取个人资料
     */
    async getProfiles() {

      this.loading = true;

      try {
        const rs = await usersApi.profiles();
        if (rs) {
          this.profiles = rs.data;
        }
      } catch (err) {
        console.error(err);
      }

      this.loading = false;
    }
  }
}
