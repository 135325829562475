
import { mapState } from "vuex";

export default {
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState("editor", { draftList: (state) => state.draftList }),
  },
  mounted() {
    this.$nextTick(async () => {
      await this.getDraftList();
    });
  },
  methods: {
    /**
     * 请求恢复数据
     */
    async handleSelect(t) {
      try {
        const rs = await this.$toast.confirm({
          title: "提示",
          message: `是否恢复当前选中草稿“${t.name}”到编辑器?\r\n若您发布后将自动删除该草稿，或可保存为草稿。`,
        });
        if (rs) {
          this.$emit("change", t);
        }
      } catch (err) {
        console.warn(err);
      }
    },
    /**
     * 取草稿
     */
    async getDraftList() {
      this.loading = true;
      try {
        await this.$store.dispatch("editor/updateDraftList");
      } catch (err) {
        console.error(err);
      }
      this.loading = false;
    },
  },
};
