
import quetionApi from "~/utils/api/question";

export default {
  data() {
    return {
      questionList: [],
      loading: true
    }
  },
  async fetch() {
    await this.getQuestionList();
  },
  methods: {
    /**
     * 取问答列表
     */
    async getQuestionList() {
      this.loading = true;
      try {
        const rs = await quetionApi.listLite({
          hasPickedAnswer: false,
        });
        if (rs) {
          this.questionList = rs.data || [];
        }
      } catch (err) {
        console.error(err);
      }

      this.loading = false;
    }
  }
}
