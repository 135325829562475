
import { mapState } from "vuex";

export default {
  props: {
    slogan: {
      type: String,
      default: "业务入口 梦想起点"
    },
    backgroundImage: {
      type: String,
      default: "",
    },
    eventMode: {
      type: Boolean,
      default: false,
    },
    selectedTlds: {
      type: Array,
      default() {
        return []
      },
    },
    buttonClass: {
      type: String,
      default: "btn-primary"
    }
  },
  data() {
    return {
      tlds: [
        "com",
        "net",
        "cn",
        "com.cn"
      ],
      name: this.$route.query.name || "",
      currentTab: "RECOMMENDS"
    }
  },
  computed: {
    ...mapState("tld", {
      tldTypes: (state) => state.tldTypes,
      tldLiteList: (state) => state.tldLiteList,
      loadingTldLiteList: (state) => state.loadingTldLiteList,
    }),
    // 可以注册的
    enabledTlds() {
      const { tldLiteList } = this;
      return tldLiteList.filter(it => it.enabled);
    },
    /// 将TLD分类
    grouppedTldsByTypes() {
      const { tldTypes, enabledTlds, loadingTldLiteList } = this;
      if (loadingTldLiteList) {
        return [];
      }

      let types = this.$_.cloneDeep(tldTypes);
      types.unshift({
        name: "推荐注册的后缀",
        code: "RECOMMENDS",
      });

      types.forEach((it) => {
        if (it.code == "RECOMMENDS") {
          it.tlds = enabledTlds.filter(tld => tld.recommend === true);
        } else {
          it.tlds = enabledTlds.filter(tld => tld.tldType === it.code);
        }

      });

      return types;
    }
  },
  async fetch() {
    await this.$store.dispatch("tld/bootstrap");
    await this.$store.dispatch("tld/tldLiteList");

  },
  mounted() {
    this.$nextTick(async () => {
      // 补全传入预选的后缀
      this.appendSelectedTlds();

      // 自动触发搜索
      const { eventMode, name, tlds } = this;
      if (!eventMode || this.$_.isEmpty(name) || this.$_.isEmpty(tlds)) {
        return;
      }

      this.$emit('changed', { tlds, name })
    });
  },
  methods: {
    handleTabChange(tab) {
      this.currentTab = tab.code || "RECOMMENDS";
    },
    appendSelectedTlds() {
      const { selectedTlds, tlds } = this;
      if (this.$_.isEmpty(selectedTlds)) {
        return;
      }


      this.tlds = [
        ...tlds,
        ...selectedTlds];
    },
    handleClick() {
      const { tlds, name, eventMode } = this;

      // 将name移除.的后面部分
      let n = name.split('.');
      n = n[0] || '';

      // 如果是事件模式，则不进行路由跳转
      if (eventMode) {
        this.$emit('changed', { tlds, name: n })
        return;
      }

      const tldString = tlds.map(it => it).join(',');
      this.$router.push(`/product/domain/search?name=${n}&tld=${tldString}`)
    }
  }
}
