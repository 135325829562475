import { render, staticRenderFns } from "./_ip.vue?vue&type=template&id=310a19b0&scoped=true&lang=pug"
import script from "./_ip.vue?vue&type=script&lang=js"
export * from "./_ip.vue?vue&type=script&lang=js"
import style0 from "./_ip.vue?vue&type=style&index=0&id=310a19b0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "310a19b0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ToolsBreadcrumb: require('/Users/weizhang/Projects/mingcui_web/components/tools/Breadcrumb.vue').default})
