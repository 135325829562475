import fs from "~/utils/api/fs";
import MD5 from "crypto-js/md5";

/// 这是一个前端用于cos直传的Mixin实例，在组件中混入该对象，将获得cos上传的能力
export default {
  data() {
    return {
      // 对象存储身份验证信息
      fsCredential: null,
    }
  },
  methods: {
    //- 获取fs credential
    async _getFSCredential(fileName) {
      // const { fsCredential } = this;
      // 防止重复获取
      // if (!this.$_.isEmpty(fsCredential) ) {
      //   return 
      // }

      const rs = await fs.credential({
        fileName: fileName || ""
      });
      if (rs) {
        this.fsCredential = rs.data;
      }
    },
    //- 取腾讯云cos客户端实例
    //- 每次获取的客户端，将获得一个新的签名，避免过期
    async _getCosClient(fileName) {
      await this._getFSCredential(fileName);

      // 注意使用this.fsCredential，因为fsCredential存在异步更新的情况
      const credential = this.fsCredential.credential;

      const cos = new COS({
        // getAuthorization 必选参数
        getAuthorization: function (_, callback) {
          // 异步获取临时密钥
          // 服务端 JS 和 PHP 例子：https://github.com/tencentyun/cos-js-sdk-v5/blob/master/server/
          // 服务端其他语言参考 COS STS SDK ：https://github.com/tencentyun/qcloud-cos-sts-sdk
          // STS 详细文档指引看：https://cloud.tencent.com/document/product/436/14048

          callback({
            TmpSecretId: credential.tmpSecretID,
            TmpSecretKey: credential.tmpSecretKey,
            SecurityToken: credential.sessionToken,
            // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
            StartTime: credential.startTime, // 时间戳，单位秒，如：1580000000
            ExpiredTime: credential.expiredTime, // 时间戳，单位秒，如：1580000000
          });
        },
      });

      return cos;
    },
    ///取真实上传路径
    _getUploadKey(filename) {
      const { fsCredential } = this;
      if (!fsCredential) {
        throw "fsCredential is not initiallized";
      }

      return `${fsCredential.allowPrefixes}/${filename}`;
    },
    /// 调用COS SDK进行简单上传
    /// options: {fileName , body, done, onProgress}
    async putObject(options) {
      if (!options.file) {
        throw "none null options property: {file}"
      }

      // 文件名
      const fileText = await options.file.text();
      if(!fileText){
        throw "none null options property: {fileText}, file.text() return empty value. selected file is empty."
      }
      const fileHash = MD5(fileText).toString();
      const fileExtensionName = options.file.name.split(".")[options.file.name.split(".").length - 1];
      const fileName = `${fileHash}.${fileExtensionName}`;

      // 对象存储
      const cosClient = await this._getCosClient(fileName);
      const key = this._getUploadKey(fileName);
      if (cosClient) {
        const { fsCredential } = this;
        await cosClient.putObject(
          {
            Bucket: fsCredential.other.bucket /* 必须 */,
            Region: fsCredential.other.endpoint /* 存储桶所在地域，必须字段 */,
            Key: key /* 必须 */,
            StorageClass: "STANDARD",
            Body: options.file, // 上传文件对象
            onProgress: function (progressData) {
              typeof options.onProgress !== "undefined" ?
                options.onProgress(progressData) :
                console.log(JSON.stringify(progressData));
            },
          },
          /// 上传回调
          function (err, _) {
            typeof options.done !== "undefined" ?
              options.done(err ? false : true, key) :
              console.log(key + "上传" + (err ? "失败" : "完成"));

          }
        );
      }
    },
    // 调用COS SDK进行文件删除
    // options: {fileName, done}
    async deleteObject(options) {
      const cosClient = await this._getCosClient(options.fileName);
      if (cosClient) {
        const { fsCredential } = this;
        await cosClient.deleteObject({
          Bucket: fsCredential.other.bucket /* 必须 */,
          Region: fsCredential.other.endpoint /* 存储桶所在地域，必须字段 */,
          Key: options.fileName /* 必须 */,
        }, options.done || function (err, data) {
          console.log(err || data);
        });
      }
    }
  }
}