import req from "~/utils/request";
import { URLS } from "~/utils/request/requestUrls";

export default {
  /// 搜索
  async search(data) {
    const rs = await req.postJson({
      url: URLS.PEXELS_SEARCH,
      data
    });
    return rs.data;
  },
}