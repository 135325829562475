
import externalLinkApi from "~/utils/api/external-link";

export default {
  async fetch(){
    await this.getLinks();
  },
  head() {
    return {
      title: this.$app.getTitle('友情链接'),
    };
  },
  data() {
    return {
      externalLinks: []
    };
  },
  methods: {
    /**
     * 取得显示的链接
     */
    async getLinks() {
      const rs = await externalLinkApi.listDisplay();
      if(rs){
        this.externalLinks = rs.data || [];
      }
    }
  },
};
