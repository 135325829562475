import req from "~/utils/request";
import { URLS } from "~/utils/request/requestUrls";

export default {

  /**
   * 取QQ表情列表
   * @returns 
   */
  async qq() {
    const rs = await req.get({
      url: URLS.EMOJI_QQ
    });
    return rs.data;
  },
}