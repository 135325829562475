
export default {
  props: {
    // 默认类型
    type: {
      type: String,
      default: 'check_circle',
    },
    // 颜色
    color: {
      type: String,
      default: '#000000',
    },
    // 大小
    size: {
      type: Number,
      default: 30,
    },
  }
}
