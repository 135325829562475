
export default {
  head() {
    return {
      title: this.$app.getTitle('在线Punycode编码 Punycode解码 Punycode编码解码'),
      meta: [
        {
          hid: "keywords",
          name: "keywords",
          content: "在线Punycode编码 Punycode解码 Punycode编码解码 Punycode在线解码 中文域名解码 中文域名编码",
        },
      ]
    };
  },
  data() {
    return {
      method: 'btoa', // 或者atob
      beforeData: "", // 处理前的数据
      afterData: "", // 处理后的数据
    };
  },
  methods: {
    handleEncode() {
      const { beforeData } = this;
      const punycode = require("punycode");
      this.afterData = punycode.toASCII(beforeData);
    },
    handleDecode() {
      const { afterData } = this;
      const punycode = require("punycode");
      this.beforeData = punycode.toUnicode(afterData);
    }
  },
};
