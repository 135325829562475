
import { mapState } from "vuex";
import threadsApi from "~/utils/api/threads";
import metasMixins from "~/mixins/metas";

export default {
  mixins: [metasMixins],
  async fetch() {
    await this.getThreads();
  },
  head() {
    const { currentCategory, metas } = this;

    let category = {};
    if (!this.$_.isEmpty(currentCategory)) {
      category = {
        ...currentCategory,
      }
    }

    /// 补一个全部分类
    if (this.$_.isEmpty(category)) {
      category = {
        id: 0,
        name: "全部",
        slug: 'all',
        shortDesc: "全部分类下的文章",
        keywords: "全部文章",
      }
    }

    /// seo信息
    return {
      title: this.$app.getTitle(`${category.name}分类下的文章 第${metas.currentPage}页`),
      meta: [
        {
          hid: 'keywords',
          name: 'keywords',
          content: category.keywords || category.name,
        },
        {
          hid: 'description',
          name: 'description',
          content: category.shortDesc || category.name,
        }
      ]
    };
  },
  data() {
    return {
      slug: this.$route.params.categorySlug || '',
      threads: [],
      loading: true,
    };
  },
  computed: {
    ...mapState("threads", {
      threadCatgories: (state) => state.threadCatgories,
    }),
    /**
     * 当前分类
     */
    currentCategory() {
      const { threadCatgories, slug } = this;
      return threadCatgories.find((it) => it.slug == slug);
    },
    /**
     * 当前分类ID
     */
    currentID() {
      const { currentCategory } = this;
      if (!currentCategory) {
        return 0;
      }

      return Number(currentCategory.id || '0');
    },
    /**
     * 基础路径
     */
    basePath() {
      const { slug } = this;
      return this.$_.isEmpty(slug) ? "/threads/all" : `/threads/${slug}`;
    },
    /**
     * 重新计算分类，增加一个全部选项
     */
    categories() {
      const { threadCatgories } = this;
      if (this.$_.isEmpty(threadCatgories)) {
        return [];
      }

      let categories = [
        ...threadCatgories,
      ];

      const defaultCategory = { id: 0, name: '全部', slug: 'all' };
      if (!categories.find(it => it.name == '全部')) {
        categories.unshift(defaultCategory);
      }

      return categories;
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.avoidEmptySlug();
    });
  },
  methods: {
    /**
     * 如果传入的slug为空自动跳转到all，这样避免分页组件出现Bug
     */
    avoidEmptySlug() {
      const { slug } = this;
      if (!this.$_.isEmpty(slug)) {
        return;
      }

      this.$router.push("/threads/all");
    },
    /**
     * 用户点击投稿
     */
    handleUserRequestPost() {
      this.$router.push('/studio');
    },
    /**
     * 取主题列表
     */
    async getThreads(targetMetas) {
      const { slug, metas, currentID } = this;

      const willLoadMetas = targetMetas || metas;
      let data = {
        ...willLoadMetas
      };

      if (!this.$_.isEmpty(slug)) {
        data.categoryID = Number(currentID) || 0;
      }

      try {
        this.loading = true;
        const rs = await threadsApi.listSimple({ ...data });
        if (rs) {
          this.metas = rs.metas;
          this.threads = rs.data || [];
        }
      } catch (err) {
        console.error(err);
      }

      this.loading = false;
    }
  }
};
