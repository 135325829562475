
import { mapState } from "vuex";

export default {
  props: {
    /**
     * 是否仅显示推荐的后缀
     */
    recommend: {
      type: Boolean,
      default: false,
    }
  },
  async fetch() {
    await this.$store.dispatch("tld/tldLiteList");
  },
  data() {
    return {}
  },
  computed: {
    ...mapState("tld", { tldLiteList: (state) => state.tldLiteList }),
    // 可以注册的
    renderTldList() {
      const { tldLiteList, recommend } = this;
      const enabledTlds = tldLiteList.filter(it => it.enabled);
      if (!recommend) {
        return enabledTlds.slice(0, 12);
      }

      // 选取recommend
      return enabledTlds.filter((it) => it.recommend).slice(0, 12);
    },
  },
  methods: {

  }
}
