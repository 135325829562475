import tldApi from "~/utils/api/tld";
import isEmpty from 'lodash/isEmpty';

export const state = () => ({
  bootstrap: null,
  domainContactGSWLTypesCompany: [],
  domainContactGSWLTypesPersonal: [],
  domainContactHKIDTypesCompany: [],
  domainContactHKIDTypesPersonal: [],
  domainContactPreviewStatus: [],
  domainContactRegTypes: [],
  domainContactTelTypes: [],
  domainContactTypes: [],
  domainContactVerifyStatus: [],
  domainNameRuleValidateStatus: [],
  domainTransferStatus: [],
  tldRegistrars: [],
  recordTypes: [],
  recordLines: [],
  // TLD 类型
  tldTypes: [],
  eppIdTypes: [],
  /**
   * 简版TLD列表
   */
  tldLiteList: [],
  loadingTldLiteList: true,
  // 域名交易筛选类型
  domainTypeMetrics: [],
  domainDNSSecAlgorithmTypes: [],
  domainDNSSecDigestTypes: []
})

export const mutations = {
  //- update bootstrap information
  updateBootstrap(state, bootstrap) {
    state.bootstrap = bootstrap;

    /// 更新bootstrap时同时更新setSite
    /// 更新bootstrap同时更新verifyTypes
    /// 更新bootstrap同时更新qcloud
    if (typeof bootstrap === "object") {
      state.domainContactGSWLTypesCompany = bootstrap.domainContactGSWLTypesCompany || [];
      state.domainContactGSWLTypesPersonal = bootstrap.domainContactGSWLTypesPersonal || [];
      state.domainContactHKIDTypesCompany = bootstrap.domainContactHKIDTypesCompany || [];
      state.domainContactHKIDTypesPersonal = bootstrap.domainContactHKIDTypesPersonal || [];
      state.domainContactPreviewStatus = bootstrap.domainContactPreviewStatus || [];
      state.domainContactRegTypes = bootstrap.domainContactRegTypes || [];
      state.domainContactTelTypes = bootstrap.domainContactTelTypes || [];
      state.domainContactTypes = bootstrap.domainContactTypes || [];
      state.domainContactVerifyStatus = bootstrap.domainContactVerifyStatus || [];
      state.domainNameRuleValidateStatus = bootstrap.domainNameRuleValidateStatus || [];
      state.domainTransferStatus = bootstrap.domainTransferStatus || [];
      state.tldRegistrars = bootstrap.tldRegistrars || [];
      state.tldTypes = bootstrap.tldTypes || [];
      state.recordLines = bootstrap.recordLines || [];
      state.recordTypes = bootstrap.recordTypes || [];
      state.eppIdTypes = bootstrap.eppIdTypes || [];
      state.domainTypeMetrics = bootstrap.domainTypeMetrics || [];
      state.domainDNSSecAlgorithmTypes = bootstrap.domainDNSSecAlgorithmTypes || [];
      state.domainDNSSecDigestTypes = bootstrap.domainDNSSecDigestTypes || [];
    }
  },
  /**
   * 更新TLD列表
   * @param {*} state 
   * @param {*} tldLites 
   */
  updateTldLiteList(state, tldLites) {
    state.tldLiteList = tldLites;
    state.loadingTldLiteList = false;
  }
}

export const actions = {
  //- 启动配置
  async bootstrap(context) {
    if (!isEmpty(context.state.bootstrap)) {
      return;
    }

    const rs = await tldApi.bootstrap();
    if (rs) {
      context.commit("updateBootstrap", rs.data);
      return true;
    }
    return;
  },
  //- 获取TLD LiteList
  async tldLiteList(context) {
    // 避免重复更新
    if (!isEmpty(context.state.tldLiteList)) {
      return;
    }

    const rs = await tldApi.tldLiteList({});
    if (rs) {
      context.commit("updateTldLiteList", rs.data);
      return true;
    }
    return;
  },
}

export const namespaced = true;
