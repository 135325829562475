import { render, staticRenderFns } from "./Card.vue?vue&type=template&id=4f069e52&scoped=true&lang=pug"
import script from "./Card.vue?vue&type=script&lang=js"
export * from "./Card.vue?vue&type=script&lang=js"
import style0 from "./Card.vue?vue&type=style&index=0&id=4f069e52&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f069e52",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PaymentGatewaySelection: require('/Users/weizhang/Projects/mingcui_web/components/payment/GatewaySelection.vue').default})
