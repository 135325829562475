import threadsApi from "~/utils/api/threads";

export const state = () => ({
  draftList: [],
  // 是否正在提交保存或者创建
  submiting: false,
})

export const mutations = {
  //- update bootstrap information
  updateDraftList(state, draftList) {
    state.draftList = draftList;
  },
  updateSubmiting(state, submiting) {
    state.submiting = submiting;
  },
}

export const actions = {
  //- 更新草稿箱
  async updateDraftList(context) {
    try {
      const rs = await threadsApi.list({
        isDraft: true,
      });
      if (rs) {
        context.commit("updateDraftList", rs.data || []);
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return;
  },
  // 更新提交状态
  async updateSubmitingState(context, submiting) {
    context.commit("updateSubmiting", submiting || false);
  }

}

export const namespaced = true;
