
import { mapState } from "vuex";
import reportApi from "~/utils/api/report";

export default {
  head() {
    return {
      title: this.$app.getTitle('投诉举报'),
    };
  },
  data() {
    return {
      //- 选中的类型
      selectedType: this.$route.query.type || 1,
      // 举报网页地址
      url: null,
      // 联系方式
      contact: '',
      // 备注
      content: '',
      loading: false,
    };
  },
  computed: {
    ...mapState("users", { user: (state) => state.user }),
    ...mapState("bootstrap", {
      setSite: (state) => state.setSite,
      reportTypes: (state) => state.reportTypes,
    }),
  },
  mounted() {
    this.$nextTick(() => {
      this.autoCompl();
    });
  },
  methods: {
    /**
     * 自动填表
     */
    autoCompl() {
      const { user } = this;

      //- 自动填写用户联系方式
      if (!this.$_.isEmpty(user)) {
        this.contact = user.mobile || "";
      }

      //- 传入举报的页面地址
      const url = this.$route.query.resourceUrl || "";
      if (!this.$_.isEmpty(url)) {
        this.url = decodeURI(url)
      }

    },
    /**
     * 提交投诉建议
     */
    async submitReport() {
      const { url, contact, content, selectedType } = this;
      if (selectedType == null || selectedType == 0) {
        this.$toast.show("请选择投诉类型");
        return;
      }

      if (this.$_.isEmpty(contact)) {
        this.$toast.show("请填写联系方式");
        return;
      }

      if (this.$_.isEmpty(content)) {
        this.$toast.show("请填写备注信息，备注信息越详细投诉成功率越高");
        return;
      }

      let data = {
        url,
        contact,
        content,
        reportType: selectedType
      }

      this.loading = true;

      try {
        const rs = await reportApi.create({ ...data });
        if (rs) {
          this.url = "";
          this.contact = "";
          this.content = "";
          this.selectedType = 1;
          this.$toast.notify({
            title: "投诉成功",
            message: "我们会在2个工作日内进行处理，谢谢反馈",
            type: "success",
          });
        }
      } catch (err) {
        console.error(err);
      }

      this.loading = false;
    }
  },
};
