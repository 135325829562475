
import { mapState } from "vuex";

// 默认的筛选器
const defaultFilterFormData = {
  name: "",
  domainTypes: "",
  length: null,
  tld: null,
  sortBy: null,
  length: null,
  lengthMoreThan: null,
};

export default {
  data() {
    return {
      // domainExtTypes 与formData里的domainTypes不同，formData里的是根据domainTypes计算后的字符串
      domainExtTypes: [],
      domainType: null,
      formData: {
        ...defaultFilterFormData
      }
    }
  },
  computed: {
    ...mapState("tld", {
      domainTypeMetrics: (state) => state.domainTypeMetrics,
    }),
  },
  mounted() {
    this.$nextTick(async () => {
      await this.$store.dispatch("tld/bootstrap");
    });
  },
  methods: {
    /**
     * 排序条件变化
     * @param {*} sortBy 
     */
    handleSortChange(sortBy) {
      this.formData.sortBy = sortBy;

      // 如果是Null
      if (typeof sortBy == "string" && sortBy == "null") {
        this.formData.sortBy = null;
      }

      this.handleClick();

    },
    /**
     * 限制长度变化
     * @param {*} val 
     */
    handleChangeLengthMoreThan(val) {
      if (this.$_.isEmpty(val)) {
        this.formData.lengthMoreThan = null;
      } else {
        this.formData.lengthMoreThan = Number(val);
      }

      this.handleClick();
    },
    /**
     * 限制长度变化
     * @param {*} val 
     */
    handleChangeLength(val) {
      if (this.$_.isEmpty(val)) {
        this.formData.length = null;
      } else {
        this.formData.length = Number(val);
      }

      this.handleClick();
    },
    /**
     * 选中指定的tld变化
     * @param {*} selectedTlds 
     */
    async handleTldChange(selectedTlds) {
      this.formData.tld = selectedTlds;

      if (this.$_.isEmpty(selectedTlds)) {
        this.formData.tld = null;
      }

      this.handleClick();
    },
    hasDomainExtTypes(typeMetric) {
      const { domainExtTypes } = this;
      return domainExtTypes.find(it => it.value == typeMetric.value) ? true : false;
    },
    /**
     * 确定查询
     */
    handleClick() {
      const { formData, domainType, domainExtTypes } = this;

      let data = { ...formData };

      // 如果选择了分类
      if (!this.$_.isEmpty(domainType)) {
        data.domainTypes = `${domainType.value},`
      }

      // 如果选择了子分类
      if (!this.$_.isEmpty(domainExtTypes)) {
        const extTypes = domainExtTypes.map(it => it.value).join(",");
        data.domainTypes = `${domainType.value},${extTypes}`;
      }

      // 如果最后计算还是为空
      if (this.$_.isEmpty(domainExtTypes)) {
        data.domainTypes = null
      }

      this.$emit('changed', data)
    },
    handleClickFilter(dt, dext) {
      const { domainType } = this;

      // 主分类不一致时，清空
      if (domainType != null && domainType.value != dt.value) {
        this.domainExtTypes = [];
      }

      // 如果不存在则增加否则删除
      this.domainType = dt;

      let hasExtType = false;
      hasExtType = this.hasDomainExtTypes(dext);
      if (!hasExtType) {
        this.domainExtTypes.push(dext);
      } else {
        this.domainExtTypes = this.domainExtTypes.filter(it => it.value != dext.value);
      }

      this.handleClick();
    }
  }
}
