import { render, staticRenderFns } from "./RecentList.vue?vue&type=template&id=a27f9946&scoped=true&lang=pug"
import script from "./RecentList.vue?vue&type=script&lang=js"
export * from "./RecentList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a27f9946",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonEmpty: require('/Users/weizhang/Projects/mingcui_web/components/common/Empty.vue').default,ThreadsListTile: require('/Users/weizhang/Projects/mingcui_web/components/threads/ListTile.vue').default})
