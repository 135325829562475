import { render, staticRenderFns } from "./FirstPageList.vue?vue&type=template&id=2f6c5daa&scoped=true&lang=pug"
import script from "./FirstPageList.vue?vue&type=script&lang=js"
export * from "./FirstPageList.vue?vue&type=script&lang=js"
import style0 from "./FirstPageList.vue?vue&type=style&index=0&id=2f6c5daa&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f6c5daa",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonIconfont: require('/Users/weizhang/Projects/mingcui_web/components/common/Iconfont.vue').default,CommonEmpty: require('/Users/weizhang/Projects/mingcui_web/components/common/Empty.vue').default,PostsListTile: require('/Users/weizhang/Projects/mingcui_web/components/posts/ListTile.vue').default})
