
import userApi from "~/utils/api/users";

export default {
  name: "page-user-create",
  head() {
    return {
      title: this.$app.getTitle("修改密码"),
    };
  },
  data() {
    return {
      mobile: "",
      password: "",
      // 短信验证码
      verifyCode: "",
      loading: false,
      agree: true,
      // 发送短信回调数据
      smsCallbackData: {},
    };
  },
  computed: {
    /**
     * 是否禁用登录按钮
     */
    disableButton() {
      const { loading, password, mobile, agree } = this;
      if (
        !agree ||
        loading ||
        this.$_.isEmpty(mobile) ||
        this.$_.isEmpty(password)
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    /**
     * 短信验证码发送回调
     * @param {*} e
     */
    handleSendSMS(d) {
      console.log(d);
      this.smsCallbackData = d;
    },
    /**
     * 用户注册
     */
    async handleRegister() {
      const { disableButton, smsCallbackData } = this;
      if (disableButton) {
        this.$toast.show("请完善表单后继续");
        return;
      }

      if (this.$_.isEmpty(smsCallbackData)) {
        this.$toast.show("请先发送短信验证码");
        return;
      }

      //- 开始发送注册请求
      this.loading = true;

      try {
        const { mobile, password, verifyCode } = this;
        const rs = await userApi.resetPassword({
          mobile,
          password,
          verifyCode,
          ...smsCallbackData,
        });
        if (rs) {
          //- 注册成功，前往登录
          this.$toast.notify({
            title: "修改完成",
            message: "您的密码修改成功，现在您可以前往登录",
            type: "success",
          });

          setTimeout(async () => {
            await this.$store.dispatch("users/redirect", {
              // 不自动补全回调地址。防止登录后跳转回注册页
              preventCallbackRedirectLink: true,
            });
          }, 2000);
        }
      } catch (error) {}

      this.loading = false;
    },
  },
};
