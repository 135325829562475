
export default {
  computed: {
    /**
     * 是否显示
     */
    showTag() {
      return this.$C.isProduction ? false : true;
    },
  },
};
