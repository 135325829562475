
import Logo from "~/static/images/icon.svg";

export default {
  name: 'common-logo',
  data() {
    return {
      Logo
    }
  },
  props: {
    /**
     * 用于渲染的标题
     */
    logoText: {
      type: String,
      default: ""
    },
    /**
     * 字体大小
     */
    logoTextFontSize: {
      type: String,
      default: "22px"
    },
    /**
     * logo大小
     */
    logoSize: {
      type: String,
      default: "35px"
    },
    /**
     * 是否显示text
     */
    showText: {
      type: Boolean,
      default: true,
    },
    /**
     * 隐藏底部logo
     */
    showLogoSub: {
      type: Boolean,
      default: true,
    },
    /**
     * 点击后跳转至首页
     */
    tapToHome: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    /**
     * 点击
     */
    handleClick() {
      const { tapToHome } = this;
      if (!tapToHome) {
        return;
      }

      this.$router.push("/");
    },
  },
};
