export default ({ app: { router } }) => {
  /* 每次路由变更时进行pv统计 */
  // router.afterEach((to, from) => {
  //   /* 告诉增加一个PV */
  //   try {
  //     window._hmt = window._hmt || []
  //     window._hmt.push(['_trackPageview', to.fullPath])
  //   } catch (e) {
  //   }
  // })


  /// 百度自带的路由统计，需要开启单页面应用统计
  try {
    window._hmt = window._hmt || []
    window._hmt.push(['_requirePlugin', 'UrlChangeTracker', {
      shouldTrackUrlChange: function (newPath, oldPath) {
        return newPath && oldPath;
      }
    }
    ]);
  } catch (e) {
  }

}
