
export default {
  props: {
    title: {
      type: String,
      default: "测试"
    },
    to: {
      type: String,
      default: ""
    }
  }
}
