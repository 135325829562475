
import threadsApi from "~/utils/api/threads";
import metasMixins from "~/mixins/metas";
import format from "~/utils/format";

export default {
  name: "page-thread-tag",
  mixins: [metasMixins],
  async fetch() {
    await this.getTagItems();
  },
  data() {
    return {
      slug: this.$route.params.slug || '',
      tag: null,
      threadTagItems: [],
      loading: true,
    }
  },
  head(){
    const {tag} = this;

    return {
      title: this.$app.getTitle(`${tag == null ? '': tag.name}相关的文章`),
    };
  },
  methods: {
    // 格式化时间
    formatDate(t) {
      return format.formatDate(t, 'YYYY-MM-DD');
    },
    /**
     * 取标签关联的主题项目
     * @param {*} targetMetas 
     */
    async getTagItems(targetMetas) {
      const { slug, metas } = this;

      const willLoadMetas = targetMetas || metas;
      let data = {
        ...willLoadMetas
      };

      try {
        this.loading = true;
        const rs = await threadsApi.tagItems(slug, { ...data });
        if (rs) {
          this.metas = rs.metas;
          const data = rs.data || {};
          this.tag = data.threadTag || null;
          this.threadTagItems = data.threadTagItems || [];
        }
      } catch (err) {
        console.error(err);
      }

      this.loading = false;
    }
  }
}
