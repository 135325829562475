import { render, staticRenderFns } from "./_page.vue?vue&type=template&id=5c6f4d16&scoped=true&lang=pug"
import script from "./_page.vue?vue&type=script&lang=js"
export * from "./_page.vue?vue&type=script&lang=js"
import style0 from "./_page.vue?vue&type=style&index=0&id=5c6f4d16&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c6f4d16",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonPagenationLink: require('/Users/weizhang/Projects/mingcui_web/components/common/PagenationLink.vue').default})
