import { render, staticRenderFns } from "./RecentThreads.vue?vue&type=template&id=4ebd60e9&scoped=true&lang=pug"
import script from "./RecentThreads.vue?vue&type=script&lang=js"
export * from "./RecentThreads.vue?vue&type=script&lang=js"
import style0 from "./RecentThreads.vue?vue&type=style&index=0&id=4ebd60e9&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ebd60e9",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonIconfont: require('/Users/weizhang/Projects/mingcui_web/components/common/Iconfont.vue').default,CommonEmpty: require('/Users/weizhang/Projects/mingcui_web/components/common/Empty.vue').default,ThreadsReviewListTile: require('/Users/weizhang/Projects/mingcui_web/components/threads/ReviewListTile.vue').default})
