import req from "~/utils/request";
import { URLS } from "~/utils/request/requestUrls";

export default {
  /// 创建订单
  async create(data) {
    const rs = await req.postJson({
      url: URLS.ORDER_CREATE,
      data
    });
    return rs.data;
  },

  // 查询订单列表
  async list(data) {
    const rs = await req.postJson({
      url: URLS.ORDER_LIST,
      data
    });
    return rs.data;
  },

  // 取消订单
  async cancel(orderCode) {
    const rs = await req.get({
      url: `${URLS.ORDER_CANCEL}/${orderCode}`
    });
    return rs.data;
  },
  
  // 取订详情
  async detail(id) {
    const rs = await req.get({
      url: `${URLS.ORDER_DETAIL}/${id}`
    });
    return rs.data;
  },

  //- 手动发货
  async deliver(id) {
    const rs = await req.get({
      url: `${URLS.ORDER_DELIVER}/${id}`
    });
    return rs.data;
  },
}