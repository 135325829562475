
import searchApi from "~/utils/api/search";
import metasMixins from "~/mixins/metas";
import format from "~/utils/format";

export default {
  mixins: [metasMixins],
  head() {
    const { keyword } = this;

    return {
      title: this.$app.getTitle(`搜索${keyword}的结果`),
    }
  },
  data() {
    return {
      keyword: decodeURI(this.$route.params.keyword || ""),
      currentPage: this.$route.query.page || 1,
      loading: false,
      result: [],
      indexType: this.$route.params.indexType || "threads",
      words: [
        "名翠云监控",
        "JavaScript",
        "域名",
        "证书监控"
      ]
    };
  },
  computed: {
    /**
     * 基础路径
     */
    basePath() {
      const { keyword, indexType } = this;
      return `/search/${indexType}/${encodeURI(keyword)}`;
    }
  },
  async fetch() {
    await this.search();
  },
  methods: {
    // 格式化时间
    formatDate(t) {
      return format.formatDate(t, 'YYYY-MM-DD');
    },
    // 加载搜索结果
    async search(targetMetas) {
      const { metas, keyword, indexType } = this;

      const willLoadMetas = targetMetas || metas;
      let data = {
        ...willLoadMetas,
        // 仅加载顶级评论
        keyword,
        indexType
      };

      this.loading = true;

      try {
        const rs = await searchApi.do({
          ...data
        });
        if (rs) {
          this.metas = rs.metas;
          this.result = rs.data || [];
        }
      } catch (err) {
        console.log(err);
      }

      this.loading = false;
    }
  }
};
