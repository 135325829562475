
import tldApi from "~/utils/api/tld";
import format from "~/utils/format";

export default {
  name: "page-tld-detail",
  head() {
    const { tld } = this;

    return {
      title: this.$app.getTitle(`.${tld.name}域名注册`),
    };
  },
  async asyncData({ params, error }) {
    let tld;

    try {
      const rs = await tldApi.detail(params.id);
      if (rs) {
        tld = rs.data;
      }
    } catch (err) {
      // 抛异常，
      // 如果错误是record not found, 并将Nuxtjs中的响应状态跟新为404
      error({ status: 404, message: err.message || "未知错误" });
    }

    return {
      tld,
      // 覆盖loading
      loading: false
    }
  },
  data() {
    return {
      id: this.$route.params.id || "",
      loading: true,
      tld: {},
    };
  },
  methods: {
    /**
     * 格式化时间
     */
    formatDate(t) {
      return format.formatDate(t, "YYYY-MM-DD");
    },
  },
};
