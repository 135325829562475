import Vue from 'vue'
import isEmpty from 'lodash/isEmpty'
import isNumber from 'lodash/isNumber'
import isObject from 'lodash/isObject'
import trim from 'lodash/trim'
import debounce from 'lodash/debounce'
import take from 'lodash/take'
import trimEnd from 'lodash/trimEnd'
import trimStart from 'lodash/trimStart'
import isArray from 'lodash/isArray'
import isElement from 'lodash/isElement'
import includes from 'lodash/includes'
import cloneDeep from 'lodash/cloneDeep';
import clone from 'lodash/clone';

var lodash = {
    isEmpty,
    isNumber,
    isObject,
    trim,
    debounce,
    take,
    trimEnd,
    trimStart,
    isArray,
    isElement,
    includes,
    /// 深拷贝不会改变原来的对象，浅拷贝会，知道了吗，改代码的时候注意使用
    cloneDeep,
    clone
}

Vue.prototype.$_ = lodash

export default lodash;