
export default {
  data() {
    return {
      // 使用图标集
      // https://www.iconfont.cn/collections/detail?spm=a313x.7781069.1998910419.d9df05512&cid=42739
      menus: [
        { icon: '/images/post.png', name: '投稿', path: '/studio', alt: 'post' },
        { icon: '/images/ask.png', name: '问答', path: '/faq', alt: 'ask' },
      ]
    }
  }
}
