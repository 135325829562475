
import coxMixins from "~/mixins/cos";
import { mapState } from "vuex";

export default {
  mixins: [coxMixins],
  props: {
    value: String,
  },
  data() {
    return {
      coverImageKey: null,
      uploading: false,
    };
  },
  computed: {
    ...mapState("bootstrap", {
      qcloud: (state) => state.qcloud,
    }),
    /// 图片CDN域名
    qcloudCOSCdnFileDomain() {
      const { qcloud } = this;
      return qcloud.qcloudCOSCdnFileDomain || "";
    },
    /**
     * 上传文件的URL
     */
    uploadedFileUrl() {
      const { coverImageKey, qcloudCOSCdnFileDomain } = this;
      if (this.$_.isEmpty(coverImageKey)) {
        return null;
      }

      return `${qcloudCOSCdnFileDomain}${coverImageKey}`;
    },
  },
  methods: {
    /**
     * 要删除图片
     */
    async handleDeleteImage() {
      const { coverImageKey } = this;

      this.deleteObject({
        fileName: coverImageKey,
        done: (err, data) => {
          console.info(err);

          if (err) {
            this.$toast.show("删除失败，请重试");
            return;
          }

          console.log("文件删除", data)
          this.coverImageKey = null;
          this.$emit("input", null);
        },
      });
    },
    /**
     * 图片选择
     */
    imageInputEl() {
      const imgSelector = document.getElementById("thread-cover-img-upload-input");
      if (!imgSelector) {
        this.$toast.show("编辑器错误，无法找到用于上传文件的INPUT元素");
        return;
      }

      return imgSelector;
    },
    /**
     * 托管图片上传
     */
    imageHandler() {
      const imgSelector = this.imageInputEl();
      if (!imgSelector) {
        return;
      }

      imgSelector.click();
    },
    /**
     * 上传文件发生变化
     */
    async handleFileChanged() {
      const imgSelector = this.imageInputEl();
      if (!imgSelector) {
        return;
      }

      const file = imgSelector.files[0] || null;
      if (!file) {
        return;
      }
      this.uploading = true;

      console.log("开始上传文件：", file);
      // 上传文件
      this.putObject({
        file: file,
        done: async (ok, url) => {
          this.uploading = false;
          if (!ok) {
            this.$toast.show("上传失败，请重新试试");
            return;
          }

          this.coverImageKey = url;
          this.$emit("input", url);
          imgSelector.value = null;
          console.log("文件上传成功", url);
        },
      });
    },
  },
  watch: {
    value(newVal, _) {
      this.coverImageKey = newVal;
    },
  },
};
