
import layoutMixins from '~/mixins/layout';

export default {
  mixins: [layoutMixins],
  data() {
    return {
      keepAliveProps: {
        // 排除 page name，不进行缓存
        exclude: [
          'page-threads-detail',
          'page-question-detail',
          'page-studio',
          'page-posts-detail',
          'page-brand-detail',
          'page-jade-product-detail',
          "page-about",
          "page-announcement-detail",
          "product-landing",
          "page-product-domain",
          "page-tld-detail",
          "page-product-domain-search",
          "users-order-list",
          // "page-product-domain-trade"
        ]
      }
    }
  }
};
