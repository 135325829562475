
import { mapState } from "vuex";

export default {
  props: {
    value: {
      type: String,
    }
  },
  data() {
    return {
      selectedTld: this.value || [],
    }
  },
  computed: {
    ...mapState("tradeDomain", {
      tlds: (state) => state.tlds,
    }),
  },
  mounted() {
    this.$nextTick(async () => {
      await this.$store.dispatch("tradeDomain/getTlds");
    });
  },
  methods: {
    handleChange() {
      const {selectedTld} = this;
      this.$emit('change', selectedTld || null);
    }
  }
}
