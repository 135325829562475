import { render, staticRenderFns } from "./hao.vue?vue&type=template&id=380d3ec7&lang=pug"
import script from "./hao.vue?vue&type=script&lang=js"
export * from "./hao.vue?vue&type=script&lang=js"
import style0 from "./hao.vue?vue&type=style&index=0&id=380d3ec7&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UserSettingsMenus: require('/Users/weizhang/Projects/mingcui_web/components/user/settings/Menus.vue').default,FilePicker: require('/Users/weizhang/Projects/mingcui_web/components/file/picker.vue').default})
