import AES from 'crypto-js/aes';
import EncodeUTF8 from 'crypto-js/enc-utf8';


const encryptKey = "a4144a28a61e0724";

//- encrypt data
function encrypt(value) {
  return AES.encrypt(value, encryptKey).toString();
}

//- decrypt
function decrypt(value) {
  var bytes = AES.decrypt(value, encryptKey);
  return bytes.toString(EncodeUTF8);
}

export default {
  encrypt,
  decrypt
}