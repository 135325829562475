
import quetionApi from "~/utils/api/question";
import metasMixins from "~/mixins/metas";

export default {
  mixins: [metasMixins],
  props: {
    showTitle: {
      type: Boolean,
      default: true
    },
    showBadge: {
      type: Boolean,
      default: false
    },
    showMoreButton: {
      type: Boolean,
      default: true
    },
    limit: {
      type: Number,
      default: 50
    }
  },
  data() {
    return {
      questionList: [],
      loading: true
    }
  },
  async fetch() {
    await this.getQuestionList();
  },
  methods: {
    /**
     * 取问答列表
     */
    async getQuestionList() {
      this.loading = true;
      try {
        const { limit } = this;
        const rs = await quetionApi.listLite({
          hasPickedAnswer: true,
          //- 写死选取50个
          limit
        });
        if (rs) {
          this.metas = rs.metas;
          this.questionList = rs.data || [];
        }
      } catch (err) {
        console.error(err);
      }

      this.loading = false;
    }
  }
}
