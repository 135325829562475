
import domainApi from "~/utils/api/domain";
import { mapState } from "vuex";

export default {
  name: "page-product-domain-search",
  head() {
    return {
      title: this.$app.getTitle('查询域名可用性'),
    };
  },
  mounted() {
    this.$store.dispatch("navbar/updateNavbar", {
      backgroundColor: "#292927",
      mode: "dark",
      borderBottom: "none"
    });
  },
  beforeDestroy() {
    this.$store.dispatch("navbar/updateNavbar", null);
  },
  data() {
    return {
      loading: false,
      tld: this.$route.query.tld || '',
      results: []
    };
  },
  computed: {
    ...mapState("carts", { domains: (state) => state.domains }),
    /**
     * 浏览器传入预选的Tld
     */
    browserQureryInputTlds(){
      const {tld} = this;
      if(this.$_.isEmpty(tld)){
        return []
      }

      return tld.split(",")
    }
  },
  methods: {
    /**
     * 购物车中是否已经加入
     * @param {*} domainName 
     */
    hasDomainName(domainName){
      const {domains} = this;
      return domains.find(it => it.domainName == domainName) ? true : false;
    },
    addToCart(item){
      this.$store.dispatch('carts/addDomains', [item])
    },
    /**
     * 查询域名
     * @param {*} params 
     */
    async handleSearch(params) {

      this.results = [];
      this.loading = true;

      try {
        const rs = await domainApi.availability({
          ...params
        });
        if (rs) {
          this.results = rs.data || [];
        }
      } catch (err) {
        console.error(err);
      }

      this.loading = false;
    }
  },
};
