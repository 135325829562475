import req from "~/utils/request";
import { URLS } from "~/utils/request/requestUrls";

export default {
  //- 创建举报
  async create(data) {
    const rs = await req.postJson({
      url: URLS.REPORT_CREATE,
      data
    });
    return rs.data;
  },
  //- 列表
  async list(data) {
    const rs = await req.postJson({
      url: URLS.REPORT_LIST,
      data
    });
    return rs.data;
  },
  // 标注
  async mark(id, data) {
    const rs = await req.postJson({
      url: `${URLS.REPORT_MARK}/${id}`,
      data
    });
    return rs.data;
  },
}