import { render, staticRenderFns } from "./_page.vue?vue&type=template&id=3953b432&scoped=true&lang=pug"
import script from "./_page.vue?vue&type=script&lang=js"
export * from "./_page.vue?vue&type=script&lang=js"
import style0 from "./_page.vue?vue&type=style&index=0&id=3953b432&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3953b432",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonLogo: require('/Users/weizhang/Projects/mingcui_web/components/common/Logo.vue').default,SearchFlatTextfield: require('/Users/weizhang/Projects/mingcui_web/components/search/FlatTextfield.vue').default,CommonEmpty: require('/Users/weizhang/Projects/mingcui_web/components/common/Empty.vue').default,CommonPagenationLink: require('/Users/weizhang/Projects/mingcui_web/components/common/PagenationLink.vue').default})
