
import metasMixins from "~/mixins/metas";
import domainTradeApi from "~/utils/api/domain-trade";
import format from "~/utils/format";

export default {
  name: "page-product-domain-trade",
  mixins: [metasMixins],
  head() {
    return {
      title: this.$app.getTitle('一口价'),
      meta: [
        {
          hid: "keywords",
          name: "keywords",
          content: "域名交易,一口价域名,域名拍卖,域名抢注,白金词域名,优质域名拍卖,域名过户",
        },
      ]
    };
  },
  data() {
    return {
      domains: [],
      loading: false,
      filterData: {}
    };
  },
  async fetch() {
    await this.list();
  },
  // activated() {
  //   this.$store.dispatch("navbar/updateNavbar", {
  //     backgroundColor: "#061126",
  //     mode: "dark",
  //     borderBottom: "none"
  //   });
  // },
  // deactivated() {
  //   this.$store.dispatch("navbar/updateNavbar", null);
  // },
  methods: {
    // 格式化时间
    formatDate(t) {
      return format.formatDate(t, 'YYYY-MM-DD');
    },
    /**
     * 筛选器变化时，要更改路由地址
     * @param {*} params 
     */
    async handleSearchBannerChanged(params) {
      if (this.$_.isEmpty(params)) {
        this.filterData = {};
        return;
      }

      this.filterData = {
        ...params
      };

      await this.list();
    },
    /**
   * 分页变化
   * @param {*} val 
   */
    async handleCurrentChange(val) {
      const { metas } = this;
      let willLoadMeta = { ...metas };
      willLoadMeta.currentPage = val;

      // 仅更改路由地址但不刷新
      window.history.pushState({}, '', `/product/trade/${val}`)
      // window.location.reload()

      await this.list(willLoadMeta);
    },
    // 加载搜索结果
    async list(targetMetas) {
      const { metas, filterData } = this;

      this.domains = [];
      const willLoadMetas = targetMetas || metas;
      let data = {
        ...willLoadMetas,
        // 仅加载顶级评论
        ...filterData
      };

      this.loading = true;

      try {
        const rs = await domainTradeApi.list({
          ...data
        });
        if (rs) {
          this.metas = rs.metas;
          this.domains = rs.data || [];
        }
      } catch (err) {
        console.log(err);
      }

      this.loading = false;
    },
    formatPrice(p) {
      return format.priceToFixed(p);
    }
  },
};
