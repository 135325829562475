
import tldApi from "~/utils/api/tld";

export default {
  name: "page-product-domain-price",
  data() {
    return {
      tldLiteList: [],
      loading: true
    }
  },
  head() {
    return {
      title: this.$app.getTitle('域名注册价格表'),
    };
  },
  async asyncData({ error }) {
    let tldLiteList = [];

    try {
      const rs = await tldApi.tldLiteList({
        enabled: true
      });
      if (rs) {
        tldLiteList = rs.data;
      }
    } catch (err) {
      // 抛异常，
      // 如果错误是record not found, 并将Nuxtjs中的响应状态跟新为404
      error({ status: 404, message: err.message || "未知错误" });
    }

    return {
      tldLiteList,
      // 覆盖loading
      loading: false
    }
  },
  methods: {

  }
}
