import req from "~/utils/request";
import { URLS } from "~/utils/request/requestUrls";

export default {
  //- 发送短信验证码
  //- 注意，发送短信验证码前先发起验证码逻辑并将回调数据合并到data中
  async send(data) {
    const rs = await req.postJson({
      url: URLS.SMS_SEND,
      data
    });
    return rs.data;
  },
}