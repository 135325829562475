
import { mapState } from "vuex";
import quetionApi from "~/utils/api/question";

export default {
  props: {
    fullMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      name: "",
      content: "",
      isAnnoy: true,
      /**
       * 用户上传的附件
       */
      attachments: []
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.autoCompl();
    });
  },
  computed: {
    ...mapState("users", {
      user: (state) => state.user,
      loadingUserInfo: (state) => state.loadingUserInfo,
    }),
    /**
    * 是否已经登录
    */
    hasLogin() {
      const { user } = this;
      return this.$_.isEmpty(user) ? false : true;
    },
  },
  methods: {
    /**
     * 登录
     */
    async handleRequestLoginButtonClick() {
      await this.$store.dispatch("users/redirect")
    },
    /**
     * 自动填表
     */
    autoCompl() {
      let title = this.$route.query.title;
      if (this.$_.isEmpty(title)) {
        return;
      }

      this.name = decodeURI(title);
    },
    /**
     * 点击后先登录
     */
    async handleClick() {
      const { name, content, isAnnoy, hasLogin, attachments } = this;

      if (!hasLogin) {
        this.$toast.show("请先登录");
        await this.$store.dispatch("users/redirect");
        return;
      }

      if (this.$_.isEmpty(name) || name.length < 5) {
        this.$toast.show("请输入标题，且标题不少于5个字符");
        return;
      }

      const attachmentsIds = (attachments || []).map(it => it.id);
      

      const rs = await quetionApi.create({
        name,
        content,
        isAnnoy,
        attachments: attachmentsIds
      });
      if (rs) {
        this.$toast.notify({
          title: "发布成功",
          message: "问题发布成功，我们将推荐给更多用户为您答复",
          type: "success",
        });
        this.$emit('created', rs.data || null);

        this.name = "";
        this.content = "";
        this.isAnnoy = true;
      }

    }
  }
}
