
import format from "~/utils/format";
import threadsApi from "~/utils/api/threads";
import metasMixins from "~/mixins/metas";

export default {
  mixins: [metasMixins],
  head() {
    const { metas } = this;

    return {
      title: this.$app.getTitle(`主题标签页 第${metas.currentPage}页`),
    };
  },
  async fetch() {
    await this.tagList();
  },
  data() {
    return {
      threadTags: [],
      loading: true,
      basePath: "/threads/tag/list"
    };
  },
  methods: {
    // 格式化时间
    formatDate(t) {
      return format.formatDate(t, 'YYYY-MM-DD');
    },
    /**
     * 取标签关联的主题项目
     * @param {*} targetMetas 
     */
    async tagList(targetMetas) {
      const { metas } = this;

      const willLoadMetas = targetMetas || metas;
      let data = {
        ...willLoadMetas
      };

      try {
        this.loading = true;
        const rs = await threadsApi.tagList({ ...data });
        if (rs) {
          this.metas = rs.metas;
          this.threadTags = rs.data || [];
        }
      } catch (err) {
        console.error(err);
      }

      this.loading = false;
    }
  },
};
