import { render, staticRenderFns } from "./ReviewListTile.vue?vue&type=template&id=dc8192d0&scoped=true&lang=pug"
import script from "./ReviewListTile.vue?vue&type=script&lang=js"
export * from "./ReviewListTile.vue?vue&type=script&lang=js"
import style0 from "./ReviewListTile.vue?vue&type=style&index=0&id=dc8192d0&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc8192d0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonIconfont: require('/Users/weizhang/Projects/mingcui_web/components/common/Iconfont.vue').default})
