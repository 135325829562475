import { mapState } from "vuex";

export default {
  // 初始化应用
  async fetch() {
    await this.bootstrapApp();
   },
  computed: {
    ...mapState("bootstrap", {
      bootstrap: (state) => state.bootstrap,
    }),
    ...mapState("users", {
      user: (state) => state.user,
    }),
  },
  mounted() {
    this.$nextTick(async () => {
      this.handleLayoutMouned();
    });
  },
  methods: {
    async bootstrapApp() {
      // 防止重复初始化
      const { bootstrap } = this;
      if (!this.$_.isEmpty(bootstrap)) {
        return;
      }

      await this.$store.dispatch("bootstrap/bootstrap");
    },
    /**
     * 生命周期回调处理
     */
    async handleLayoutMouned() {
      const {user} = this;
      if (!this.$_.isEmpty(user)) {
        return;
      }

      await this.$store.dispatch("users/restoreLoginState");
    }
  }
};