import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("users", { user: (state) => state.user }),
  },
  methods: {
    /**
     * 用户已经登录提示
     * 延迟调用
     */
    async hasLoginedNotice() {
      const { user } = this;
      if (this.$_.isEmpty(user)) {
        return;
      }

      try {
        const rs = await this.$toast.confirm({
          title: "提示",
          message: "您的账号已经登录，无需重复登录，是否带您前往首页?",
          confirmButtonText: "前往首页"
        });
        if (rs) {
          this.$router.push("/");
        }
      } catch (error) {

      }
    }
  }
}