
import { mapState } from "vuex";

export default {
  name: "page-faq-index",
  head() {
    return {
      title: this.$app.getTitle('问答'),
    };
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("users", { user: (state) => state.user }),
  },
  methods: {
    /**
     * 发布问题成功回调
     */
    handleCreated(q){
      console.log(q);
      this.$router.push(`/faq/${q.id}.html`);
    }
  },
};
