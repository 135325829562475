export default {
  data() {
    return {
      metas: {
        currentPage: Number(this.$route.params.page || "1"),
        // 前端不写limit 仅用于测试 交给后端控制
        // limit: 5,
        total: 0,
      },
      /**
       * 默认的
       */
      defaultMetas: {
        currentPage: Number(this.$route.params.page || "1"),
        total: 0,
      }
    }
  },
  computed: {
    /**
     * 
     * @returns 计算是否还有更多页面
     */
    hasMore() {
      const { metas, totalPage } = this;
      return metas.currentPage < totalPage ? true : false;
    },
    /**
     * 
     * @returns 计算总页面
     */
    totalPage() {
      const { metas } = this;
      if (!metas.total) {
        return 1;
      }

      return this.calculatePagesCount(metas.limit, metas.total);
    },
    /**
     * 
     * @returns 下一页分页的对象
     */
    nextPageMetas() {
      const { metas } = this;
      let m = {
        ...metas,
        currentPage: metas.currentPage + 1
      }

      delete m.total;
      delete m.limit;

      return m
    }
  },
  methods: {
    calculatePagesCount(pageSize, totalCount) {
      // we suppose that if we have 0 items we want 1 empty page
      return totalCount < pageSize ? 1 : Math.ceil(totalCount / pageSize);
    }
  }
}