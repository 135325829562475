
import format from "~/utils/format";

export default {
  props: {
    /**
     * 主题信息，不包含内容
     */
    thread: {
      type: Object,
      default() {
        return null
      }
    },
    /**
     * 显示分类名称
     */
    showCategoryName: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    // 副标题
    createdAt() {
      const { thread } = this;

      const createdAt = this.formatDate(thread.createdAt || null);
      return createdAt;
    },
    authorName() {
      const { thread } = this;
      const authorName = thread.author == null ? '' : thread.author.username;
      return authorName;
    },
    categoryName() {
      const { thread } = this;
      return thread.categoryName || '未知';
    },
    cachedViewCounts() {
      const { thread } = this;
      return thread.cachedViewCounts || 0;
    },
    /**
     * 用于渲染封面
     */
    renderImages() {
      const { thread } = this;
      if (!this.$_.isEmpty(thread.coverImageUrl)) {
        return [thread.coverImageUrl];
      }

      const images = thread.images || [];
      return images.slice(0, 3);
    }
  },
  methods: {
    /**
     * 格式化时间
     */
    formatDate(t) {
      return format.formatDate(t, 'YYYY-MM-DD');
    }
  }
}
