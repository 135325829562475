import isEmpty from 'lodash/isEmpty';
import Storage from "~/utils/storage";

//-用于存储accessTokenStorageKey
const accessTokenStorageKey = "cs_";

export default class AuthHelper {


  //- saveToken
  saveToken(token) {
    if (!process.client) {
      return false;
    }

    new Storage().set(accessTokenStorageKey, JSON.stringify(token));
    return true;
  }

  //- get local token
  getLocalStoredToken() {
    if (!process.client) {
      return null;
    }

    const tokenString = new Storage().get(accessTokenStorageKey);
    return isEmpty(tokenString) ? null : JSON.parse(tokenString);
  }

  //- 清空 
  clear() {
    if (!process.client) {
      return null;
    }
    new Storage().del(accessTokenStorageKey);
  }
}
