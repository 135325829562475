import threadsApi from '~/utils/api/threads';

export const state = () => ({
  threadCatgories: [],
})

export const mutations = {
  //- update bootstrap information
  updateThreadCatgories(state, threadCatgories) {
    state.threadCatgories = threadCatgories;
  },

}

export const actions = {
  //- 获取分类
  async updateThreadCatgories(context) {
    try {
      const rs = await threadsApi.categoriesCached();
      if (rs) {
        context.commit("updateThreadCatgories", rs.data);
        return true;
      }
    } catch (error) {
      console.error(error);
    }

    return;
  },

}

export const namespaced = true;
