const defaultNavbar = {
  mode: "light", // dark or light
  backgroundColor: "#FFFFFF",
  borderBottom: "0.5px solid #eaecf3"
}

export const state = () => ({
  navbar: {
    ...defaultNavbar
  }
})

export const mutations = {
  //- update navbar
  updateNavbar(state, navbar) {
    state.navbar = navbar;
  },
}

export const actions = {
  //- 更新草稿箱
  updateNavbar(context, navbar) {
    let n = navbar;
    if (n == null) {
      n = { ...defaultNavbar };
    }
    context.commit("updateNavbar", n);
  },

}

export const namespaced = true;
