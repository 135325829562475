
import { mapState } from "vuex";
import coxMixins from "~/mixins/cos";

export default {
  mixins: [coxMixins],
  data() {
    return {
      uploading: false,
      fileKey: null,
    }
  },
  computed: {
    ...mapState("bootstrap", {
      qcloud: (state) => state.qcloud,
    }),
    /// 图片CDN域名
    qcloudCOSCdnFileDomain() {
      const { qcloud } = this;
      return qcloud.qcloudCOSCdnFileDomain || "";
    },
    /**
     * 上传文件的URL
     */
    uploadedFileUrl() {
      const { fileKey, qcloudCOSCdnFileDomain } = this;
      if (this.$_.isEmpty(fileKey)) {
        return null;
      }

      return `${qcloudCOSCdnFileDomain}${fileKey}`;
    },
  },
  methods: {
    /**
     * 图片选择
     */
    imageInputEl() {
      const imgSelector = document.getElementById("file-picker");
      if (!imgSelector) {
        this.$toast.show("无法找到用于上传文件的INPUT元素");
        return;
      }

      return imgSelector;
    },
    /**
     * 托管图片上传
     */
    imageHandler() {
      const imgSelector = this.imageInputEl();
      if (!imgSelector) {
        return;
      }

      imgSelector.click();
    },
    /**
     * 上传文件发生变化
     */
    async handleFileChanged() {
      const imgSelector = this.imageInputEl();
      if (!imgSelector) {
        return;
      }

      const file = imgSelector.files[0] || null;
      if (!file) {
        return;
      }
      this.uploading = true;

      console.log("开始上传文件：", file);
      // 上传文件
      this.putObject({
        file: file,
        done: async (ok, url) => {
          this.uploading = false;
          if (!ok) {
            this.$toast.show("上传失败，请重新试试");
            return;
          }

          this.fileKey = url;

          // 回调
          const {uploadedFileUrl} = this;
          this.$emit("input", {url, uploadedFileUrl});
          console.log("文件上传成功", {url, uploadedFileUrl});
        },
      });

      imgSelector.value = null;
    },
  }
}
