import { render, staticRenderFns } from "./index.vue?vue&type=template&id=36852c4a&scoped=true&lang=pug"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36852c4a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TldSearchBanner: require('/Users/weizhang/Projects/mingcui_web/components/tld/SearchBanner.vue').default,TldHorizontalRecommends: require('/Users/weizhang/Projects/mingcui_web/components/tld/HorizontalRecommends.vue').default,ProductLandingTemplate: require('/Users/weizhang/Projects/mingcui_web/components/product/LandingTemplate.vue').default})
