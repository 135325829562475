
const defaultOriginData = {
  origin: null,
  originUrl: null
};

export default {
  props: {
    value: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      originData: { ...defaultOriginData }
    }
  },
  watch: {
    /**
     * 来源数据
     */
    "originData": {
      handler(val, _) {
        this.$emit('input', val)
      },
      deep: true
    },
    /**
     * 值变化
     * @param {*} val 
     */
    value(val) {
      if (this.$_.isEmpty(val)) {
        this.originData = {
          ...defaultOriginData
        };
        return;
      }
      this.originData = val;
    }
  }
}
