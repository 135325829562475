import req from "~/utils/request";
import { URLS } from "~/utils/request/requestUrls";

export default {
  /**
   * 查询IP是否是蜘蛛
   * @param {*} data 
   * @returns 
   */
  async spiderLookup(data) {
    const rs = await req.postJson({
      url: URLS.DATA_SPIDER_LOOKUP,
      data
    });
    return rs.data;
  },
}