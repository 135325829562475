
import format from "~/utils/format";
import { mapState } from "vuex";

export default {
  data() {
    return {}
  },
  computed: {
    ...mapState("users", {
      user: (state) => state.user,
      loadingUserInfo: (state) => state.loadingUserInfo,
    }),

    /**
     * 登录用户头像地址
     */
    avatarUrl() {
      const { user, defaultAvatarUrl, hasError } = this;

      if (
        this.$_.isEmpty(user) ||
        this.$_.isEmpty(user.avatarUrl) ||
        hasError
      ) {
        return defaultAvatarUrl;
      }

      return user.avatarUrl;
    },
  },
  methods: {
    /**
     * 格式化时间
     * @param {*} d 
     */
    formatDate(d) {
      return format.formatDate(d, 'YYYY-MM-DD HH:mm');
    },
  }
}
