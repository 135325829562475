
export default {
  props: {
    recommendThreads: {
      type: Array,
      default() {
        return [];
      }
    }
  }
}
