
export default {
  props: {
    value: {
      type: String,
    }
  },
  data() {
    return {
      sortBy: this.value || 'null',
      sortTypes: [
        { label: "全部", value: "null" },
        { label: "最新入库的域名", value: "ID_DESC" },
        { label: "最早入库的域名", value: "ID_ASC" },
        { label: "价格从高到底", value: "PRICE_DESC" },
        { label: "价格从低到高", value: "PRICE_ASC" },
      ]
    }
  },
  methods: {
    handleChange(val){
      if(val == "null"){
        this.$emit('change', null);
        return;
      }

      this.$emit('change', val);
    }
  }
}
