
export default {
  head() {
    return {
      title: this.$app.getTitle('TLD'),
    };
  },
  data() {
    return {};
  },
  asyncData({error}) {
    error({ status: 404, message: "找不到当前页面" });
  },
  methods: {},
};
