import { render, staticRenderFns } from "./reset.vue?vue&type=template&id=6964d878&scoped=true&lang=pug"
import script from "./reset.vue?vue&type=script&lang=js"
export * from "./reset.vue?vue&type=script&lang=js"
import style0 from "./reset.vue?vue&type=style&index=0&id=6964d878&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6964d878",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SmsSendButton: require('/Users/weizhang/Projects/mingcui_web/components/sms/SendButton.vue').default,CommonPrivacyCheck: require('/Users/weizhang/Projects/mingcui_web/components/common/PrivacyCheck.vue').default})
