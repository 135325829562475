
import { mapState } from "vuex";

export default {
  name: 'common-footer',
  data() {
    return {
      beianUrl: "https://beian.miit.gov.cn/",
    };
  },
  computed: {
    ...mapState("bootstrap", {
      setSite: (state) => state.setSite,
    }),
  },
};
