
export default {
  data() {
    return {
      menu: [
        { name: "账号信息", path: "/users/settings" },
        { name: "个人资料", path: "/users/settings/profiles" },
        { name: "收货地址", path: "/users/settings/address" },
      ]
    }
  },
  computed: {
    /**
     * 当前请求路径
     */
    currentPath() {
      const { $route } = this;
      return $route.path || "";
    }
  }
}
