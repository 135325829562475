import { render, staticRenderFns } from "./_page.vue?vue&type=template&id=7020bf2e&lang=pug"
import script from "./_page.vue?vue&type=script&lang=js"
export * from "./_page.vue?vue&type=script&lang=js"
import style0 from "./_page.vue?vue&type=style&index=0&id=7020bf2e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonButton: require('/Users/weizhang/Projects/mingcui_web/components/common/Button.vue').default,CommonEmpty: require('/Users/weizhang/Projects/mingcui_web/components/common/Empty.vue').default,PostsListTile: require('/Users/weizhang/Projects/mingcui_web/components/posts/ListTile.vue').default,CommonPagenationLink: require('/Users/weizhang/Projects/mingcui_web/components/common/PagenationLink.vue').default})
