import req from "~/utils/request";
import { URLS } from "~/utils/request/requestUrls";

export default {
  /// fs认证
  async credential(data) {
    const rs = await req.postJson({
      url: URLS.FS_CREDENTIAL,
      data
    });
    return rs.data;
  },
  /// 下载图片
  async downloadImage(data) {
    const rs = await req.postJson({
      url: URLS.FS_DOWNLOAD_IMAGE,
      data
    });
    return rs.data;
  },
}