
export default {
  name: "product-landing",
  props: {
    // 产品属性
    // 默认返回一个空的产品对象用于渲染
    product: {
      type: Object,
      default() {
        return {
          // 文档与工具
          // 注意，默认情况下文档与工具会渲染通用文档，常见问题，
          // 根据需要传入产品文档,SDK文档链接，否则不显示
          documents: [],
        }
      }
    },

  },
  data() {
    return {

    }
  },
  computed: {
    /**
     * 根据传入的参数计算一个hash标点标签导航，跳转到不同部位
     */
    hashNav() {
      let navs = [];

      return navs;
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.updateNavbar();
    });
  },
  beforeDestroy() {
    this.$store.dispatch("navbar/updateNavbar", null);
  },
  methods: {
    // 更新导航条
    updateNavbar() {
      const { product } = this;
      const header = product.header || {};
      if (this.$_.isEmpty(header.navbar)) {
        return;
      }

      this.$store.dispatch("navbar/updateNavbar", {
        ...header.navbar
      });
    }
  }
}
