
import metasMixins from "~/mixins/metas";
import pexelsApi from "~/utils/api/pexels";

export default {
  mixins: [metasMixins],
  data() {
    return {
      word: "",
      loading: false,
      photos: []
    }
  },
  methods: {
    /**
     * 输出
     * @param {*} item 
     */
    async copy(item) {
      console.log(item)
      const large = item.src.large || "";

      // 复制
      const imgEl = `<img src="${large}">`;

      const blob = new Blob(
        [imgEl],
        { type: "text/html" }
      );

      await navigator.clipboard.write([
        new ClipboardItem({
          'text/html': blob
        })
      ]);


      this.$toast.notify({
        title: "成功",
        message: "已经将图片链接地址复制到剪辑版，您可以粘贴到剪辑版编辑器会自动下载",
        type: "success",
      });
    },
    /**
     * 搜索
     */
    async handleSearch(targetMetas) {
      const { word, metas } = this;

      const willLoadMetas = targetMetas || metas;
      let data = {
        ...willLoadMetas,
        // 仅加载顶级评论
        word,
        limit: 28
      };

      this.loading = true;
      this.photos = [];
      try {
        const rs = await pexelsApi.search({
          ...data
        });
        if (rs) {
          this.photos = rs.data || [];
        }
      } catch (err) {
        console.log(err)
      }

      this.loading = false;

    }
  }
}
