
import { mapState } from "vuex";
import threadsApi from "~/utils/api/threads";

export default {
  props: {
    thread: {
      type: Object,
      default() {
        return null
      }
    }
  },
  data() {
    return {
      content: "",
      loading: false,
      showEmojiSelector: false
    }
  },
  computed: {
    ...mapState("users", { user: (state) => state.user }),
    hasLogin() {
      const { user } = this;
      return this.$_.isEmpty(user) ? false : true;
    }
  },
  methods: {
    /**
     * 登录
     */
    async handleRequestLoginButtonClick() {
      await this.$store.dispatch("users/redirect")
    },
    handleAddEmoji(e) {
      const { content } = this;
      this.content = `${content} [e_qq]${e.code}[/e_qq] `
    },
    /**
     * 创建评论
     */
    async handleCreatePost() {
      const { thread, content } = this;

      this.loading = true;
      try {
        const rs = await threadsApi.threadPostCreate(thread.id, {
          content
        });

        if (rs) {
          this.content = "";
          this.$toast.notify({
            title: "成功",
            message: "评论发布成功，审核通过后显示",
            type: "success",
          });

        }
      } catch (error) {

      }

      this.loading = false;
    }
  }
}
