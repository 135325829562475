
export default {
  props: {
    /**
     * 绑定主题
     */
    thread: {
      type: Object,
      default() {
        return null
      }
    }
  }
}
