
import orderApi from "~/utils/api/order";
import domainTradeApi from "~/utils/api/domain-trade";
import format from "~/utils/format";

export default {
  head() {
    const { trade } = this;

    return {
      title: this.$app.getTitle(`${this.$_.isEmpty(trade) ? '域名' : trade.domainName}正在出售中`),
    };
  },
  data() {
    return {
      id: this.$route.params.id || "",
      loading: true,
      trade: {},
    };
  },
  async asyncData({ params, error }) {
    let rs;
    try {
      rs = await domainTradeApi.detail(params.id);
    } catch (err) {
      error({ code: 503, message: err });
    }

    return {
      trade: rs.data,
      loading: false
    }
  },
  methods: {
    // 格式化时间
    formatDate(t) {
      return format.formatDate(t, "YYYY-MM-DD HH:mm");
    },
    formatPrice(p) {
      return format.priceToFixed(p);
    },
    /**
     * 创建订单
     */
    async createOrder() {
      const { trade } = this;
      if (this.$_.isEmpty(trade)) {
        this.$toast.show('请等待数据加载完成后继续');
        return;
      }

      // 登录检测
      const shouldRedirect = await this.$store.dispatch("users/redirect");
      if (shouldRedirect) {
        return;
      }

      // 询问是否购买
      const ok = await this.$toast.confirm({
        title: '提示',
        message: `您即将购买域名${trade.domainName}，是否确认下单`,
        confirmButtonText: "立即购买",
        cancelButtonText: "取消"
      });
      if (!ok) {
        return;
      }


      // 创建订单
      this.loading = true;

      try {
        // 遍历domains，生成orderitem在提交创建订单
        const items = [
          {
            productType: "domainTradeFixed",
            name: `一口价域名购买${trade.domainName}`,
            action: "create",
            processData: {
              domainName: trade.domainName,
            }
          }
        ];

        const rs = await orderApi.create({
          "orderType": 2,
          "fee": 0,// orderType时，fee会被后端重新计算的
          items
        });
        if (rs) {
          const order = rs.data;

          this.$toast.show({
            message: "订单创建成功，请完成支付否则会被他人抢占。马上带您去支付...",
            type: "success"
          });

          setTimeout(() => {
            window.location.href = `/console/order/detail/${order.id}`;
          }, 3000);
        }
      } catch (err) {
        console.error(err);
      }

      this.loading = false;
    }
  },
};
