
export default {
  head() {
    return {
      title: this.$app.getTitle('搜索引擎站长入口大全，站长导航'),
      meta: [
        {
          hid: "keywords",
          name: "keywords",
          content: "搜索引站长入口,搜索引擎提交,站长导航",
        },
        {
          hid: "description",
          name: "description",
          content: "为站长提供各大搜索引擎网址提交入口，站长平台入口，站长导航",
        },
      ]
    };
  },
  data() {
    return {
      links: [
        {
          name: "国产搜索引擎",
          desc: "国产搜索引擎站长平台，在国内使用份额较高的搜索引擎",
          items: [
            { name: "百度站长平台", desc: "直达百度搜索引擎站长平台", path: "https://ziyuan.baidu.com/", icon: "/images/webmaster/baidu.svg", native: true },
            { name: "好搜站长平台", desc: "直达神马搜索引擎站长平台", path: "https://zhanzhang.so.com/", icon: "/images/webmaster/so.png", native: true },
            { name: "搜狗站长平台", desc: "直达搜狗搜索引擎站长平台", path: "https://zhanzhang.sogou.com/", icon: "/images/webmaster/sogou.svg",  native: true },
            { name: "神马站长平台", desc: "直达神马搜索引擎站长平台", path: "https://zhanzhang.sm.cn/", icon: "/images/webmaster/shenma_logo.png", native: true },
            { name: "今日头条站长", desc: "直达头条搜索引擎站长平台", path: "https://zhanzhang.toutiao.com/", icon: "/images/webmaster/toutiao.png", native: true },
          ]
        },
        {
          name: "国外搜索引擎",
          desc: "部分国外的搜索引擎，在国内也占有一定的搜索市场的，也可能是国产但面向境外的搜索引擎",
          items: [
            { name: "必应站长", desc: "必应，Ecosia，DuckDuckGo，雅虎", path: "https://www.bing.com/webmasters/home", icon: "/images/webmaster/bing.svg", native: true },
            { name: "谷歌搜索控制台", desc: "Google search console", path: "https://search.google.com/search-console/about", icon: "/images/webmaster/google.svg", native: true },
            { name: "华为花瓣搜索", desc: "主要面向海外市场的华为智能手机搜索引擎", path: "https://webmaster.petalsearch.com/", icon: "/images/webmaster/petal.png", native: true },
          ]
        },

      ]
    };
  },
  methods: {},
};
