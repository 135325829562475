import consoleApi from "~/utils/api/console";

export const state = () => ({
  /// 数据总览
  overviews: null,
  loadingOverviews: false,
})


export const mutations = {
  //- 更新数据总览
  updateLoadingOverviews(state, loading) {
    state.loadingOverviews = loading || false;
  },
  //- 更新数据总览
  updateOverviews(state, overviews) {
    state.overviews = overviews;
  },
}


export const actions = {
  /**
   * 取得总览数据
   * @param {*} context 
   * silent: 不会设置loading
   */
  async getOverviews(context, silent) {
    if(!silent){
      context.commit("updateLoadingOverviews", true);
    }
   

    try {
      const rs = await consoleApi.overview();
      if (rs) {
        context.commit("updateOverviews", rs.data || null);
      }
    } catch (err) {
      console.error(err)
    }

    context.commit("updateLoadingOverviews", false);
  }
}

export const namespaced = true;
