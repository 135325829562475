
import dataApi from "~/utils/api/data";
import isEmpty from 'lodash/isEmpty'

// http://localhost:3004/tools/spider/66.249.64.14
export default {
  head() {
    return {
      title: this.$app.getTitle(`${this.ip}百度真假蜘蛛查询 百度蜘蛛识别 蜘蛛IP查询`),
      meta: [
        {
          hid: "keywords",
          name: "keywords",
          content: "百度真假蜘蛛查询,百度蜘蛛识别,谷歌蜘蛛查询,必应蜘蛛查询,搜狗蜘蛛查询,360蜘蛛查询,搜狗蜘蛛识别,谷歌蜘蛛识别,必应蜘蛛识别,360蜘蛛识别,站长工具,站长平台,站长导航",
        },
        {
          hid: "description",
          name: "description",
          content: "为您提供百度蜘蛛、谷歌蜘蛛、必应蜘蛛、搜狗蜘蛛等搜索引擎爬网蜘蛛真假IP查询",
        },
      ]
    };
  },
  async asyncData({ params, error }) {
    let result;

    // 取得IP
    const ip = params.ip;
    if (isEmpty(ip)) {
      return {
        result: null,
        // 覆盖loading
        loading: false
      }
    }

    try {
      const rs = await dataApi.spiderLookup({
        ip
      });
      if (rs) {
        result = rs.data;
      }
    } catch (err) {
      // 抛异常，
      // 如果错误是record not found, 并将Nuxtjs中的响应状态跟新为404
      error({ status: 503, message: err.message || "未知错误" });
    }

    return {
      result,
      // 覆盖loading
      loading: false
    }
  },
  data() {
    return {
      ip: this.$route.params.ip || '',
      // ipParam不要绑定任何输入框
      ipParam: this.$route.params.ip || '',
      loading: false,
    };
  },
  methods: {
    handleClick() {
      const { ip } = this;
      this.$router.push(`/tools/spider/${ip}`)
    }
  },
};
