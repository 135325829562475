import { render, staticRenderFns } from "./_id.vue?vue&type=template&id=0f2679f9&scoped=true&lang=pug"
import script from "./_id.vue?vue&type=script&lang=js"
export * from "./_id.vue?vue&type=script&lang=js"
import style0 from "./_id.vue?vue&type=style&index=0&id=0f2679f9&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f2679f9",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UserDetailHeader: require('/Users/weizhang/Projects/mingcui_web/components/user/detail/Header.vue').default,ThreadsUserRecentList: require('/Users/weizhang/Projects/mingcui_web/components/threads/user/RecentList.vue').default,CommonEmpty: require('/Users/weizhang/Projects/mingcui_web/components/common/Empty.vue').default,CommonCard: require('/Users/weizhang/Projects/mingcui_web/components/common/Card.vue').default})
