
export default {
  props: {
    // 传入标题
    title: {
      type: String,
      default: "",
    },
    // 副标题
    subtitle: {
      type: String,
      default: "",
    },
    // 拓展标题class
    titleClassName: {
      type: String,
      default: "",
    },
    // 拓展于body的类名
    bodyClass: {
      type: String,
      default: "",
    },
  },
};
