import { render, staticRenderFns } from "./_id.html.vue?vue&type=template&id=50edb932&lang=pug"
import script from "./_id.html.vue?vue&type=script&lang=js"
export * from "./_id.html.vue?vue&type=script&lang=js"
import style0 from "./_id.html.vue?vue&type=style&index=0&id=50edb932&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {QuestionQuickActions: require('/Users/weizhang/Projects/mingcui_web/components/question/QuickActions.vue').default,QuestionDetailNextAndPrev: require('/Users/weizhang/Projects/mingcui_web/components/question/detail/NextAndPrev.vue').default,CommonEmpty: require('/Users/weizhang/Projects/mingcui_web/components/common/Empty.vue').default,UserAvatar: require('/Users/weizhang/Projects/mingcui_web/components/user/Avatar.vue').default,EditorFaqFull: require('/Users/weizhang/Projects/mingcui_web/components/editor/faq/Full.vue').default,QuestionHasNoPickedAnswerList: require('/Users/weizhang/Projects/mingcui_web/components/question/HasNoPickedAnswerList.vue').default})
