import req from "~/utils/request";
import { URLS } from "~/utils/request/requestUrls";

export default {
  /// 内容伪原创API
  async pseudo(data) {
    const rs = await req.postJson({
      url: URLS.CONTENT_PSEUDO,
      data
    });
    return rs.data;
  },
  /// 使用标题查询搜索引擎关键字列表
  async keywordIndex(data) {
    const rs = await req.postJson({
      url: URLS.CONTENT_KEYWORD_INDEX,
      data
    });
    return rs.data;
  },
  /**
   * 固定模板的AI协作工具API
   * @param {*} data 
   * @returns 
   */
  async chatCompletion(data) {
    const rs = await req.postJson({
      url: URLS.CONTENT_CHAT_COMPLETION,
      data
    });
    return rs.data;
  },
}