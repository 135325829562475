import req from "~/utils/request";
import { URLS } from "~/utils/request/requestUrls";

export default {
  /// 全量同步
  async sync() {
    const rs = await req.get({
      url: URLS.SEARCH_SYNC_PUBLIC
    });
    return rs.data;
  },
  /// 执行搜索
  async do(data) {
    const rs = await req.postJson({
      url: URLS.SEARCH_DO,
      data
    });
    return rs.data;
  },
  /// 清空索引
  async indexClean(data) {
    const rs = await req.postJson({
      url: URLS.SEARCH_INDEX_CLEAN,
      data
    });
    return rs.data;
  },
}