
import usersApi from "~/utils/api/users";

// 默认对象
const defaultMediumObj = {
  mediumName: "",
  companyName: "",
  visa: "",
  visaPic: "",
  telephone: "",
  website: "",

  // 后端计算属性
  reviewing: false,
  failed: false,
  isChecked: false,
  failedReason: "",
  visaPicUrl: ""
}

export default {
  head() {
    return {
      title: this.$app.getTitle('媒体号'),
    };
  },
  data() {
    return {
      medium: {
        ...defaultMediumObj
      },
      loading: false,
      submiting: false,
    };
  },
  computed: {
    /**
     * 是否显示注销按钮
     */
    showDeleteButton() {
      const { medium } = this;
      if (medium.id && medium.id != 0) {
        return true;
      }
      return false;
    }
  },
  mounted() {
    this.$nextTick(async () => {
      await this.$store.dispatch('users/redirect');
      await this.restoreUserMedium();
    });
  },
  methods: {
    /**
     * 注销
     */
    async handleDelete() {
      const ok = await this.$toast.confirm({
        title: "注销媒体号",
        message: "注销后，您的发布权限将丢失且文章不会随之删除，真的要注销吗?",
      });
      if (!ok) {
        return;
      }

      try {
        const rs = await usersApi.mediumDelete();
        if (rs) {
          this.$toast.notify({
            title: "成功",
            message: "好的，已经为您注销如有需要欢迎重新申请",
            duration: 3000,
            type: 'success'
          });
          this.medium = {
            ...defaultMediumObj
          }
        }
      } catch (err) {
        console.error(err);
      }

    },
    /**
     * 图片上传成功
     * @param {*} f 
     */
    handleImageUploaded(f) {
      this.medium.visaPic = f.url || "";
      this.medium.visaPicUrl = f.uploadedFileUrl || "";
    },
    /**
     * 保存
     */
    async handleSave() {
      const ok = await this.$toast.confirm({
        title: "是否保存",
        message: "每次保存都会触发重新审核，您是否确定保存?",
      });
      if (!ok) {
        return;
      }

      const { medium } = this;

      this.submiting = true;

      try {
        const rs = await usersApi.mediumSave({
          ...medium
        });
        if (rs) {
          this.$toast.notify({
            title: "成功",
            message: "已经为您重新保存并提交审核",
            duration: 3000,
            type: 'success'
          });
          await this.restoreUserMedium();
        }
      } catch (err) {
        console.error(err);
      }

      this.submiting = false;

    },
    /**
     * 还原用户媒体号申请表单信息
     */
    async restoreUserMedium() {
      this.loading = true;

      try {
        const rs = await usersApi.mediumDetail();
        if (rs && rs.data) {
          this.medium = rs.data;
        }
      } catch (err) {
        console.error(err);
      }

      this.loading = false;
    }
  },
};
