
import { mapState } from "vuex";

export default {
  props: {
    /**
     * 大小
     */
    size: {
      type: Number,
      default: 30,
    },
    /**
     * 若需要渲染目标用户
     */
    targetUser: {
      type: Object,
    },
    /**
     * 是否允许点击后直达用户主页
     */
    tapToHome: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState("users", { user: (state) => state.user }),
    /**
     * 登录用户头像地址
     */
    avatarUrl() {
      const { currentUser, defaultAvatarUrl, hasError } = this;

      if (
        this.$_.isEmpty(currentUser) ||
        this.$_.isEmpty(currentUser.avatarUrl) ||
        hasError
      ) {
        return defaultAvatarUrl;
      }

      return currentUser.avatarUrl;
    },
    /**
     * 实际渲染的用户
     */
    currentUser() {
      const { user, targetUser } = this;
      return targetUser || user;
    },
    /**
     * 头像alt元素
     */
    alt() {
      const { currentUser } = this;
      if (this.$_.isEmpty(currentUser)) {
        return "用户头像";
      }

      return currentUser.username;
    },
    /**
     * 点击链接直达Url
     */
    toUrl(){
      const {currentUser} = this;

      return `/users/detail/${currentUser.id}`;
    }
  },
  data() {
    return {
      defaultAvatarUrl: "/images/avatar.jpeg",
      hasError: false,
    };
  },
  methods: {
    /**
     * 图片下载回调错误
     */
    handleAvatarLoadingErr(event) {
      if (!event.type === "error") {
        return;
      }

      this.hasError = true;
    },
    /**
     * 头像被点击
     */
    handleClick() {
      const { currentUser } = this;
      this.$emit("clickUser", { ...currentUser });
    },
  },
};
